import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
// reactstrap components
import { Nav, Row } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// used for making the prop types of this component
// core components
import { RenderImage } from 'components'
import { Routes } from "constants/index.js";

import defaultAvatar from "assets/img/default_profile.jpg";
import logo from "../../../logo.png";
import sidebarBackground from "assets/img/sidebar-background.png"
import dashboardLogo from "assets/img/dashboard.svg"
import staffLogo from "assets/img/staff_outline.svg"
import studiesLogo from "assets/img/studies.svg"
import profileLogo from "assets/img/profile.svg"
import queriesLogo from 'assets/img/query.svg'
import metricLogo from 'assets/img/graph.svg'
import documentsLogo from 'assets/img/documents.svg'
import clipboard from 'assets/img/clipboard.svg'

var ps;


const DMSidebar = (props) => {

    var routes = []
    if (props.study && props.study._id) {
        routes = [
            {
                path: Routes.DASHBOARD,
                name: "Dashboard",
                icon: dashboardLogo,
            },
            {
                path: Routes.DOCUMENTS,
                name: "CRF Documents",
                icon: documentsLogo,
            },
            {
                path: Routes.QUERIES,
                name: "My Queries",
                icon: queriesLogo,
            },
            {
                path: `${Routes.STUDIES}/${props.study._id}`,
                name: "Study Information",
                icon: clipboard,
            },
            {
                path: Routes.METRICS,
                name: "Metrics",
                icon: metricLogo,
            },
            {
                path: Routes.REPORTS,
                name: "Reports",
                icon: studiesLogo,
            },
            {
                path: Routes.STAFF,
                name: "User Accounts",
                icon: staffLogo,
            },
            {
                path: Routes.PROFILE,
                name: "My Profile",
                icon: profileLogo,
            },
        ];
    }

    const sideBarRef = useRef();

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sideBarRef.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }

        return () => {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            // to stop the warning of calling setState of unmounted component
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        }
    });

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return window.location.href.indexOf(routeName) > -1 ? "active" : "";
    };

    return (
        <div className="sidebar" data-color={props.backgroundColor}>
            <div className="sidebar-background" style={{ backgroundImage: `url(${sidebarBackground})`, opacity: .2 }}></div>
            <div className="logo">
                <div className="logo-img" style={{ margin: '10px' }}>
                    <img src={logo} alt="..." />
                </div>
            </div>
            <div className="sidebar-wrapper" ref={sideBarRef}>
                <div className="user">
                    <div className="photo">
                        <RenderImage style={{ width: '50px', height: '50px' }} src={props.user.staffProfile && props.user.staffProfile.profilePicture ? props.user.staffProfile.profilePicture : defaultAvatar} alt="." />
                    </div>
                    <div className="info">
                        <a href="/profile">
                            <span style={{ color: 'white', fontSize: '16px' }}>
                                {`${props.user.staffProfile && props.user.staffProfile.firstName} ${props.user.staffProfile && props.user.staffProfile.lastName}`}
                                <p className="sm-8" style={{ color: 'white', fontSize: '12px' }}>
                                    {props.user && props.user.role ? props.user.role.name : ""}
                                </p>
                            </span>
                        </a>
                    </div>
                </div>
                <Nav>
                    {
                        routes.map((prop, key) => {
                            return (
                                <li className={activeRoute(prop.path)} key={key}>
                                    <NavLink to={prop.path} activeClassName="">
                                        <Row className="d-flex align-items-center" style={{ marginLeft: '15px' }}>
                                            <img alt="..." src={prop.icon} width={24} height={24} />
                                            <p style={{ marginLeft: '15px', fontSize: '14px' }}>{prop.name}</p>
                                        </Row>
                                    </NavLink>
                                </li>
                            );
                        })
                    }
                    <li className="my-5">
                        <p className="text-center text-white">
                            &copy; {1900 + new Date().getYear()} Quanticate{" "}
                        </p>
                    </li>
                </Nav>
            </div>

        </div>
    );
}



export default DMSidebar;