import React, {  Fragment, useState } from 'react'
// reactstrap components
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button, FormFeedback
} from "reactstrap";
import PropTypes from "prop-types";
// react plugin used to create DropdownMenu for selecting items
import { Chip } from 'components';


const CRFPages = (props) => {

    const [invalidPagesCount, setInvalidPagesCount] = useState(false)

    function pages() {
        var pages = [];
        for (var i = 0; i < props.totalCRFPages; i++) {
            pages.push(
                <Chip
                    key={i}
                    id={i + 1}
                    disabled={props.disabled}
                    checked={props.selectedPages && props.selectedPages.indexOf(`${i + 1}`) > -1}
                    name={`CRF Page ${i + 1}`}
                    onCheckedChange={props.onCRFDataChange} />
            );
        }
        return pages;
    }

    return (
        <Fragment>
            <h6>Publish CRF Pages Template</h6>
            <Row>
                <Col sm={5}>
                    <FormGroup>
                        <Label>Specify No. of Pages</Label>
                        <Input type="number" disabled={props.disabled} required invalid={invalidPagesCount} defaultValue={props.totalCRFPages} onChange={(e) => {
                            const newValue = e.target.value
                            if (newValue <= 0 || newValue > 999) {
                                setInvalidPagesCount(true)
                            } else {
                                setInvalidPagesCount(false)
                            }
                            props.onTotalCRFPagesChange(e)
                        }} />
                        <FormFeedback>No. of pages should should be atleast 1 and cannot be more than 999.</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={8} style={{ marginTop: '20px' }}>
                    <FormGroup>
                        <Label>Select Pages</Label>
                        <Row>
                            {
                                pages()
                            }
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
            <Button color="primary" hidden={!props.selectedPages || props.selectedPages.length <= 0} style={{ marginLeft: '20px' }} className="btn-round" size="sm" type="submit" onClick={props.onCRFNextClick}>
                Next
            </Button>
        </Fragment>
    );

}

CRFPages.propTypes = {
    totalCRFPages: PropTypes.number,
    selectedPages: PropTypes.array,
    disabled: PropTypes.bool,
    checked: PropTypes.bool
};

export default CRFPages