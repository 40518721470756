import React from "react";
import { connect } from 'react-redux';
import {
    Button,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Label,
    Col,
    Breadcrumb,
    BreadcrumbItem,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    FormGroup,
    TabPane
} from 'reactstrap';
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
import _ from 'lodash';
import EasyEdit, { Types } from "react-easy-edit";
import validator from 'validator';
//core components
import DashboardLayout from "layouts/Admin"
import { Routes, Roles } from 'constants/index.js';
import { SiteActions, DashboardActions, StaffActions } from 'actions'
import { SiteStats, MarkPatients, CRFPages } from "./Components";
import { MonitoringPlan, RiskParameters, PanelHeader } from "components";
import { RemoteTable } from "components";
import { userTableColumns } from './Components/TableColumns';
import { 
    updateSiteStaffPivot, 
    updateSite, 
    bulkUpdateSites, 
    updateCRFPages, 
    updateMonitoringPlan, 
    updateSiteSubject, 
    createSiteSubject 
} from "api";
import { PAGE_SIZE } from "api/list";
import cogoToast from "cogo-toast";
const tabs = [
    { id: 'ht1', title: 'Site Stats' },
    { id: 'ht2', title: 'User Management' },
    { id: 'ht3', title: 'Trial Participants' },
    { id: 'ht4', title: 'Monitoring Plan' },
    { id: 'ht5', title: 'Customize CRF Pages' },
    { id: 'ht6', title: 'Risk Parameters' }
]

class SiteDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            activeTab: "ht1",
            disableRiskParameters: false,
            riskAssessment: undefined,
            selectedStudy: {},
            enableRiskAssessment: false,
            enableCRFPages: false,
            enableMonitorPlan: false,
            createPatient: false,
            editMode: false,
            showAlert: false,
            userPage : 1
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.onCRFDataChange = this.onCRFDataChange.bind(this);
        this.onMonitorDataChange = this.onMonitorDataChange.bind(this);
        this.alterEditMode = this.alterEditMode.bind(this);
        this.onConfirmClick = this.onConfirmClick.bind(this);
    }

    hideAlert() {
        this.setState({
            alert: null,
            showAlert: false,
            disableRiskParameters: !this.state.disableRiskParameters
        });
    }

    componentDidMount = async () => {
        const token = this.props.token;
        const siteId = this.props.match.params.id;

        try {
            this.props.getSiteDetails(token, siteId);
        } catch (e) {
            if (e && e.response && e.response.status === 401) {
                this.props.apiError()
            }
            console.log('error', e);
        }
    }

    loadSiteDetails = () => {

        const { selectedStudy, userPage} = this.state;
        const token = this.props.token;
        this.props.getSiteDetails(token, selectedStudy.value);
        this.props.getMyMetrics(token, selectedStudy.value);
        this.props.getSiteStaff(token, selectedStudy.value, 
            (userPage - 1) * PAGE_SIZE, PAGE_SIZE, this.props.role !== Roles.SYSTEM_ADMIN );
        this.props.getStudyStaff(token, selectedStudy.study, 
            (userPage - 1) * PAGE_SIZE, PAGE_SIZE, this.props.role !== Roles.SYSTEM_ADMIN )
        this.props.getSiteSubjects(token, selectedStudy.value);
        this.props.getSiteVisitDetails(token, selectedStudy.value);
    }

    static getDerivedStateFromProps(props, state) {

        // Typical usage (don't forget to compare props):
        if (props !== state) {
            return {
                patients: props.patients,
            };
        }
        return null;
    }

    async componentDidUpdate(prevProps) {

        if (prevProps.site !== this.props.site && this.props.site !== null) {
            this.props.getStudySites(this.props.token, this.props.site.name);
        }
        if (prevProps.studies !== this.props.studies && this.props.studies !== null) {
            if (!this.state.selectedStudy.value) {
                await this.setState({
                    selectedStudy: {
                        value: this.props.studies[0]._id,
                        label: this.props.studies[0].clinicalStudy.name,
                        study: this.props.studies[0].clinicalStudy.id
                    }
                });
                this.loadSiteDetails()
            }
        }

        if (prevProps.visitsInfo !== this.props.visitsInfo && this.props.visitsInfo) {
            this.loadMonitorPlan();
        }
    }

    async loadMonitorPlan() {
        const visitsInfo = this.props.visitsInfo
        var visits = []
        var tempDomains = []
        var monitorTemplateData = []
        var riskAssessment = visitsInfo.length > 0 ? visitsInfo[0].riskAssessment : undefined;
        var totalCRFPages = visitsInfo.length > 0 ? visitsInfo[0].totalCRFPages : 0;
        var selectedPages = visitsInfo.length > 0 ? visitsInfo[0].visitMonitorCRFPages : []; //[1, 6, 9]
        visitsInfo.forEach(visit => {
            visits.push(visit.name);
            if (visit.visitMonitorTemplate) {
                tempDomains.push(...visit.visitMonitorTemplate);
                visit.visitMonitorTemplate.forEach(domain => {
                    monitorTemplateData.push(`${visit.name}:${domain}`)
                })
            }
        });

        await this.setState({
            visits: visits,
            totalCRFPages: totalCRFPages,
            updatedTotalCRFPages: totalCRFPages,
            selectedPages: selectedPages,
			updatedSelectedPages : selectedPages,
            domains: _.uniq(tempDomains),
            monitorTemplateData: monitorTemplateData,
			updatedMonitorData : monitorTemplateData,
			riskAssessment : riskAssessment,
            updatedRiskAssessment: riskAssessment
        })
    }

    onTotalCRFPagesChange = (e) => {
        this.setState({ updatedTotalCRFPages: parseInt(e.target.value) })
    }

    onTotalCRFPagesBlur = (e) => {
        if (!this.state.updatedTotalCRFPages)
            this.setState({ updatedTotalCRFPages: this.state.totalCRFPages })
    }

    onRiskAssessmentChange = (e) => {
        this.setState({ updatedRiskAssessment: e })
    }

    onRiskChangeClick = (e) => {
        const { editMode } = this.state
        if (editMode) {
            this.setState({
                showAlert: true,
            });
        }
    }
    
    handleUserTableChange = (type, { page, sizePerPage }) => {
        
        const currentIndex = (page - 1) * sizePerPage;
        
        this.props.getSiteStaff(this.props.token, this.state.selectedStudy.value, 
            currentIndex, PAGE_SIZE, this.props.role !== Roles.SYSTEM_ADMIN);
        
        if (currentIndex > this.props.siteStaffCount) {
            this.props.getStudyStaff(this.props.token, this.state.selectedStudy.study, 
                (currentIndex - this.props.siteStaffCount), 
                PAGE_SIZE, this.props.role !== Roles.SYSTEM_ADMIN);
        } else if ((currentIndex + sizePerPage) > this.props.siteStaffCount) {
            this.props.getStudyStaff(this.props.token, this.state.selectedStudy.study, 
                0, PAGE_SIZE, this.props.role !== Roles.SYSTEM_ADMIN);
        }
        
        this.setState({ userPage : page })
    }
    
    changeUserAccess = (staffAccess) => {
        this.setState({
                alert: (
                    <SweetAlert
                        title='Confirmation'
                        style={{ minHeight: '200px' }}
                        closeOnClickOutside={false}
                        allowEscape={false}
                        onConfirm={() => {
                            updateSiteStaffPivot(this.props.token, staffAccess.id, { isAccess: !staffAccess.isAccess}).then(res => {
                                this.props.getSiteStaff(this.props.token, this.state.selectedStudy.value, 
                                    this.state.selectedStudy.study, (this.state.userPage - 1) * PAGE_SIZE/2, PAGE_SIZE,
                                    this.props.role !== Roles.SYSTEM_ADMIN);
                                this.hideAlert();
                            }).catch(e => {
                                console.log(e);
                                this.hideAlert();
                            });
                        }}
                        onCancel={this.hideAlert}
                        confirmBtnBsStyle="primary btn-round btn-sm"
                        cancelBtnBsStyle="outline-primary btn-round btn-sm"
                        confirmBtnText="Confirm"
                        cancelBtnText="Cancel"
                        showCancel
                    >
                        Are you sure you want to {`${staffAccess.isAccess ? 'Revoke' : 'Grant'}`} access to user for this site?
                    </SweetAlert>
                )
            });
    }

    async onCRFDataChange(e) {
        const selectedId = e.target.id;
        var selectedPages = _.clone(this.state.selectedPages);

        if (this.state.updatedSelectedPages)
            selectedPages = this.state.updatedSelectedPages;
        
        if (e.target.checked) {
            selectedPages.push(selectedId);
        } else {
            _.remove(selectedPages, val => val === selectedId);
        }
        await this.setState({ updatedSelectedPages: selectedPages })
    }

    async onConfirmClick() {
        const { editMode, activeTab, selectedStudy } = this.state;
        const siteId = selectedStudy.value;
        const token = this.props.token;

        switch (activeTab) {
            case 'ht3':
                const patients = this.state.updatedPatients ? this.state.updatedPatients : this.state.patients;
                let isError = false;
                for (let i = 0; i < patients.length ; i++) {
                    const val = patients[i];
                    try {
                        if (!val.isNew) {
                            await updateSiteSubject(token, val._id, { monitorSelect: val.monitorSelect });
                        } else {
                            let response = await createSiteSubject(token, val);
							patients[i] = response.data;
							patients[i].isNew = false;
                        }
                    } catch (error) {
                        alert('Update site Trial Participant failed!')
                        console.log(error);
                        isError = true;
                    }
                }
                
                if (isError) {
                    const { hide } = cogoToast.error('Error updating trial participants information.', {
                        onClick: () => {
                            hide();
                        },
                        position: "top-right",
                        hideAfter: 3
                    });
                } else {
                    const { hide } = cogoToast.success('Site trial participants information updated successfully.', {
                        onClick: () => {
                            hide();
                        },
                        position: "top-right",
                        hideAfter: 3
                    });
                }
                
                this.hideAlert();
                this.setState({ editMode: false, patients : patients, updatedPatients : patients })
                break;
            case 'ht4':
                var { updatedMonitorData } = this.state;
				var monitorObj = {};
                    
                updatedMonitorData.forEach(val => {
                    const d = val.split(':')
                    const key = d[0]
                    const value = d[1]
                    if (monitorObj[key]) {
                        monitorObj[key].push(value)
                    } else {
                        monitorObj[key] = [value]
                    }
                })
                updateMonitoringPlan(token, siteId, monitorObj).then(res => {
                    this.hideAlert();
                    this.setState({ editMode: false,  monitorTemplateData : updatedMonitorData})
                    const { hide } = cogoToast.success('Monitor Plan updated successfully.', {
                        onClick: () => {
                            hide();
                        },
                        position: "top-right",
                        hideAfter: 3
                    });
                }).catch(e => console.log(e))
                break;
            case 'ht5':
                var { visits, updatedTotalCRFPages, updatedSelectedPages } = this.state;

                var totalCRFPagesObj = {}
                var selectedPagesObj = {}
				visits.forEach(val => {
                    totalCRFPagesObj[val] = `${updatedTotalCRFPages}`;
                    selectedPagesObj[val] = updatedSelectedPages;
                })
                const crfPages = {
                    'totalCRFPages': totalCRFPagesObj,
                    'monitorCRFPages': selectedPagesObj
                }
                updateCRFPages(token, siteId, crfPages).then(res => {
                    this.hideAlert();
                    this.setState({ editMode: false, totalCRFPages : updatedTotalCRFPages, selectedPages : updatedSelectedPages})
                }).catch(e => console.log(e))
                break;
            case 'ht6':
				const { updatedRiskAssessment } = this.state;
                updateSite(token, siteId, { riskAssessment: updatedRiskAssessment }).then(res => {
                    this.hideAlert();
                    this.setState({ editMode: !editMode, riskAssessment : updatedRiskAssessment })
                }).catch(e => console.log(e))
                break;
            default:
                return
        }
    }

    onCancelEdit = () => {
		const { monitorTemplateData, riskAssessment, totalCRFPages, selectedPages, patients} = this.state;
		this.setState({
            alert: undefined,
            showAlert: false,
            updatedMonitorData: monitorTemplateData,
            updatedRiskAssessment: riskAssessment,
            updatedSelectedPages: selectedPages,
            updatedTotalCRFPages: totalCRFPages,
			updatedPatients : patients,
            editMode: false,
            createPatient: false,
        })
    }

    async alterEditMode() {
        const { editMode } = this.state;
        if (editMode) {
            this.setState({
                showAlert: true,
            });
        } else {
            this.setState({ editMode: !editMode })
        }
    }

    onMarkPatientsUpdate = (e) => {
        let patients = _.cloneDeep(this.state.updatedPatients ? this.state.updatedPatients : this.state.patients);
        let patient = patients.filter(val => val._id === e.target.id)[0];
        patient.monitorSelect = e.target.checked;

        this.setState({ updatedPatients: patients })
    }

    onMonitorDataChange = async (selectedId, checked) => {
        var monitorData = _.cloneDeep(this.state.updatedMonitorData);
        
        if (checked) {
            monitorData.push(selectedId);
        } else {
            _.remove(monitorData, val => val === selectedId);
        }
        await this.setState({
            updatedMonitorData: monitorData
        });
    }

    // this is a status toggle on all study sites
    // matching the same. All studies should be disabled
    //
    onStatusChange = (el, state) => {
        const token = this.props.token;
        if (this.props.site) {
            const title = state ? 'Activate' : 'Deactivate'
            this.setState({
                alert: (
                    <SweetAlert
                        title=''
                        style={{ minHeight: '200px' }}
                        closeOnClickOutside={false}
                        allowEscape={false}
                        onConfirm={() => {
                            bulkUpdateSites(token, this.props.site.name, { isActive: state }).then(res => {
                                this.setState({ isActive: state })
                                this.props.getSiteDetails(token, this.props.match.params.id);
                                this.hideAlert();
                            }).catch(e => {
                                console.log(e);
                                this.hideAlert();
                            });
                        }}
                        onCancel={this.hideAlert}
                        confirmBtnBsStyle="primary btn-round btn-sm"
                        cancelBtnBsStyle="outline-primary btn-round btn-sm"
                        confirmBtnText={title}
                        cancelBtnText="Cancel"
                        showCancel
                    >
                        <h6>{title}</h6>
                        Are you sure you want to {title} this site?
                    </SweetAlert>
                )
            });

        }
    }

    onTabChangeClick = (tabId) => {
        this.setState({
            alert: (<SweetAlert
                title=''
                style={{ minHeight: '200px' }}
                closeOnClickOutside={false}
                allowEscape={false}
                onConfirm={this.onTabChangeConfirmClick}
                onCancel={this.hideAlert}
                confirmBtnBsStyle="primary btn-round btn-sm"
                cancelBtnBsStyle="outline-primary btn-round btn-sm"
                confirmBtnText="Ok"
                cancelBtnText="Cancel"
                showCancel
            >
                Do you want to discard the changes and proceed?
            </SweetAlert>),
            nextTabId: tabId,
        })
    }

    onTabChangeConfirmClick = async () => {
        await this.setState({
            activeTab: this.state.nextTabId,
            nextTabId: undefined,
        })
        this.onCancelEdit()
    }

    onAddSubjectTrigger = () => {
        this.setState({ createPatient: !this.state.createPatient })
    }

    onAddSubject = async (event, values) => {
        const study = this.state.selectedStudy;
        let subject = values;
        subject['studySite'] = study.value;
        subject['icfVerified'] = false;
        subject['monitorSelect'] = values.monitorSelect === "true";
        subject['isNew'] = true;
        let patients = _.cloneDeep(this.state.updatedPatients ? this.state.updatedPatients : this.state.patients);
        patients.push(subject);
        await this.setState({updatedPatients : patients, createPatient : false});
    }

    updateSiteItem = async (key, value) => {
        const token = this.props.token;
        try {
            bulkUpdateSites(token, this.props.site.name, { [key]: value })
        } catch (error) {
            console.log(error);
            cogoToast.error('Failed to update!')
        }
    }

    render() {

        const { selectedStudy } = this.state;
        const { name, contactNumber, contactPerson, designation, contactEmail, subjectPrefix, address, crfType, isActive } = this.props.site ? this.props.site : {}
        const { site, studies, metrics } = this.props;
        const { visits, domains, showAlert, patients, editMode, createPatient, updatedRiskAssessment } = this.state;
        // let effectiveStatus = false;
        let runningStudies = 0;
        
        let staffData = this.props.siteStaff.concat(this.props.studyStaff);
        if (staffData.length > PAGE_SIZE) staffData.length = PAGE_SIZE;

        if (studies) {
            studies.map(study => {
                if (study.isActive) {
                    // effectiveStatus = true;
                    runningStudies++;
                }
                // fix warnings
                return null;
            });
        }
        
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.SITES}`}>Sites</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Site Details</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {this.state.alert}
                    <SweetAlert
                        show={showAlert}
                        title=''
                        style={{ minHeight: '200px' }}
                        closeOnClickOutside={false}
                        allowEscape={false}
                        onConfirm={this.onConfirmClick}
                        onCancel={this.onCancelEdit}
                        confirmBtnBsStyle="primary btn-round btn-sm"
                        cancelBtnBsStyle="outline-primary btn-round btn-sm"
                        confirmBtnText="Submit"
                        cancelBtnText="Cancel"
                        showCancel>
                        <h6>Submit</h6> Are you sure you want to submit the changes?
                    </SweetAlert>
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '755px' }}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={8}>
                                            <CardTitle tag="h4">{name}</CardTitle>
                                            <p>{`There are ${runningStudies} Studies actively running in the site`}</p>
                                        </Col>
                                        <Col xs={12} sm={4}>
                                            <p className="info">Change Status</p>
                                            <Switch name="isActive" value={isActive} onColor="primary" onChange={(el, state) => this.onStatusChange(el, state)} />
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Contact Person Name</Label>
                                                <EasyEdit
                                                    type={Types.TEXT}
                                                    placeholder="Contact Person Name"
                                                    validationMessage="Please provide Contact Person Name"
                                                    value={contactPerson}
                                                    onSave={(val) => this.updateSiteItem('contactPerson', val)}
                                                    onValidate={value => { return value.trim() !== '' }}
                                                    saveButtonLabel={<i class="fa fa-check" aria-hidden="true" />}
                                                    cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" />}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Contact Email</Label>
                                                <EasyEdit
                                                    type={Types.TEXT}
                                                    placeholder="Contact Email"
                                                    validationMessage="Please provide Contact Email"
                                                    value={contactEmail}
                                                    onSave={(val) => this.updateSiteItem('contactEmail', val)}
                                                    onValidate={value => { return validator.isEmail(value) }}
                                                    saveButtonLabel={<i class="fa fa-check" aria-hidden="true" />}
                                                    cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" />}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Contact Number</Label>
                                                <EasyEdit
                                                    type={Types.TEXT}
                                                    placeholder="Contact Number"
                                                    validationMessage="Please provide Contact Number"
                                                    value={contactNumber}
                                                    onSave={(val) => this.updateSiteItem('contactNumber', val)}
                                                    onValidate={value => { return validator.isMobilePhone(value) }}
                                                    saveButtonLabel={<i class="fa fa-check" aria-hidden="true" />}
                                                    cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" />}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Designation</Label>
                                                <EasyEdit
                                                    type={Types.TEXT}
                                                    placeholder="Designation"
                                                    validationMessage="Please provide designation"
                                                    value={designation}
                                                    onSave={(val) => this.updateSiteItem('desingation', val)}
                                                    onValidate={value => { return value.trim() !== '' }}
                                                    saveButtonLabel={<i class="fa fa-check" aria-hidden="true" />}
                                                    cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" />}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Address</Label>
                                                <EasyEdit
                                                    type={Types.TEXTAREA}
                                                    placeholder="Address"
                                                    validationMessage="Please provide address"
                                                    value={address}
                                                    onSave={(val) => this.updateSiteItem('address', val)}
                                                    onValidate={value => { return value.trim() !== '' }}
                                                    saveButtonLabel={<i class="fa fa-check" aria-hidden="true" />}
                                                    cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" />}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col lg={12} md={12} xs={12}>
                                            <br />
                                            <Nav pills className="nav-pills-primary"
                                                style={{ fontSize: '7px', padding: '10px', overflowX: 'auto', overflowY: 'hidden', flexWrap: 'nowrap' }}>
                                                {
                                                    tabs.map((val, index) => {
                                                        // customize crf pages option is hidden for electronic CRF sites
                                                        if (crfType === 'ELECTRONIC' && val.id === 'ht5') return null;

                                                        return (
                                                            <NavItem key={index}>
                                                                <NavLink
                                                                    className={this.state.activeTab === `${val.id}` ? "active" : ""}
                                                                    onClick={() => {
                                                                        if (this.state.editMode) {
                                                                            this.onTabChangeClick(val.id)
                                                                        } else {
                                                                            this.setState({ activeTab: `${val.id}` })
                                                                        }
                                                                    }}
                                                                >
                                                                    {val.title}
                                                                </NavLink>
                                                            </NavItem>
                                                        )
                                                    })
                                                }
                                            </Nav>
                                            <TabContent
                                                activeTab={this.state.activeTab} style={{ border: '2px' }}
                                                className="tab-space">
                                                <Row>
                                                    <Col sm={4}>
                                                        <FormGroup>
                                                            <Label tag="p">Select a study from the list.</Label>
                                                            <Select
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                placeholder="Select Study"
                                                                name="study"
                                                                isDisabled={editMode}
                                                                value={selectedStudy}
                                                                options={
                                                                    studies && studies.map((val, i) => {
                                                                        return { value: val._id, label: val.clinicalStudy.name, study: val.clinicalStudy.id }
                                                                    })
                                                                }
                                                                onChange={(value) => {
                                                                    this.setState({ selectedStudy: value }, () => {
                                                                        this.loadSiteDetails();
                                                                    })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}></Col>
                                                    <Col sm={2} className="text-center">
                                                        {
                                                            this.state.activeTab === 'ht2' ?
                                                                <Button color="primary" className="btn-round" href={`${Routes.SITES}/${this.state.selectedStudy.value}/adduser`} size="sm">
                                                                    Add User
                                                                </Button>
                                                                : ''
                                                        }
                                                        {
                                                            (this.state.activeTab === 'ht3' || this.state.activeTab === 'ht4' || this.state.activeTab === 'ht5' || this.state.activeTab === 'ht6') ?
                                                                <Button color="primary" className="btn-round" size="sm" onClick={this.alterEditMode}>
                                                                    {editMode ? 'Submit' : 'Change'}
                                                                </Button>
                                                                : ''
                                                        }
                                                    </Col>
                                                </Row>
                                                <TabPane tabId="ht1">
                                                    <SiteStats
                                                        site={site}
                                                        subjectPrefix={subjectPrefix}
                                                        metrics={metrics}
                                                    />
                                                </TabPane>
                                                <TabPane tabId="ht2">
                                                {
                                                    (this.props.siteStaff || this.props.studyStaff )&&
                                                    <RemoteTable
                                                        data={staffData}
                                                        sizePerPage={PAGE_SIZE}
                                                        page={this.state.userPage}
                                                        totalSize={this.props.siteStaffCount + this.props.studyStaffCount}
                                                        columns={userTableColumns((this.state.userPage - 1 ) * PAGE_SIZE, this.changeUserAccess, false)}
                                                        // onItemClick={this.onItemClick}
                                                        onTableChange={this.handleUserTableChange}
                                                    />
                                                }
                                                </TabPane>
                                                <TabPane tabId="ht3">
                                                    <MarkPatients
                                                        patients={this.state.updatedPatients ? this.state.updatedPatients : patients}
                                                        disabled={!editMode}
                                                        enableCreate={true}
                                                        subjectPrefix={subjectPrefix}
                                                        createPatient={createPatient}
                                                        onMarkPatientsUpdate={this.onMarkPatientsUpdate}
                                                        onAddSubjectTrigger={this.onAddSubjectTrigger}
                                                        onAddSubject={this.onAddSubject} />
                                                </TabPane>
                                                <TabPane tabId="ht4">
                                                    <MonitoringPlan
                                                        visits={visits}
                                                        disabled={!editMode}
                                                        domains={domains}
                                                        data={this.state.updatedMonitorData}
                                                        onDataChange={this.onMonitorDataChange} />
                                                </TabPane>
                                                {
                                                    crfType === 'PAPER' &&
                                                    <TabPane tabId="ht5">
                                                        <CRFPages
                                                            onCRFDataChange={this.onCRFDataChange}
                                                            onTotalCRFPagesChange={this.onTotalCRFPagesChange}
                                                            onTotalCRFPagesBlur={this.onTotalCRFPagesBlur}
                                                            totalCRFPages={this.state.updatedTotalCRFPages}
                                                            selectedPages={this.state.updatedSelectedPages}
															disabled={!editMode} />
                                                    </TabPane>
                                                }
                                                <TabPane tabId="ht6">
                                                    <RiskParameters
                                                        disabled={!editMode}
                                                        data={updatedRiskAssessment}
                                                        onChange={this.onRiskAssessmentChange} />
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, sites, dashboard, staff }) => {
    var plans = [{ name: 'ICF' }, { name: 'Randomisation' }, { name: 'Demography' }, { name: 'Medical History' }, { name: 'Concomitant Meds' }, { name: 'Adverse Events' }, { name: 'Vital Signs' }, { name: 'Lab Parameters' }, { name: 'FEV1' }, { name: 'Death' }]
    return {
        token: user.token,
        role : user.role ? user.role.name : undefined,
        siteStaff: staff.siteStaff || [],
        studyStaff: staff.studyStaff || [],
        site: sites.current,
        studies: sites.studies,
        visitsInfo: sites.visits,
        patients: sites.subjects,
        metrics: dashboard.metrics,
        plans: plans,
        siteStaffCount : staff.siteStaffCount,
        studyStaffCount : staff.studyStaffCount
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSiteDetails: (token, id) => SiteActions.getSiteDetails(dispatch, token, id),
        getStudySites: (token, id) => SiteActions.getSiteStudies(dispatch, token, id),
        getSiteSubjects: (token, id) => SiteActions.getSiteSubjects(dispatch, token, id),
        getSiteStaff: (token, id, start, limit, activeOnly) => StaffActions.getSiteStaff(dispatch, token, id, start, limit, activeOnly),
        getStudyStaff: (token, id, start, limit, activeOnly) => StaffActions.getStudyStaff(dispatch, token, id, start, limit, activeOnly),
        
        getSiteVisitDetails: (token, id) => SiteActions.getSiteVisitDetails(dispatch, token, id),
        getMyMetrics: (token, id) => DashboardActions.getSSRMetrics(dispatch, token, id),
        saveSiteDetails: (data) => dispatch({
            type: 'SITE_DETAILS',
            response: data
        }),
        saveSiteStudies: (data) => dispatch({
            type: 'SITE_STUDIES',
            response: data
        }),
        apiError: () => dispatch({
            type: 'GET_TOKEN_ERROR'
        })
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SiteDetails);