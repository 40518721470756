import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    BreadcrumbItem,
    Col,
    Breadcrumb,
} from "reactstrap";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import cogoToast from 'cogo-toast';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";
import { Routes } from 'constants/index.js';
import { UploadFile, UploadDocument, UploadConfirm } from "./Components";
import { SiteActions } from "actions";

import { getToken, createMonitorDocument } from "api/index.js";
import { uploadBlobToStrapi, readFileAsDataURL, readContentAsImage, checkFileSize, getBase64Size } from "commons/Utils.js";

class UploadNewDocument extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            stage: 'upload',
            files: [],
            monitorDoc: undefined,
            editMode: false
        }
    }

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    }

    componentDidMount() {
        const token = this.props.token;
        const siteId = this.props.site && this.props.site._id;
        if (siteId) {
            this.props.getSiteSubjects(token, siteId);
            this.props.getSiteVisits(token, siteId);
        }
    }


    saveRedactedDocument = async (image, index) => {
        var oldFiles = this.state.files;
        oldFiles[index] = { fileType: 'image', previewUrl: image }

        await this.setState({ files: oldFiles, stage: 'data', editMode: false });

    }

    saveLoadedFile = (blob) => {
        var oldFiles = this.state.files;
        const mimeType = (blob.match(/^data:([^;]+);/) || '')[1];
        const fileType = mimeType.includes('image') ? 'image' : mimeType.includes('pdf') ? 'pdf' : undefined
        oldFiles.push({
            fileType: fileType,
            previewUrl: blob
        });

        if (fileType) {
            this.setState({
                files: oldFiles,
                stage: 'data'
            });
        }
    }

    onFileUploadFromComputer = async (e) => {
        for (const file of e.target.files) {
            const validFile = checkFileSize(file, 10)
            if (validFile.valid) {
                const result = await readContentAsImage(await readFileAsDataURL(file));
                for (const img of result) {
                    this.saveLoadedFile(img);
                }
            } else {
                cogoToast.warn(validFile.message)
            }
        }
    }

    onScanCompleted = async (scannedImages) => {
        for (let i = 0; i < scannedImages.length; ++i) {
            this.saveLoadedFile(scannedImages[0].src);
        }
    }

    onScreenshotCapture = async (image) => {

        const validFile = getBase64Size(image)
        if (validFile.valid)
            this.saveLoadedFile(image);
        else
            cogoToast.warn(validFile.message)
    }

    onDocumentDataCollected = (values) => {
        console.log('onDocumentDataCollected', values);
        this.setState({ monitorDoc: values, stage: 'confirm' })
    }

    onAddMorePagesClick = () => {
        this.setState({ stage: 'upload' })
    }

    onDocTypeChange = (docType) => {
        var doc = this.state.monitorDoc;
        if (doc) {
            doc['docType'] = docType;
        } else {
            doc = { docType: docType }
        }
        this.setState({
            monitorDoc: doc
        });
    }

    onSubjectChange = (subject) => {
        var doc = this.state.monitorDoc;
        if (doc) {
            doc['subject'] = subject;
        } else {
            doc = { subject: subject }
        }
        this.setState({
            monitorDoc: doc
        });
    }

    onDescriptionChange = (description) => {
        var doc = this.state.monitorDoc;
        if (doc) {
            doc['description'] = description;
        } else {
            doc = { description: description }
        }
        this.setState({
            monitorDoc: doc
        });
    }

    onEditClick = () => {
        this.setState({ editMode: true });
    }

    onUploadConfirmClick = (values) => {
        //todo create monitor document 
        const identifier = this.props.user.email;
        getToken(identifier, values.password).then(async (response) => {
            try {
                this.props.saveToken(response.data);
                const { files, monitorDoc } = this.state;
                const uploadedBy = `${this.props.user.staffProfile.firstName} ${this.props.user.staffProfile.lastName}`
                var filesData = []
                var pageNos = []
                var i = 5;
                for (const item of files) {
                    pageNos.push(i);
                    const fileResponse = await uploadBlobToStrapi(this.props.user.token, item.previewUrl);
                    filesData.push({ 'version': 1, 'url': fileResponse.data[0].url, 'uploadDate': moment.utc().valueOf(), 'uploadedBy': uploadedBy });
                    i++;
                }

                let monitorDocObj = {
                    subject: monitorDoc.subject._id,
                    studySite: monitorDoc.site._id,
                    docType: monitorDoc.docType,
                    pageNo: pageNos,
                    description: monitorDoc.description,
                    documents: filesData
                }

                // for ICF documents visit and domainData are fixed
                if (monitorDocObj['docType'] === 'ICF') {
                    monitorDocObj['domainData'] = 'ICF';
                    let screening = this.props.visits.find(v => v.name === 'Screening');
                    if (screening) {
                        monitorDocObj['visit'] = screening._id;
                    }
                }

                console.log('monitorDocObj', monitorDocObj);
                await createMonitorDocument(this.props.token, monitorDocObj);
                this.props.history.push(Routes.DOCUMENTS);
            } catch (error) {
                alert('API failed!')
                console.log(error);
            }

        }).catch((e) => {
            const m = e.response ? e.response.data.data[0].messages[0].message : 'Something went wrong';
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        title="Alert"
                        onConfirm={this.hideAlert}
                    >
                        {m}
                    </SweetAlert>
                )
            });
        });
    }

    onCancel = () => {
        this.props.history.push(`${Routes.DOCUMENTS}`)
    }

    onImageDelete = (i) => {
        const { files } = this.state;
        files.splice(i, 1);
        if (files.length === 0) {
            this.setState({ files: files, stage: 'upload' })
        } else {
            this.setState({ files: files })
        }

    }

    render() {
        const { stage, files, monitorDoc, editMode } = this.state;
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.DOCUMENTS}`}>Documents</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Upload Document</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {this.state.alert}
                    {
                        stage === 'upload' ?
                            <UploadFile
                                hideCancel={false}
                                onCancel={this.onCancel}
                                onScanCompleted={this.onScanCompleted}
                                onScreenshotCapture={this.onScreenshotCapture}
                                onFileUploadFromComputer={this.onFileUploadFromComputer} />
                            : null
                    }
                    {
                        stage === 'data' ?
                            <UploadDocument
                                site={this.props.site}
                                study={this.props.study}
                                subjects={this.props.subjects}
                                files={files}
                                editMode={editMode}
                                closeRedactWindow={async e => await this.setState({ editMode: false })}
                                saveRedactedDocument={this.saveRedactedDocument}
                                document={monitorDoc}
                                onEditClick={this.onEditClick}
                                onCancel={this.onCancel}
                                onDescriptionChange={this.onDescriptionChange}
                                onSubjectChange={this.onSubjectChange}
                                onDocTypeChange={this.onDocTypeChange}
                                onAddMorePagesClick={this.onAddMorePagesClick}
                                onImageDelete={this.onImageDelete}
                                onDocumentDataCollected={this.onDocumentDataCollected} />
                            : null
                    }
                    {
                        stage === 'confirm' ?
                            <UploadConfirm
                                {...this.props}
                                document={monitorDoc}
                                files={files}
                                user={this.props.user}
                                onUploadConfirmClick={this.onUploadConfirmClick} />
                            : null
                    }
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, sites, studies }) => {
    return {
        token: user.token,
        user: user,
        subjects: sites.subjects,
        study: studies.current,
        site: sites.current,
        visits: sites.visits,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSiteSubjects: (token, siteId) => SiteActions.getSiteSubjects(dispatch, token, siteId),
        getSiteVisits: (token, siteId) => SiteActions.getSiteVisitDetails(dispatch, token, siteId),
        saveToken: (data) => dispatch({
            type: 'GET_TOKEN',
            response: { ...data }
        }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadNewDocument);
