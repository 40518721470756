const initialState = {

};

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SSR_DASHBOARD':
            return { ...state, metrics: action.response };
        case 'GET_PENDING_ICF_REVIEW_DOCS':
            return { ...state, pendingICFDocs: action.response };
        case 'GET_WAITING_FOR_REVIEW_QUERIES':
            return { ...state, pendingReviewQueries: action.response };
        case 'GET_PENDING_ICF_REVIEW_DOCS_COUNT':
            return { ...state, pendingICFDocCount: action.response };
        case 'GET_WAITING_FOR_REVIEW_QUERIES_COUNT':
            return { ...state, pendingReviewQueriesCount: action.response };
        default:
            return state;
    }
};

export default dashboard;