import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    FormGroup,
    Label,
    Button,
    CustomInput
} from "reactstrap";
// reactstrap validations
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { Routes } from "constants/index.js";

function UploadConfirm(props) {

    const [selectedImage, setSelectedImage] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const handleValidSubmit = (event, values) => {
        setIsUploading(true)
        props.onUploadConfirmClick(values)
    }

    return (
        <Card style={{ minHeight: '756px' }}>
            <CardHeader>
                <CardTitle tag="h5">Confirm Document Upload</CardTitle>
            </CardHeader>
            <CardBody>
                <AvForm onValidSubmit={handleValidSubmit}>
                    <Row style={{ marginLeft: '0px' }}>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Site Name</Label>
                                <p>{props.site ? props.site.name : ''}</p>
                            </FormGroup>
                            <FormGroup>
                                <Label>Protocol</Label>
                                <p>{props.study ? props.study.name : ''}</p>
                            </FormGroup>
                            <FormGroup>
                                <Label>Trial Participant ID</Label>
                                <p>{props.document.subject ? props.document.subject.uniqueCode : ''}</p>
                            </FormGroup>
                            <FormGroup>
                                <Label>Doc Type</Label>
                                <p>{props.document ? props.document.docType : ''}</p>
                            </FormGroup>
                            {
                                props.document && props.document.domainData &&
                                <FormGroup>
                                    <Label>Domain Data</Label>
                                    <p>{props.document ? props.document.domainData : ''}</p>
                                </FormGroup>
                            }
                            {
                                props.document && props.document.visit &&
                                <FormGroup>
                                    <Label>Visit</Label>
                                    <p>{props.document ? props.document.visit.name : ''}</p>
                                </FormGroup>
                            }

                            <h6 style={{ marginTop: '50px' }}>Signature Copy</h6>
                            <AvInput tag={CustomInput} className="checkbox" type="checkbox" name="guidelines" label="Check to accept guidelines" required errorMessage="Please accept the guidelines." />
                            <AvField name="username" autoComplete="off" value={props.user.staffProfile ? `${props.user.staffProfile.firstName} ${props.user.staffProfile.lastName}` : ''} placeholder="Some" label="Name" type="text" disabled />
                            <AvField name="password" autoComplete="off" placeholder="Enter Password" label="Password" type="password" required errorMessage="Please enter your password." onChange={(e) => { setIsUploading(false) }}/>
                        </Col>
                        <Col xs={12} md={12} lg={9}>
                            <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                                <Col xs={10}>
                                    <div className="text-center">
                                        <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                                            <img
                                                alt="." width={600} height={400}
                                                src={props.files[selectedImage].previewUrl}
                                            />
                                        </div>
                                        <div style={{ marginTop: '20px' }} className="text-center">
                                            <Button color="primary" className="btn-round" disabled={isUploading} size="sm" type="submit" >{isUploading ? 'Please wait...' : 'Submit'}</Button>
                                            <Button color="primary" className="btn-round ml-4" outline size="sm" type="button" href={`${Routes.DOCUMENTS}`} >Cancel</Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={2}>
                                    <div style={{ height: '320px', overflowY: 'scroll' }}>
                                        <ul style={{ listStyle: 'none', paddingInlineStart: '0px' }}>
                                            {
                                                props.files && props.files.map((file, i) => {
                                                    return (
                                                        <li>
                                                            <div key={i} className={`extra-document ${selectedImage === i ? 'selected' : ''} mx-auto`} onClick={() => setSelectedImage(i)}><img alt='.' src={file.previewUrl} /></div>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </AvForm>
            </CardBody>
        </Card>
    );
};

export default UploadConfirm;
