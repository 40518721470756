const initialState = {
    data: []
};

const studies = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STUDIES':
            return { ...state, data: action.response }
        case 'GET_STUDIES_COUNT':
            return { ...state, count: action.response }
        case 'STUDY_DETAILS':
            return { ...state, current: action.response }
        case 'SITES_BY_STUDY':
            return { ...state, sites: action.response }
        case 'SITES_BY_STUDY_COUNT':
            return { ...state, sitesCount: action.response }
        case 'PROCESSDOCS_BY_STUDY':
            return { ...state, processDocs: action.response }
        case 'PROCESSDOCS_COUNT_BY_STUDY':
            return { ...state, processDocsCount: action.response }
        case 'STUDIES_BY_STAFF':
            return { ...state, myStudies: action.response }
        case 'STUDY_CCRA':
            return { ...state, ccra: action.response }
        case 'MONITOR_REPORTS':
            return { ...state, monitorReports: action.response }
        case 'MONITOR_REPORTS_COUNT':
            return { ...state, monitorReportsCount: action.response }
        case 'GET_TOKEN_ERROR':
            return { ...state, current: {} }
        case 'ACTIVE_SITES':
            return { ...state, activeSites: action.response }
        default:
            return state;
    }
};

export default studies;