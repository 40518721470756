import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody, CustomInput
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import { DocumentActions } from "actions/index.js";
import { getToken, createQuery } from "api/index.js";
import { UploadFile } from "views/Documents/Components";
import { uploadBlobToStrapi, readFileAsDataURL, readContentAsImage, checkFileSize } from "commons/Utils.js";
import cogoToast from "cogo-toast";
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import { Validations } from "constants/index";

class RaiseEdcQuery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            designation: null,
            imagePreviewUrl: null,
            documentPopup: false,
            password: '',
            alert: null,
            checkAccept: false,
            queryDetails: "",
            isUploadnig:false
        };
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onEdcQuerySubmission = this.onEdcQuerySubmission.bind(this);
        this.checkBoxOnClick = this.checkBoxOnClick.bind(this);
    }

    componentDidMount() {
        this.props.getDocumentDetails(this.props.token, this.props.id);
    }

    checkBoxOnClick(e) {
        this.setState({
            checkAccept: e.target.checked
        })

    }

    onChange(e) {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onCancel() {
        this.props.close();
    }

    onScreenshotCapture = async (image) => {
        await this.setState({ imagePreviewUrl: image });
    }

    onFileUploadFromComputer = async (e) => {
        const file = e.target.files[0];
        const validFile = checkFileSize(file, 10)
        if (validFile.valid) {
            this.setState({ isUploading: true })
            const result = await readContentAsImage(await readFileAsDataURL(file));
            if (result && result.length > 0) {
                this.setState({ imagePreviewUrl: result[0] });
            }
            this.setState({ isUploading: false })
        } else {
            cogoToast.warn(validFile.message)
        }
    }

    onScanCompleted = async (scannedImages) => {
        await this.setState({ imagePreviewUrl: scannedImages[0].src });
    }

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    }

    onEdcQuerySubmission = (event, values) => {
        this.setState({ isUploading: true });
        
        if (this.state.imagePreviewUrl) {
            const identifier = this.props.user.email;
            getToken(identifier, values.password).then(async (response) => {
                this.props.saveToken(response.data);
                try {
                    this.setState({ isUploading: true })
                    // upload attachment file
                    let fileData = await uploadBlobToStrapi(this.props.user.token, this.state.imagePreviewUrl);

                    // create edc query
                    let monitorDocQueryObj = {
                        subject: this.props.crfDocument.subject.id,
                        studySite: this.props.crfDocument.studySite.id,
                        monitorDocument: this.props.crfDocId,
                        attachment: fileData.data[0].url,
                        linkSourceCRFDocument: this.props.sourceDocument ? this.props.sourceDocument.id : null,
                        docVersion: String(this.props.crfDocument.versionedDocument.length),
                        queryType: "EDC_QUERY",
                        title: values.queryDetails,
                        query: values.queryDetails,
                        queryStatus: 'OPENED',
                        owner: this.props.user.staffProfile._id,
                        linkVersion: this.props.sourceDocument ? String(this.props.sourceDocument.versionedDocument.length) : '1',
                        cancelRemarks: "",
                        isDeleted: false,
                    }
                    await createQuery(this.props.token, monitorDocQueryObj);
                    this.props.close();
                    const { hide } = cogoToast.success('EDC query raised successfully.', {
                        onClick: () => {
                            hide();
                        },
                        position: "top-right",
                        hideAfter: 3
                    });
                } catch (error) {
                    console.log(error);
                    this.setState({ isUploading: false })
                }

            }).catch((e) => {
                this.setState({ isUploading: false })
                const m = e.response ? e.response.data.data[0].messages[0].message : 'Something went wrong';
                this.setState({
                    alert: (
                        <SweetAlert
                            warning
                            title="Alert"
                            onConfirm={this.hideAlert}
                        >
                            {m}
                        </SweetAlert>
                    )
                });
            });
        } else {
            cogoToast.error('Please upload the image')
            this.setState({ isUploading: false })
        }
        
    }
    render() {
        let { imagePreviewUrl, isUploading } = this.state;
        return (
            <div className="content">
                {this.state.alert}
                <Modal
                    isOpen={this.state.documentPopup}
                    size="lg"
                    centered
                    toggle={async () => await this.setState({ documentPopup: false })}
                    backdrop="static"
                    keyboard={false}
                >
                    <ModalHeader toggle={async () => await this.setState({ documentPopup: false })}>
                        Document Preview
                    </ModalHeader>
                    <ModalBody>
                        <div className="query-image">
                            <img alt="." src={this.state.imagePreviewUrl} />
                        </div>
                    </ModalBody>
                </Modal>
                <AvForm onValidSubmit={this.onEdcQuerySubmission}>
                    <Row>
                        <h5 tag="h5">Query Raised In EDC System</h5>
                        <Col md="12">
                            <Row style={{ marginLeft: '0px' }}>
                                <Col xs={12} sm={4}>
                                    <Row>
                                        <Col sm={10}>
                                            <AvField name="queryDetails" autoComplete="off" placeholder="Query Number" label="Enter Query No. in EDC System" type="text" validate={{
                                                required: { value: true, errorMessage: 'Please fill EDC Query No.' },
                                                pattern: Validations.ALPHA_NUMERCIS,
                                                maxLength: { value: 10, errorMessage: 'EDC Query No. must not be more than 10 characters.' }
                                            }} />
                                            <AvGroup>
                                                <p>Signature Copy</p>
                                                <AvInput tag={CustomInput} className="checkbox" type="checkbox" name="guidelines" label="Check to accept guidelines" required errorMessage="Please accept the guidelines." />
                                            </AvGroup>

                                            <AvField name="username" autoComplete="off" value={this.props.user.staffProfile ? `${this.props.user.staffProfile.firstName} ${this.props.user.staffProfile.lastName}` : ''} placeholder="Name" label="Name" type="text" disabled />
                                            <AvField name="password" autoComplete="off" placeholder="********" label="Password" type="password" required errorMessage="Please enter your password." />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={8}>
                                    <Row>
                                        {
                                            imagePreviewUrl ?
                                                (<div
                                                    className="text-center ml-auto query-image"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={async () => await this.setState({ documentPopup: true })}
                                                >
                                                    <img alt="." src={imagePreviewUrl} />
                                                </div>) :
                                                (<UploadFile
                                                    small={true}
                                                    onScanCompleted={this.onScanCompleted}
                                                    onScreenshotCapture={this.onScreenshotCapture}
                                                    onFileUploadFromComputer={this.onFileUploadFromComputer}
                                                />)
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Button color="primary ml-3" className="btn-round" size="sm" type="submit" disabled={isUploading}>
                          {isUploading ? 'Please wait..' : 'Submit'}
                    </Button>
                        <Button color="primary ml-3" className="btn-round" size="sm" type="button" onClick={this.onCancel}>
                            Cancel
                    </Button>
                    </Row>
                </AvForm>
            </div>

        )
    }
}


const mapStateToProps = ({ user, documents, studies }) => {
    return {
        token: user.token,
        details: documents.details,
        study: studies.myStudy,
        user: user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDocumentDetails: (token, id) => DocumentActions.getDocumentDetails(dispatch, token, id),
        saveToken: (data) => dispatch({
            type: 'GET_TOKEN',
            response: { ...data }
        }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RaiseEdcQuery);
