import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { Routes as RoutePaths, Roles } from "constants/index";
import Login from "views/Login/Login"
import Sponsors from 'views/Sponsors/Sponsors';
import SponsorDetails from 'views/Sponsors/SponsorDetails';
import Sites from 'views/Sites/Sites';
import Studies from 'views/Studies/Studies';
import Profile from 'views/Profile/Profile';
import Staff from 'views/Staff/Staff';
import StaffDetails from 'views/Staff/StaffDetails';
import SiteDetails from 'views/Sites/SiteDetails';
import SponsorAdd from 'views/Sponsors/SponsorAdd';
import SiteAdd from 'views/Sites/SiteAdd';
import StudyAdd from 'views/Studies/StudyAdd';
import StaffAdd from 'views/Staff/StaffAdd';
import UploadMonitorDocument from 'views/Studies/UploadMonitorDocument';
import UploadMonitorReport from 'views/Studies/UploadMonitorReport';
import Documents from 'views/Documents/Documents';
import DocumentDetails from 'views/Documents/DocumentDetails';
import RaiseImageQuery from 'views/Queries/SubmitQuery/RaiseImageQuery';
import Popup from 'views/Documents/Components/Popup';
import UpdateImageRecertify from 'views/Documents/UpdateImageRecertify';
import AddSiteUser from 'views/Sites/AddSiteUser';
import AddStudyUser from 'views/Studies/AddStudyUser';
import Queries from 'views/Queries/Queries';
import RaiseSourceDataQuery from 'views/Queries/SubmitQuery/RaiseSourceDataQuery';
import Reports from 'views/Reports/Reports';
import Protocol from 'views/Protocol/Protocol';
import StudyInformation from 'views/StudyInformation/StudyInformation';
import SDocuments from 'views/StudyInformation/SDcoument';
import CRFPage from 'views/StudyInformation/CRFPage';
import Search from 'views/Search/Search';
import RootDashboard from 'views/Dashboard/RootDashboard';
import UploadNewDocument from 'views/Documents/UploadNewDocument';
import StaffView from 'views/Staff/StaffView';
import ConductSDV from 'views/Documents/ConductSDV';
import RaiseMetaQuery from 'views/Queries/SubmitQuery/RaiseMetaQuery';
import RootStudyDetails from 'views/Studies/RootStudyDetails';
import ViewQueryDetails from 'views/Queries/ViewQueryDetails';
import EditQueryDetails from 'views/Queries/EditQueryDetails';
import ViewQueryResponse from 'views/Queries/ViewQueryResponse';
import AnswerQuery from 'views/Queries/AnswerQuery';
import EditDocument from 'views/Documents/EditDocument';
import CertifyDocument from 'views/Documents/CertifyDocument';
import ForgetPassword from 'views/Login/ForgetPassword';
import SetResetPassword from 'views/Login/SetResetPassword';
import RootMetrics from 'views/Metrics/RootMetrics';
import secureStorage from './secureStorage';

const isSysAdmin = () => {
    let value = secureStorage.getItem('state');
    if (value && value !== null) {
        const user = value.user;
        const role = user.role;
        return user.isAuthenticated && role.name === Roles.SYSTEM_ADMIN;
    }
    return false;
}

const isSiteStaff = () => {
    let value = secureStorage.getItem('state');
    if (value && value !== null) {
        const user = value.user;
        const role = user.role;
        return user.isAuthenticated && [Roles.CCRA, Roles.SITE_SCANNER, Roles.SITE_SCANNER_RESOLVER, Roles.DM, Roles.DE, Roles.PM, Roles.CSM].indexOf(role.name) > -1;
    }
    return false;
}

const isAuthenticated = () => {
    let value = secureStorage.getItem('state');
    if (value && value !== null) {
        const user = value.user;
        return user.isAuthenticated;
    }
    return false;
}

const ProctectedRoute = ({ component: Component, authCheck, ...rest }) => (
    <Route {...rest} render={(props) => {
        if (authCheck() && Component) {
            return <Component {...props} />
        } else {
            if (isSysAdmin())
                return <Redirect to={RoutePaths.DASHBOARD} />
            else if (isSiteStaff())
                return <Redirect to={RoutePaths.PROTOCOL} />
            else {
                // return <Redirect to={{ pathname: '/login', callback: props.location }} />
                return <Redirect to={'/login'} />
            }
        }
    }} />
)

const Router = (props) => {

    React.useEffect(
        () => {
            window.scrollTo(0, 0);
        },
        [props.location]
    );

    return (
        <Switch>
            <Route path="/forgetpassword" exact component={ForgetPassword} />
            <Route path="/resetpassword" exact component={() => <SetResetPassword {...props} option={'reset'} />} />
            <Route path="/activate" exact component={() => <SetResetPassword {...props} option={'set'} />} />
            <Route path="/login" exact component={Login} />
            {/* root dashboard */}
            <ProctectedRoute path={RoutePaths.DASHBOARD} authCheck={isAuthenticated} exact component={RootDashboard} />
            {/* sponsors */}
            <ProctectedRoute path={RoutePaths.SPONSORS} authCheck={isSysAdmin} exact component={Sponsors} />
            <ProctectedRoute path={`${RoutePaths.SPONSORS}/add`} exact authCheck={isSysAdmin} component={SponsorAdd} />
            <ProctectedRoute path={`${RoutePaths.SPONSORS}/:id`} exact authCheck={isSysAdmin} component={SponsorDetails} />
            {/* sites */}
            <ProctectedRoute path={RoutePaths.SITES} exact authCheck={isSysAdmin} component={Sites} />
            <ProctectedRoute path={`${RoutePaths.SITES}/add`} authCheck={isSysAdmin} exact component={SiteAdd} />
            <ProctectedRoute path={`${RoutePaths.SITES}/:id`} authCheck={isSysAdmin} exact component={SiteDetails} />
            <ProctectedRoute path={`${RoutePaths.SITES}/:id/adduser`} authCheck={isSysAdmin} exact component={AddSiteUser} />
            {/* staff */}
            <ProctectedRoute path={`${RoutePaths.STAFF}/add`} authCheck={isSysAdmin} exact component={StaffAdd} />
            <ProctectedRoute path={`${RoutePaths.STAFF}/view/:id`} authCheck={isSiteStaff} exact component={StaffView} />
            <ProctectedRoute path={`${RoutePaths.STAFF}/:id`} authCheck={isAuthenticated} exact component={StaffDetails} />
            <ProctectedRoute path={`${RoutePaths.STAFF}`} exact authCheck={isAuthenticated} component={Staff} />
            {/* queries */}
            <ProctectedRoute path={`${RoutePaths.QUERIES}`} exact authCheck={isSiteStaff} component={Queries} />
            <ProctectedRoute path={`${RoutePaths.QUERIES}/add`} exact authCheck={isSiteStaff} component={RaiseSourceDataQuery} />
            <ProctectedRoute path={`${RoutePaths.QUERIES}/answer/:id`} exact authCheck={isSiteStaff} component={AnswerQuery} />
            <ProctectedRoute path={`${RoutePaths.QUERIES}/response/:id`} exact authCheck={isSiteStaff} component={ViewQueryResponse} />
            <ProctectedRoute path={`${RoutePaths.QUERIES}/:id`} exact authCheck={isSiteStaff} component={ViewQueryDetails} />
            <ProctectedRoute path={`${RoutePaths.QUERIES}/editQuery/:id`} exact authCheck={isSiteStaff} component={EditQueryDetails} />
            {/* metrics */}
            <ProctectedRoute path={`${RoutePaths.METRICS}`} exact authCheck={isSiteStaff} component={RootMetrics} />
            {/* reports */}
            <ProctectedRoute path={`${RoutePaths.REPORTS}`} exact authCheck={isSiteStaff} component={Reports} />
            {/* study info */}
            <ProctectedRoute path={`${RoutePaths.STUDYINFORMATION}`} exact authCheck={isSiteStaff} component={StudyInformation} />
            <ProctectedRoute path={`${RoutePaths.STUDYINFORMATION}/add`} exact authCheck={isSiteStaff} component={CRFPage} />
            <ProctectedRoute path={`${RoutePaths.STUDYINFORMATION}/document`} exact authCheck={isSiteStaff} component={SDocuments} />
            {/* studies */}
            <ProctectedRoute path={`${RoutePaths.STUDIES}`} exact authCheck={isSysAdmin} component={Studies} />
            <ProctectedRoute path={`${RoutePaths.STUDIES}/add`} exact authCheck={isSysAdmin} component={StudyAdd} />
            <ProctectedRoute path={`${RoutePaths.STUDIES}/:id`} exact authCheck={isAuthenticated} component={RootStudyDetails} />
            <ProctectedRoute path={`${RoutePaths.STUDIES}/:id/uploaddocument`} exact authCheck={isAuthenticated} component={UploadMonitorDocument} />
            <ProctectedRoute path={`${RoutePaths.STUDIES}/:id/uploadreport`} exact authCheck={isSiteStaff} component={UploadMonitorReport} />
            <ProctectedRoute path={`${RoutePaths.STUDIES}/:id/newsite`} exact authCheck={isAuthenticated} component={SiteAdd} />
            <ProctectedRoute path={`${RoutePaths.STUDIES}/:id/adduser`} authCheck={isAuthenticated} exact component={AddStudyUser} />
            {/* profile */}
            <ProctectedRoute path={`${RoutePaths.PROFILE}`} exact authCheck={isAuthenticated} component={Profile} />
            {/* protocol */}
            <ProctectedRoute path={`${RoutePaths.PROTOCOL}`} exact authCheck={isSiteStaff} component={Protocol} />
            {/* searcg */}
            <ProctectedRoute path={`${RoutePaths.SEARCH}`} exact authCheck={isSysAdmin} component={Search} />
            {/*documents */}
            <ProctectedRoute path={`${RoutePaths.DOCUMENTS}`} exact authCheck={isSiteStaff} component={Documents} />
            <ProctectedRoute path={`${RoutePaths.DOCUMENTS}/add`} exact authCheck={isSiteStaff} component={UploadNewDocument} />
            <ProctectedRoute path={`${RoutePaths.DOCUMENTS}/:id/edit`} exact authCheck={isSiteStaff} component={EditDocument} />
            <ProctectedRoute path={`${RoutePaths.DOCUMENTS}/:id/certify`} exact authCheck={isSiteStaff} component={CertifyDocument} />
            <ProctectedRoute path={`${RoutePaths.DOCUMENTS}/:id/:index/raiseImg`} exact authCheck={isSiteStaff} component={RaiseImageQuery} />
            <ProctectedRoute path={`${RoutePaths.DOCUMENTS}/:id/:index/raiseMeta`} exact authCheck={isSiteStaff} component={RaiseMetaQuery} />
            <ProctectedRoute path={`${RoutePaths.DOCUMENTS}/conductSdv/:id`} exact authCheck={isSiteStaff} component={ConductSDV} />
            <ProctectedRoute path={`${RoutePaths.DOCUMENTS}/upload`} exact authCheck={isSiteStaff} component={UploadNewDocument} />
            <ProctectedRoute path={`${RoutePaths.DOCUMENTS}/popup`} exact authCheck={isSiteStaff} component={Popup} />
            <ProctectedRoute path={`${RoutePaths.DOCUMENTS}/updateImg`} exact authCheck={isSiteStaff} component={UpdateImageRecertify} />
            <ProctectedRoute path={`${RoutePaths.DOCUMENTS}/:id`} exact authCheck={isSiteStaff} component={DocumentDetails} />
            {/* login or redirect */}
            <ProctectedRoute path="/" exact authCheck={isAuthenticated} />
            <Redirect to="/" />
        </Switch>
    );
};
export default withRouter(Router);