import Axios from 'axios';
import { SITES_URL, SITES_LIST_URL, SITE_SUBJECTS_URL, SITE_STAFF_PIVOT_URL, SITE_VISITS_URL, MONITOR_DOC_URL, MONITOR_DOC_QUERIES_URL } from 'api/list';

const getSites = (dispatch, token, params, start, limit, count=false) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    if (count) {
        Axios.get(`${SITES_URL}/countByStudy?${params}`, { headers })
            .then((response) => {
                dispatch({
                    type: 'GET_SITES_COUNT',
                    response: response.data

                });
            })
            .catch((e) => {
                if (e && e.response && e.response.status === 401) {
                    dispatch({
                        type: 'GET_TOKEN_ERROR',
                    });
                }
                console.log('error', e);
            });
    }
        
    Axios.get(`${SITES_LIST_URL}?_start=${start}&_limit=${limit}&${params}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_SITES',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getAllSites = (dispatch, token) => {

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    Axios.get(`${SITES_LIST_URL}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_SITES',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getSiteDetails = (dispatch, token, id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${SITES_URL}/${id}`, { headers })
        .then((response) => {
            dispatch({
                type: 'SITE_DETAILS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getSiteSubjects = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${SITE_SUBJECTS_URL}?studySite._id=${siteId}&_sort=id:ASC&_limit=100`, { headers })
        .then((response) => {
            dispatch({
                type: 'SITE_SUBJECTS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getSiteSubjectsRecruited = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${SITE_SUBJECTS_URL}/count?monitorSelect=true&studySite._id=${siteId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'SITE_SUBJECTS_RECRUITED',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getSiteQueriesCount = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_QUERIES_URL}/count?studySite._id=${siteId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'SITE_QUERIES_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

/*const getSiteStaff = (dispatch, token, siteId, studyId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    // get site level staff and study level staff
    
    Axios.get(`${SITE_STAFF_PIVOT_URL}?studySite.id=${siteId}&accessLevel=STUDY_SITE`, { headers })
        .then((response) => {
            let data = response.data;
            Axios.get(`${SITE_STAFF_PIVOT_URL}?clinicalStudy.id=${studyId}&accessLevel=CLINICAL_STUDY`, { headers })
                .then ((response) => {
                    data = data.concat(response.data);
                    dispatch({
                        type: 'SITE_STAFF',
                        response: data
                    });
                });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}*/

const getSiteVisitDetails = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${SITE_VISITS_URL}?studySite._id=${siteId}&_sort=id:ASC`, { headers })
        .then((response) => {
            dispatch({
                type: 'SITE_VISIT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getSiteStudies = (dispatch, token, siteName) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${SITES_URL}?name=${siteName}`, { headers })
        .then((response) => {
            dispatch({
                type: 'SITE_STUDIES',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getPagesUploadedBySite = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_URL}/count?studySite._id=${siteId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'PAGES_UPLOADED_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getQueriesCountBySite = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_QUERIES_URL}/count?studySite._id=${siteId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'SITE_QUERIES_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getLongestOutstandingQuery = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_QUERIES_URL}/count?studySite._id=${siteId}&_sort=createdAt:ASC&_limit=1&queryStatus_nin=CLOSED&queryStatus_ne=CANCELLED`, { headers })
        .then((response) => {
            dispatch({
                type: 'SITE_QUERIES_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getRaisedByDetails = (dispatch, token, siteId, studyId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${SITE_STAFF_PIVOT_URL}?studySite._id=${siteId}&role.name=cCRA&isAccess=true&_limit=100`, { headers })
        .then((response) => {
            let siteStaff = response.data;
            Axios.get(`${SITE_STAFF_PIVOT_URL}?clinicalStudy._id=${studyId}&role.name=Data Manager&role.name=CSM&isAccess=true&_limit=100`, { headers })
                .then((response) => {
                dispatch({
                    type: 'SITE_QUERIES_RAISED_BY',
                    response: siteStaff.concat(response.data)
                });
            }).catch((e) => {
                if (e && e.response && e.response.status === 401) {
                    dispatch({
                        type: 'GET_TOKEN_ERROR',
                    });
                }
                console.log('error', e);
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

export default {
    getSiteVisitDetails, getSites, getAllSites, getSiteDetails, getSiteSubjects,
    getSiteSubjectsRecruited, getSiteQueriesCount, getSiteStudies,
    getPagesUploadedBySite, getQueriesCountBySite, getLongestOutstandingQuery, getRaisedByDetails
}
