import React from "react";
import { connect } from 'react-redux';

import {
    Button,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Label,
    Col,
    Breadcrumb,
    BreadcrumbItem,
    Input,
} from 'reactstrap';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
//core components
import { PanelHeader } from "components";
import DashboardLayout from "layouts/Admin"
import { Routes, Roles, DEFAULT_PASSWORD, Validations } from 'constants/index.js';
import { SiteActions, StaffActions } from 'actions'
import { createSiteStaffPivot, createStaff } from "api";
import cogoToast from "cogo-toast";
class AddSiteUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            createType: 'select',
            site: null,
            isUploading:false
        }
        this.form = null;
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props !== state) {
            if (props.site && props.site !== state.site) {
                props.getStudies(props.token, props.site.name)
            }

            return {
                site: props.site,
            };
        }
        return null;
    }

    componentDidMount() {
        const token = this.props.token;
        const siteId = this.props.match.params.id;
        this.props.getSiteDetails(token, siteId);
        // this.props.getStaff(token);
    }

    onRoleSelection = (role) => {
        this.props.getStaff(this.props.token, `user.role.name=${role}`)
    }

    async handleValidSubmit(event, values) {
        this.setState({ isUploading: true });
        const siteId = this.props.match.params.id;
        const createType = this.state.createType;
        const token = this.props.token;
        var staffType = 'SITE_STAFF'
        var accessLevel = 'STUDY_SITE'
        var study = null
        var userProfile = null;
        try {
            if (createType === 'select') {
                console.log(values);
                study = values.selectStudy;
                userProfile = this.props.staff.filter(val => val._id === values.user)[0]
                const role = userProfile.user.role;

                if ([Roles.SITE_SCANNER_RESOLVER, Roles.SITE_SCANNER].includes(role)) {
                    staffType = 'SITE_STAFF';
                } else {
                    staffType = 'EMPLOYEE';
                }

                if ([Roles.PM, Roles.DE, Roles.DM, Roles.CSM, Roles.SYSTEM_ADMIN].includes(role)) {
                    accessLevel = 'CLINICAL_STUDY';
                } else {
                    accessLevel = 'STUDY_SITE';
                }

                const siteStaff = { accessLevel: accessLevel, staffProfile: userProfile._id, clinicalStudy: study, studySite: siteId, dataEntryRole: 'DE1', role: role }
                await createSiteStaffPivot(token, siteStaff);
                const { hide } = cogoToast.success('Site User added successfully.', {
                  onClick: () => {
                    hide();
                  },
                  position:"top-right",
                  hideAfter:3
                });
                this.props.history.push(`${Routes.SITES}/${siteId}`);
            } else {
                study = values.createStudy;

                if ([Roles.SITE_SCANNER_RESOLVER, Roles.SITE_SCANNER].includes(values.role)) {
                    staffType = 'SITE_STAFF';
                } else {
                    staffType = 'EMPLOYEE';
                }

                if ([Roles.PM, Roles.DE, Roles.DM, Roles.CSM].includes(values.role)) {
                    accessLevel = 'CLINICAL_STUDY';
                } else {
                    accessLevel = 'STUDY_SITE';
                }

                var staff = { firstName: values.firstName, lastName: values.lastName, mobileNo: values.mobileNo, email: values.email, role: values.role, staffType: staffType, password: DEFAULT_PASSWORD }

                var staffResponse = await createStaff(token, staff);
                console.log(staffResponse.data._id);
                const siteStaff = { accessLevel: accessLevel, staffProfile: staffResponse.data._id, clinicalStudy: study, studySite: siteId, dataEntryRole: 'DE1', role: values.role }
                await createSiteStaffPivot(token, siteStaff);
                const { hide } = cogoToast.success('Site User added successfully.', {
                  onClick: () => {
                    hide();
                  },
                  position:"top-right",
                  hideAfter:3
                });
                this.props.history.push(`${Routes.SITES}/${siteId}`);
            }
        } catch (error) {
            this.setState({ isUploading: false})
            if (error.response && error.response.status === 400) {
                if (error.response.data.message.email)
                    alert(error.response.data.message.email.message);
                if (error.response.data.message.staffProfile)
                    alert(error.response.data.message.staffProfile.message);
            } else {
                console.log(error);
            }
        }
    }

    render() {
        const { createType,isUploading } = this.state;
        const { staff, studies } = this.props;
        return (
            <>
                <DashboardLayout>
                    <PanelHeader size="sm" >
                        <Col>
                            <Breadcrumb tag="nav" listTag="div">
                                <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                                <BreadcrumbItem tag="a" href={`${Routes.SITES}`}>Sites</BreadcrumbItem>
                                <BreadcrumbItem tag="a" href={`${Routes.SITES}/${this.props.match.params.id}`}>Site Details</BreadcrumbItem>
                                <BreadcrumbItem active tag="span">Add User</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </PanelHeader>
                    <div className="content">
                        <Row>
                            <Col md="12">
                                <Card style={{ minHeight: '755px' }}>
                                    <CardHeader>
                                        <CardTitle tag="h4">Add New User</CardTitle>
                                        <p>Select user from the list or create a new user.</p>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                                            <Row style={{ marginBottom: '20px' }}>
                                                <Col sm={3}>
                                                    <AvGroup check className="form-check-radio" inline>
                                                        <Label check style={{ paddingLeft: '12px' }}>
                                                            <Input
                                                                name="createType"
                                                                type="radio"
                                                                checked={createType === 'select'}
                                                                onChange={(value) => {
                                                                    this.setState({ createType: 'select' })
                                                                }}
                                                            />
                                                            <span className="form-check-sign" />
                                                            Select User
                                                        </Label>
                                                        <Label check style={{ paddingLeft: '12px', marginLeft: '20px' }}>
                                                            <Input
                                                                name="createType"
                                                                type="radio"
                                                                checked={createType === 'create'}
                                                                onChange={(value) => {
                                                                    this.setState({ createType: 'create' })
                                                                }}
                                                            />
                                                            <span className="form-check-sign" />
                                                    Create New User
                                                </Label>
                                                    </AvGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {
                                                    (createType === 'select') ?
                                                        <>
                                                            <Col sm={3}>
                                                                <AvField className="minimal" type="select" name="role" label="Select Role" required errorMessage='Please select a role.'
                                                                    onChange={(e) => this.onRoleSelection(e.target.value)}
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value={Roles.CCRA}>{Roles.CCRA}</option>
                                                                    <option value={Roles.SITE_SCANNER_RESOLVER}>{Roles.SITE_SCANNER_RESOLVER}</option>
                                                                    <option value={Roles.SITE_SCANNER}>{Roles.SITE_SCANNER}</option>
                                                                </AvField>
                                                                <AvField className="minimal" type="select" placeholder="User" name="user" label="Select User" errorMessage='Please select a user.'
                                                                    required={true} >
                                                                    <option value="">Select</option>
                                                                    {
                                                                        staff && staff.map((val, i) => {
                                                                            return <option key={i} value={val._id}>{`${val.firstName} ${val.lastName}`}</option>
                                                                        })
                                                                    }
                                                                </AvField>
                                                                <AvField className="minimal" 
                                                                    disabled
                                                                    type="select" name="selectStudy" 
                                                                    label="Select Study" 
                                                                    errorMessage='Please select a study.'
                                                                    value={this.props.site ? this.props.site.clinicalStudy.id : null}
                                                                    required={true}  >
                                                                    <option value="">Select</option>
                                                                    {
                                                                        studies && studies.map((val, i) => {
                                                                            const study = val.clinicalStudy;
                                                                            return <option key={i} value={study._id}>{study.name}</option>
                                                                        })
                                                                    }
                                                                </AvField>
                                                            </Col>
                                                        </> : null
                                                }
                                                {
                                                    (createType === 'create') ?
                                                        <>
                                                            <Col sm={3}>
                                                                <AvField name="firstName" autoComplete="off" placeholder="First Name" label="First Name" type="text" validate={{
                                                                    required: { value: true, errorMessage: 'Please enter a first name.' },
																	pattern: Validations.ALPHABETS_WITH_SPACES,
                                                                    minLength: { value: 4, errorMessage: 'Your first name must be at least 4 characters' },
                                                                    maxLength: { value: 50, errorMessage: 'Your first name must not be more than 50 characters.' },
                                                                }} />
                                                                <AvField name="mobileNo" autoComplete="off" placeholder="+447911123456" label="Contact Number" type="text"  validate={{
																	required: { value: true, errorMessage: 'Please enter a contact number.' },
																	pattern: Validations.TELEPHONE,
																}} />
                                                                <AvField className="minimal" type="select" name="role" label="Select Role" required errorMessage='Please select a role.' onChange={(e) => this.setState({ role: e.target.value })}>
                                                                    <option value="">Select</option>
                                                                    <option value={Roles.CCRA}>{Roles.CCRA}</option>
                                                                    <option value={Roles.SITE_SCANNER_RESOLVER}>{Roles.SITE_SCANNER_RESOLVER}</option>
                                                                    <option value={Roles.SITE_SCANNER}>{Roles.SITE_SCANNER}</option>
                                                                </AvField>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <AvField name="lastName" autoComplete="off" placeholder="Last Name" label="Last Name" type="text" validate={{
                                                                    required: { value: true, errorMessage: 'Please enter a last name.' },
																	pattern: Validations.ALPHABETS_WITH_SPACES,
                                                                    minLength: { value: 4, errorMessage: 'Your last name must be at least 4 characters' },
                                                                    maxLength: { value: 50, errorMessage: 'Your last name must not be more than 50 characters.' }
                                                                }
                                                                } />
                                                                <AvField name="email" autoComplete="off" placeholder="someone@mail.com" required label="Email ID" type="email" validate={{
                                                                    email: { value: true, errorMessage: 'Please enter a valid email address.' }
                                                                }} />
                                                                <AvField 
                                                                    className="minimal" 
                                                                    type="select" 
                                                                    name="createStudy" 
                                                                    label="Select Study" 
                                                                    errorMessage='Please select a study.'
                                                                    disabled
                                                                    value={this.props.site ? this.props.site.clinicalStudy.id : null}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {
                                                                        studies && studies.map((val, i) => {
                                                                            const study = val.clinicalStudy;
                                                                            return <option key={i} value={study._id}>{study.name}</option>
                                                                        })
                                                                    }
                                                                </AvField>
                                                            </Col>
                                                        </>
                                                        : null
                                                }
                                            </Row>
                                            <Button color="primary" className="btn-round" type="submit" size="sm" disabled={isUploading}>
                                            {isUploading ? 'Please wait..' : 'Add User'}</Button>
                                            <Button color="primary" type="button" outline className="btn-round" size="sm" style={{ marginLeft: '20px' }} href={`${Routes.SITES}/${this.props.match.params.id}`}>Cancel</Button>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </DashboardLayout>
            </>
        );
    }
}

const mapStateToProps = ({ user, sites, staff }) => {
    return {
        token: user.token,
        site: sites.current,
        studies: sites.studies,
        staff: staff.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSiteDetails: (token, id) => SiteActions.getSiteDetails(dispatch, token, id),
        getStudies: (token, name) => SiteActions.getSiteStudies(dispatch, token, name),
        getStaff: (token, params) => StaffActions.getStaff(dispatch, token, params)
    };
};
export default (connect(mapStateToProps, mapDispatchToProps)(AddSiteUser));