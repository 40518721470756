import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
    Button,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    TabContent,
    TabPane,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
    FormGroup,
} from "reactstrap";
import _ from 'lodash';
import FileSaver from 'file-saver';
// core components
import DashboardLayout from "layouts/Admin"
import { Routes, Roles } from "constants/index.js";
import { MonitoringPlan, PanelHeader, RiskParameters, Documents, RemoteTable } from "components";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import * as moment from 'moment';
import defaultAvatar from "assets/img/default_profile.jpg";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import { SponsorInfo } from ".";
import { StudyActions, SiteActions } from 'actions'
import { CRFPages, MarkPatients } from "../../Sites/Components";
import { updateStudy, updateCRFPages, updateSite, createSiteSubject, updateMonitoringPlan, updateSiteSubject } from "api";
import { PAGE_SIZE } from "api/list";
import { RenderImage } from "components";
import { getFileFromUrl } from "commons";

const tabs = [
    { id: 'ht1', title: 'Sponsor', roles: [Roles.PM, Roles.DM, Roles.DE, Roles.CSM, Roles.CCRA, Roles.SITE_SCANNER, Roles.SITE_SCANNER_RESOLVER] },
    { id: 'ht2', title: 'Documents', roles: [Roles.PM, Roles.DM, Roles.DE, Roles.CSM, Roles.CCRA, Roles.SITE_SCANNER, Roles.SITE_SCANNER_RESOLVER] },
    { id: 'ht3', title: 'Trial Participants', roles: [Roles.PM, Roles.DM, Roles.DE, Roles.CSM, Roles.CCRA, Roles.SITE_SCANNER, Roles.SITE_SCANNER_RESOLVER] },
    { id: 'ht4', title: 'Monitoring Plan', roles: [Roles.PM, Roles.DM, Roles.DE, Roles.CSM, Roles.CCRA, Roles.SITE_SCANNER, Roles.SITE_SCANNER_RESOLVER] },
    { id: 'ht5', title: 'Monitoring CRF Pages', roles: [Roles.PM, Roles.DM, Roles.DE, Roles.CSM, Roles.CCRA, Roles.SITE_SCANNER, Roles.SITE_SCANNER_RESOLVER] },
    { id: 'ht6', title: 'Risk Parameters', roles: [Roles.PM, Roles.DM, Roles.DE, Roles.CSM, Roles.CCRA, Roles.SITE_SCANNER, Roles.SITE_SCANNER_RESOLVER] },
    { id: 'ht7', title: 'Monitoring Reports', roles: [Roles.PM, Roles.DM, Roles.DE, Roles.CSM, Roles.CCRA] },
]

class SiteStudyDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            singleSelect: null,
            activeTab: "ht1",
            riskAssessment: undefined,
            createPatient: false,
            page: 1,
            reportsPage: 1,
            itemsPerPage: 5,
            startIndex: 0,
            endIndex: 5,
            currentPage: 0,
            processDocsFilter: { value: 'All', label: 'All' }
        };
        this.fileDownload = React.createRef()
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.visitsInfo) {
                this.loadMonitoringPlan()
            }
        }
    }

    async loadMonitoringPlan() {
        const visitsInfo = this.props.visitsInfo
        var visits = []
        var tempDomains = []
        var monitorTemplatedata = []
        var riskAssessment = visitsInfo.length > 0 ? visitsInfo[0].riskAssessment : undefined;
        var totalCRFPages = visitsInfo.length > 0 ? visitsInfo[0].totalCRFPages : 0;
        var selectedPages = visitsInfo.length > 0 ? visitsInfo[0].visitMonitorCRFPages : []; //[1, 6, 9]
        visitsInfo.forEach(visit => {
            visits.push(visit.name);
            if (visit.visitMonitorTemplate && visit.visitMonitorTemplate !== null) {
                tempDomains.push(...visit.visitMonitorTemplate);
                visit.visitMonitorTemplate.forEach(domain => {
                    return monitorTemplatedata.push(`${visit.name}:${domain}`)
                })
            }
        });

        await this.setState({
            visits: visits,
            totalCRFPages: totalCRFPages,
            updatedTotalCRFPages: totalCRFPages,
            selectedPages: selectedPages,
			updatedSelectedPages : selectedPages,
            domains: _.uniq(tempDomains),
            monitorTemplatedata: monitorTemplatedata,
			riskAssessment : riskAssessment,
            updatedRiskAssessment: riskAssessment
        })
    }

    handleClick(e, index) {
        e.preventDefault();
        this.setState({
            currentPage: index,
        });
    }

    static getDerivedStateFromProps(props, state) {

        // Typical usage (don't forget to compare props):
        if (props !== state) {
            return {
                patients: props.patients,
            };
        }
        return null;
    }

    componentDidMount() {
        const token = this.props.token;
        const studyId = this.props.match.params.id;
        const siteId = this.props.site._id;

        this.props.getProcessDocsByStudy(token, `clinicalStudy._id=${studyId}`);
        this.props.getSiteVisitDetails(token, siteId);
        this.props.getSiteSubjects(token, siteId);

        if (this.props.role) {

            switch (this.props.role) {
                case Roles.CCRA:
                    this.props.getMonitorReports(token, siteId);
                    break;
                case Roles.SITE_SCANNER_RESOLVER:
                case Roles.CSM:
                case Roles.DM:
                case Roles.PM:
                case Roles.DE:
                    this.props.getMonitorReports(token, siteId);
                    this.props.getCCRADetails(token, siteId);
                    break;
                case Roles.SITE_SCANNER:
                    this.props.getCCRADetails(token, siteId);
                    break;
                default:
                    break;
            }
        }
    }

    onMonitorDataChange = async (selectedId, checked) => {
        var monitorData = _.cloneDeep(this.state.updatedMonitorData);
        
        if (checked) {
            monitorData.push(selectedId);
        } else {
            _.remove(monitorData, val => val === selectedId);
        }
        await this.setState({
            updatedMonitorData: monitorData
        });
    }

    onTotalCRFPagesChange = (e) => {
        this.setState({ updatedTotalCRFPages: parseInt(e.target.value) })
    }

    onTotalCRFPagesBlur = (e) => {
        if (!this.state.updatedTotalCRFPages)
            this.setState({ updatedTotalCRFPages: this.state.totalCRFPages })
    }

    onRiskParametersChange = (e) => {
        this.setState({ updatedRiskAssessment: e })
    }

    onCRFDataChange = async (e) => {
        const selectedId = e.target.id;

        var selectedPages = _.clone(this.state.selectedPages);

        if (this.state.updatedSelectedPages)
            selectedPages = this.state.updatedSelectedPages;

        if (e.target.checked) {
            selectedPages.push(selectedId);
        } else {
            _.remove(selectedPages, val => val === selectedId);
        }
        await this.setState({ updatedSelectedPages: selectedPages })
    }

    alterEditMode = async () => {
        const { editMode } = this.state;
        if (editMode) {
            this.setState({
                alert: {
                    title: 'Confirmation',
                    confirmBtnText: 'Submit',
                    confirmMessage: 'Are you sure you want to submit the changes?',
                    onConfirm: this.onConfirmClick,
					onCancel: this.onCancelEdit
                }
            });
        } else {
            this.setState({ editMode: !editMode })
        }
    }
	
	onCancelEdit = () => {
		const { monitorData, riskAssessment, totalCRFPages, selectedPages, patients} = this.state;
        this.setState({
            editMode: false,
            alert: null,
            updatedMonitorData: monitorData,
            updatedRiskAssessment: riskAssessment,
            updatedSelectedPages: selectedPages,
            updatedTotalCRFPages: totalCRFPages,
			updatedPatients : patients
        });
    }


    onConfirmClick = async () => {
        const { activeTab } = this.state;
        const siteId = this.props.site._id;
        const token = this.props.token;

        switch (activeTab) {
            case 'ht3':
                const patients = this.state.updatedPatients ? this.state.updatedPatients : this.state.patients;
                if (patients) {
                    for (let i = 0; i < patients.length ; i++) {
                        const val = patients[i];
                        try {
                            if (!val.isNew) {
                                await updateSiteSubject(token, val._id, { monitorSelect: val.monitorSelect });
                            } else {
                                let response = await createSiteSubject(token, val);
								patients[i] = response.data;
								patients[i].isNew = false;
                            }
                        } catch (error) {
                            alert('Update site subject failed!')
                            console.log(error);
                        }
                    };
                    this.setState({ editMode: false, alert: null, patients : patients, updatedPatients : patients })
                }
                
                break;
            case 'ht4':
                const { updatedMonitorData } = this.state;
                const monitorData = {}
                updatedMonitorData.forEach(val => {
                    const d = val.split(':')
                    const key = d[0]
                    const value = d[1]
                    if (monitorData[key]) {
                        monitorData[key].push(value)
                    } else {
                        monitorData[key] = [value]
                    }
                })
                updateMonitoringPlan(token, siteId, monitorData).then(res => {
                    this.setState({ editMode: false, alert: null, monitorData : updatedMonitorData })
                }).catch(e => console.log(e))
                break;
            case 'ht5':
                var { visits, updatedTotalCRFPages, updatedSelectedPages } = this.state;
                var totalCRFPagesObj = {}
                var selectedPagesObj = {}
                visits.forEach(val => {
                    totalCRFPagesObj[val] = `${updatedTotalCRFPages}`;
                    selectedPagesObj[val] = updatedSelectedPages;
                })
                const crfPages = {
                    'totalCRFPages': totalCRFPagesObj,
                    'monitorCRFPages': selectedPagesObj
                }
                updateCRFPages(token, siteId, crfPages).then(res => {
                    this.setState({ editMode: false, alert: null, selectedPages : updatedSelectedPages, totalCRFPages : updatedTotalCRFPages })
                }).catch(e => console.log(e))
                break;
            case 'ht6':
				const { updatedRiskAssessment } = this.state;
                updateSite(token, siteId, { riskAssessment: updatedRiskAssessment }).then(res => {
                    this.setState({ editMode: false, alert: null, riskAssessment : updatedRiskAssessment })
                    this.props.getSiteDetails(token, siteId)
                }).catch(e => console.log(e))
                break;
            default:
                break;
        }
    }

    onStatusChange = (el, state) => {
        const studyId = this.props.match.params.id;
        const token = this.props.token;
        updateStudy(token, studyId, { isActive: state }).then(res => {
            this.props.getStudyDetails(token, studyId);
        }).catch(e => console.log(e))
    }

    onDocumentItemClick = (e, doc, rowIndex) => {
        if (doc.url) {
            this.setState({
                alert: {
                    title: 'Confirmation',
                    confirmBtnText: 'Download',
                    confirmMessage: 'Are you sure you want to download the document ?',
                    onConfirm: async () => await this.onDownloadConfirmClick(doc)
                }
            });
        }
    }

    onDownloadConfirmClick = async (doc) => {
        // close pop up before downloading the file.
        // sync file saver is synchronous, state change before 
        // file is downloaded is cancelling the download action
        //
        await this.setState({alert : null});
            
        if (doc) {
            const filename = `${doc.name}.${doc.url.split('.').pop()}`;
            let fileBlob = await getFileFromUrl(doc.url, this.props.token, true);
            FileSaver.saveAs(fileBlob, filename);
        }
    }

    onMarkPatientsUpdate = (e) => {
        var patients = _.cloneDeep(this.state.updatedPatients ? this.state.updatedPatients : this.state.patients);
        let patient = patients.filter(val => val._id === e.target.id)[0];
        patient.monitorSelect = e.target.checked;
        this.setState({ updatedPatients: patients });
    }

    onAddSubjectTrigger = () => {
        this.setState({ createPatient: !this.state.createPatient })
    }

    onAddSubject = async (event, values) => {
        const siteId = this.props.site._id;
        let subject = values;
        subject['studySite'] = siteId;
        subject['monitorSelect'] = values.monitorSelect === "true";
        subject['icfVerified'] = false;
        subject['isNew'] = true;
        
        let patients = _.cloneDeep(this.state.updatedPatients ? this.state.updatedPatients : this.state.patients);
        patients.push(subject);
        
        await this.setState({ updatedPatients : patients, createPatient : false});
    }

    handleProcessDocTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        this.setState({ page })

        const token = this.props.token;
        const studyId = this.props.match.params.id;
        const filter = this.state.processDocsFilter;
        var params = [`clinicalStudy._id=${studyId}`]
        if (filter.value !== 'All') {
            params.push(`docLevel=${filter.value}`)
        }

        this.props.getProcessDocsByStudy(token, _.join(params, '&'), currentIndex);
    }

    onProcessDocsFilterChange = (filter) => {
        this.setState(filter)
        const token = this.props.token;
        const studyId = this.props.match.params.id;

        var params = [`clinicalStudy._id=${studyId}`]
        if (filter.value !== 'All') {
            params.push(`docLevel=${filter.value}`)
        }

        this.props.getProcessDocsByStudy(token, _.join(params, '&'));
    }

    onTabChangeClick = (tabId) => {
        this.setState({
            alert: {
                title: '',
                onConfirm: this.onTabChangeConfirmClick,
                confirmBtnText: 'Ok',
                confirmMessage: 'Do you want to discard the changes and proceed?'
            },
            nextTabId: tabId,
        })
    }

    onTabChangeConfirmClick = async () => {
		const { monitorData, riskAssessment, totalCRFPages, selectedPages, patients} = this.state;
        await this.setState({
            activeTab: this.state.nextTabId,
            nextTabId: undefined,
            editMode: false,
            alert: false,
            createPatient: false,
            updatedPatients: patients,
            updatedMonitorData: monitorData,
            updatedRiskAssessment: riskAssessment,
            updatedSelectedPages: selectedPages,
            updatedTotalCRFPages: totalCRFPages,
        })
    }

    handleReportsTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        this.setState({ page })

        this.props.getMonitorReports(this.props.token, this.props.site._id, currentIndex, PAGE_SIZE)
    }

    render() {

        const { study, processDocs, site, role, sponsorer, ccra, processDocsCount } = this.props
        const { editMode, alert, visits, domains, monitorTemplatedata, updatedMonitorData, selectedPages, updatedSelectedPages, createPatient, patients, updatedRiskAssessment, page, processDocsFilter } = this.state;
		
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Study Protocol</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">

                    {
                        alert &&
                        <SweetAlert
                            show={true}
                            title={alert.title}
                            style={{ minHeight: '200px' }}
                            closeOnClickOutside={false}
                            allowEscape={false}
                            onConfirm={alert.onConfirm}
                            onCancel={alert.onCancel ? alert.onCancel : () => this.setState({ alert: null })}
                            confirmBtnBsStyle="primary btn-round btn-sm"
                            cancelBtnBsStyle="outline-primary btn-round btn-sm"
                            confirmBtnText={alert.confirmBtnText}
                            cancelBtnText="Cancel"
                            showCancel>
                            {alert.confirmMessage}
                        </SweetAlert>
                    }
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '755px' }}>
                                <CardHeader>
                                    <CardTitle tag="h4">Study Information</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Study Name</Label>
                                                <p>{study ? study.name : ''}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Protocol</Label>
                                                <p>{study ? study.protocolId : ''}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>No. of Trial Participants</Label>
                                                <p>{patients ? patients.length : ''}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Site Name</Label>
                                                <p>{site ? site.name : ''}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Contact Person</Label>
                                                <p>{site ? site.contactPerson : ''}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Investigator</Label>
                                                <p>{site ? site.investigator : ''}</p>
                                            </FormGroup>

                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Contact Number</Label>
                                                <p>{site ? site.contactNumber : ''}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Regulatory Authority</Label>
                                                <p>{study ? study.regulatoryAuthority : ''}</p>
                                            </FormGroup>
											<FormGroup>
                                                <Label>CRF Type</Label>
                                                <p>{site ? site.crfType : ''}</p>
                                            </FormGroup>
                                        </Col>
										<Col sm={3}>
											<FormGroup>
                                                <Label>Address</Label>
                                                <p>{site ? site.address : ''}</p>
                                            </FormGroup>
										</Col>
                                    </Row>
                                    {
                                        role && role !== Roles.CCRA &&
                                        <>
                                            <Row style={{ marginTop: '15px' }}>
                                                <span style={{ fontWeight: 500, padding: '15px' }}>CCRA Details</span>
                                            </Row>
                                            <Row>
                                                <Col sm={3}>
                                                    <Row>
                                                        <div className="fileinput" style={{ marginLeft: '15px' }}>
                                                            <div className={"thumbnail img-circle"} >
                                                                <RenderImage src={ccra && ccra.staffProfile.profilePicture ? ccra.staffProfile.profilePicture : defaultAvatar} alt="." style={{ width: '72px', height: '72px' }} />
                                                            </div>
                                                        </div>
                                                        <FormGroup style={{ marginLeft: '20px', marginTop: '15px' }}>
                                                            <Label>Name</Label>
                                                            <p>{ccra && ccra.staffProfile ? `${ccra.staffProfile.firstName} ${ccra.staffProfile.lastName}` : ''}</p>
                                                        </FormGroup>
                                                    </Row>
                                                </Col>
                                                <Col sm={3} style={{ marginTop: '15px' }}>
                                                    <FormGroup>
                                                        <Label>Email</Label>
                                                        <p>{ccra && ccra.email ? `${ccra.email}` : ''}</p>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3} style={{ marginTop: '15px' }}>
                                                    <FormGroup>
                                                        <Label>Mobile Number</Label>
                                                        <p>{ccra && ccra.staffProfile ? `${ccra.staffProfile.mobileNo}` : ''}</p>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    <Row >
                                        <Col sm={12}>
                                            <br />
                                            <Nav pills className="nav-pills-primary" 
                                                style={{ fontSize: '7px', padding: '10px', overflowX:'auto', overflowY: 'hidden', flexWrap : 'nowrap'}}>
                                                {
                                                    tabs.map((val, index) => {
                                                        if (this.props.role && val.roles.includes(this.props.role)) {
															if (site.crfType === 'ELECTRONIC' && val.id === 'ht5') return null;
                                                            return (
                                                                <NavItem key={index}>
                                                                    <NavLink
                                                                        className={this.state.activeTab === `${val.id}` ? "active" : ""}
                                                                        onClick={() => {
                                                                            if (this.state.editMode) {
                                                                                this.onTabChangeClick(val.id)
                                                                            } else {
                                                                                this.setState({ activeTab: `${val.id}` })
                                                                            }
                                                                        }}
                                                                    >
                                                                        {val.title}
                                                                    </NavLink>
                                                                </NavItem>
                                                            )
                                                        } else {
                                                            return null;
                                                        }
                                                    })
                                                }
                                            </Nav>
                                            <TabContent
                                                activeTab={this.state.activeTab} style={{ border: '2px' }}
                                                className="tab-space"
                                            >
                                                <TabPane tabId="ht1">
                                                    <SponsorInfo data={sponsorer} />
                                                </TabPane>
                                                <TabPane tabId="ht2">
                                                    {
                                                        role && [Roles.CCRA, Roles.SITE_SCANNER_RESOLVER].includes(role) &&
                                                        <Row>
                                                            <Col sm={12}>
                                                                <div>
                                                                    <h6 className="float-left mt-3">Documents Uploaded</h6>
                                                                    <Button className="btn-round float-right" color="primary" href={`${Routes.STUDIES}/${this.props.match.params.id}/uploaddocument`} size="sm">
                                                                        Upload Document
                                                                    </Button>
                                                                    <div className="float-right mt-2 mr-2" style={{ lineHeight: 1, width: '160px' }} >
                                                                        <Select
                                                                            className="react-select"
                                                                            classNamePrefix="react-select"
                                                                            placeholder="Filter"
                                                                            defaultValue={processDocsFilter}
                                                                            options={[{ value: 'All', label: 'All' }, { value: 'CLINICAL_STUDY', label: 'Study Specific' }, { value: 'STUDY_SITE', label: 'Site Specific' }]}
                                                                            onChange={(value) => this.onProcessDocsFilterChange(value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Documents
                                                        id={this.props.match.params.id}
                                                        documents={processDocs}
                                                        page={page}
                                                        totalSize={processDocsCount ? processDocsCount : 0}
                                                        handleProcessDocTableChange={this.handleProcessDocTableChange}
                                                        onDocumentItemClick={this.onDocumentItemClick} />
                                                </TabPane>
                                                <TabPane tabId="ht3">
                                                    {
                                                        role && role === Roles.CCRA &&
                                                        <Row>
                                                            <Col sm={12}>
                                                                <div>
                                                                    <h6 className="float-left mt-3">Trial Participants To Be Verified</h6>
                                                                    <Button className="btn-round float-right" color="primary" onClick={this.alterEditMode} size="sm">
                                                                        {editMode ? 'Submit' : 'Change'}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <MarkPatients
                                                        patients={this.state.updatedPatients ? this.state.updatedPatients : patients}
                                                        disabled={!editMode}
                                                        enableCreate={role === Roles.CCRA}
                                                        createPatient={createPatient}
                                                        subjectPrefix={this.props.site ? this.props.site.subjectPrefix : null}
                                                        onMarkPatientsUpdate={this.onMarkPatientsUpdate}
                                                        onAddSubjectTrigger={this.onAddSubjectTrigger}
                                                        onAddSubject={this.onAddSubject}
                                                    />
                                                </TabPane>
                                                <TabPane tabId="ht4">
                                                    {
                                                        role && role === Roles.CCRA &&
                                                        <Row>
                                                            <Col sm={12}>
                                                                <div>
                                                                    <h6 className="float-left mt-3">Monitoring Plan</h6>
                                                                    <Button className="btn-round float-right" color="primary" onClick={this.alterEditMode} size="sm">
                                                                        {editMode ? 'Submit Plan' : 'Change Plan'}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <MonitoringPlan
                                                        visits={visits}
                                                        disabled={!editMode}
                                                        domains={domains}
                                                        data={updatedMonitorData ? updatedMonitorData : monitorTemplatedata}
                                                        onDataChange={this.onMonitorDataChange} />
                                                </TabPane>
												{
													site.crfType === 'PAPER' &&
													<TabPane tabId="ht5">
														{
															role && role === Roles.CCRA &&
															<Row>
																<Col sm={12}>
																	<div>
																		<h6 className="float-left mt-3">CRF Pages</h6>
																		<Button className="btn-round float-right" color="primary" onClick={this.alterEditMode} size="sm">
																			{editMode ? 'Submit' : 'Change'}
																		</Button>
																	</div>
																</Col>
															</Row>
														}
														<CRFPages
															onCRFDataChange={this.onCRFDataChange}
                                                            onTotalCRFPagesChange={this.onTotalCRFPagesChange}
                                                            onTotalCRFPagesBlur={this.onTotalCRFPagesBlur}
                                                            totalCRFPages={this.state.updatedTotalCRFPages}
															selectedPages={updatedSelectedPages ? updatedSelectedPages : selectedPages}
															disabled={!editMode} />
													</TabPane>
												}
                                                <TabPane tabId="ht6">
                                                    {
                                                        role && role === Roles.CCRA &&
                                                        <Row>
                                                            <Col sm={12}>
                                                                <Button color="primary" className="btn-round float-right" size="sm" onClick={this.alterEditMode}>
                                                                    {editMode ? 'Submit' : 'Change'}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <RiskParameters
                                                        disabled={!editMode}
                                                        data={updatedRiskAssessment}
                                                        onChange={this.onRiskParametersChange} />
                                                </TabPane>
                                                {
                                                    role && [Roles.CCRA, Roles.SITE_SCANNER_RESOLVER].includes(role) &&
                                                    <TabPane tabId="ht7">
                                                        {
                                                            role && role === Roles.CCRA &&
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <div>
                                                                        <h6 className="float-left mt-3">Monitoring Reports</h6>
                                                                        <Button className="btn-round float-right" color="primary" href={`${Routes.STUDIES}/${this.props.match.params.id}/uploadreport`} size="sm">
                                                                            Upload Monitoring Report
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        <Row>
                                                            <Col>
                                                                <RemoteTable
                                                                    columns={[
                                                                        {
                                                                            text: 'Report Name',
                                                                            formatter: (cell, val, index) => {
                                                                                return val && val.reportDocument ? val.reportDocument[0].name : 'Report Name'
                                                                            }
                                                                        },
                                                                        {
                                                                            text: 'Description',
                                                                            formatter: (cell, val, index) => {
                                                                                return val && val.reportDocument ? val.reportDocument[0].description : 'Description'
                                                                            }
                                                                        },
                                                                        {
                                                                            text: 'Uploaded On',
                                                                            formatter: (cell, val, index) => {
                                                                                return val && val.updatedAt ? moment(val.updatedAt).format('MM/DD/YYYY') : ''
                                                                            }
                                                                        }
                                                                    ]}
                                                                    page={this.state.reportsPage}
                                                                    totalSize={this.props.reportsCount}
                                                                    sizePerPage={PAGE_SIZE}
                                                                    data={this.props.reports ? this.props.reports : []}
                                                                    onItemClick={(e, val, index) => {
                                                                        if (val && val.reportDocument)
                                                                            this.onDocumentItemClick(null, val.reportDocument[0], index);
                                                                    }}
                                                                    onTableChange={this.handleReportsTableChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                }
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, studies, sites }) => {
    return {
        token: user.token,
        role: user ? user.role.name : null,
        study: studies.current,
        site: sites.current,
        sponsorer: studies.current ? studies.current.sponsorer : {},
        visitsInfo: sites.visits,
        processDocs: studies.processDocs,
        processDocsCount: studies.processDocsCount,
        reports: studies.monitorReports,
        reportsCount: studies.monitorReportsCount,
        patients: sites.subjects,
        ccra: studies.ccra
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getStudyDetails: (token, id) => StudyActions.getStudyDetails(dispatch, token, id),
        getSiteDetails: (token, id) => SiteActions.getSiteDetails(dispatch, token, id),
        getSiteSubjects: (token, id) => SiteActions.getSiteSubjects(dispatch, token, id),
        getSitesByStudy: (token, id) => StudyActions.getSitesByStudy(dispatch, token, id),
        getProcessDocsByStudy: (token, params, dataIndex) => StudyActions.getProcessDocByStudy(dispatch, token, params, dataIndex),
        getMonitorReports: (token, id, start, limit) => StudyActions.getMonitorReports(dispatch, token, id, start, limit),
        getSiteVisitDetails: (token, id) => SiteActions.getSiteVisitDetails(dispatch, token, id),
        getCCRADetails: (token, id) => StudyActions.getCCRADetails(dispatch, token, id)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SiteStudyDetails);
