import React from "react";
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'
import {
    Button,
    Modal, 
    ModalBody,
    ModalFooter
} from "reactstrap";

const redactorTheme = {
    'common.bi.image': '',
    'common.bisize.width': '0px',
    'common.bisize.height': '0px',
    'common.backgroundImage': 'none',
    'common.backgroundColor': '#1e1e1e',
    'common.border': '0px',

    // header
    'header.backgroundImage': 'none',
    'header.backgroundColor': 'transparent',
    'header.border': '0px',
    'header.display' : 'none',

    // load button
    'loadButton.backgroundColor': '#fff',
    'loadButton.border': '1px solid #ddd',
    'loadButton.color': '#222',
    'loadButton.fontFamily': '\'Noto Sans\', sans-serif',
    'loadButton.fontSize': '12px',
    //'loadButton.display' : 'none',

    // download button
    'downloadButton.backgroundColor': '#fdba3b',
    'downloadButton.border': '1px solid #fdba3b',
    'downloadButton.color': '#fff',
    'downloadButton.fontFamily': '\'Noto Sans\', sans-serif',
    'downloadButton.fontSize': '12px',
    //'downloadButton.display' : 'none',

    // main icons
    'menu.normalIcon.color': '#8a8a8a',
    'menu.activeIcon.color': '#555555',
    'menu.disabledIcon.color': '#434343',
    'menu.hoverIcon.color': '#e9e9e9',

    // submenu icons
    'submenu.normalIcon.color': '#8a8a8a',
    'submenu.activeIcon.color': '#e9e9e9',

    'menu.iconSize.width': '24px',
    'menu.iconSize.height': '24px',

    'submenu.iconSize.width': '32px',
    'submenu.iconSize.height': '32px',

    // submenu primary color
    'submenu.backgroundColor': '#1e1e1e',
    'submenu.partition.color': '#3c3c3c',

    // submenu labels
    'submenu.normalLabel.color': '#8a8a8a',
    'submenu.normalLabel.fontWeight': 'lighter',
    'submenu.activeLabel.color': '#fff',
    'submenu.activeLabel.fontWeight': 'lighter',

    // checkbox style
    'checkbox.border': '0px',
    'checkbox.backgroundColor': '#fff',

    // range style
    'range.pointer.color': '#fff',
    'range.bar.color': '#666',
    'range.subbar.color': '#d1d1d1',

    'range.disabledPointer.color': '#414141',
    'range.disabledBar.color': '#282828',
    'range.disabledSubbar.color': '#414141',

    'range.value.color': '#fff',
    'range.value.fontWeight': 'lighter',
    'range.value.fontSize': '11px',
    'range.value.border': '1px solid #353535',
    'range.value.backgroundColor': '#151515',
    'range.title.color': '#fff',
    'range.title.fontWeight': 'lighter',

    // colorpicker style
    'colorpicker.button.border': '1px solid #1e1e1e',
    'colorpicker.title.color': '#fff'
};

export default function BasicImageRedactor (props) {

    let editorRef = React.useRef();
    
    const saveDocument = async () => {
        const editorInstance = editorRef.current.getInstance();
        let image = await editorInstance.toDataURL();
        await props.saveRedactedDocument(image);
    }
    
    return (
        <Modal 
            isOpen = {props.editMode} 
            size="xl" 
            centered 
            backdrop="static"
            keyboard={false}
        >
            <ModalBody>
                <ImageEditor 
                  ref={editorRef}
                  includeUI={{
                      loadImage: {
                        path: props.url,
                        name: "redactImage",
                      },
                      theme : redactorTheme,
                      menu: ["crop", "flip", "rotate", "shape",],
                      initMenu: "",
                      uiSize: {
                        height: '700px',
                        width : '1000px'
                      },
                      menuBarPosition: "bottom",
                    }}
                    cssMaxHeight={680}
                    cssMaxWidth={980}
                    selectionStyle={{
                      cornerSize: 20,
                      rotatingPointOffset: 70,
                    }}
                    usageStatistics={false}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" className="btn-round btn-primary btn-sm "
                    onClick={saveDocument}
                > Save Document </Button>{' '}
                <Button color="primary" className="btn-round btn-outline-primary btn-sm"
                    onClick={props.closeRedactWindow}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}