import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
   Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Badge,
    Breadcrumb,
    BreadcrumbItem
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin"
import { SiteActions } from "actions"
import { Routes } from 'constants/index.js';
import { PAGE_SIZE } from "api/list";
import { RemoteTable } from "components";

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            page: 1
        };
    }

    componentDidMount() {
        this.props.getSites(this.props.token, '', 0, PAGE_SIZE, true);
    }

    onItemClick = (e, row, index) => {
        this.props.history.push(`${Routes.SITES}/${row._id}`)
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        this.setState({ page })

        this.props.getSites(this.props.token, '', currentIndex, PAGE_SIZE, true)
    }

    onSearchChange = (e) => {
        const searchValue = e.target.value;
        if (searchValue.trim().length > 0) {
            this.props.getSites(this.props.token, `name=${searchValue}`, 0, PAGE_SIZE, true)
        } else {
            this.props.getSites(this.props.token, '', 0, PAGE_SIZE, true)
        }
    }

    render() {
        const { page } = this.state;
        var { sites } = this.props
        sites = sites ? sites : []
        return (
            <DashboardLayout onSearchChange={this.onSearchChange} searchFocus={true}>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={Routes.DASHBOARD}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Search Details</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {sites.length > 0 ?
                        <Row>
                            <Col md="12" className="ml-auto">
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col xs={3} md={4} lg={6} >
                                                <CardTitle tag="h4">
                                                    Sites
                                            </CardTitle>
                                            </Col>
                                            <Col xs={5} md={5} lg={4}>

                                            </Col>
                                            <Col xs={4} md={3} lg={2} >

                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>

                                        <RemoteTable
                                            data={sites}
                                            page={page}
                                            totalSize={this.props.totalSize}
                                            sizePerPage={PAGE_SIZE}
                                            onItemClick={this.onItemClick}
                                            columns={
                                                [
                                                    {
                                                        dataField: 'id',
                                                        text: 'S. No',
                                                        formatter: (cell, row, index) => {
                                                            return `${(index + ((this.state.page - 1) * PAGE_SIZE)) + 1}`
                                                        }
                                                    },
                                                    {
                                                        dataField: 'name',
                                                        text: 'Site\'s Name',
                                                    },
                                                    {
                                                        dataField: 'numberOfStudies',
                                                        text: 'No. of Studies',
                                                    },
                                                    {
                                                        dataField: 'contactEmail',
                                                        text: 'Contact Email',
                                                    },
                                                    {
                                                        dataField: 'isActive',
                                                        text: 'Status',
                                                        formatter: (cell, row) => {
                                                            if (row.isActive)
                                                                return <Badge className="active-badge">Active</Badge>
                                                            else
                                                                return <Badge className="inactive-badge">InActive</Badge>
                                                        }
                                                    }
                                                ]
                                            }
                                            onTableChange={this.handleTableChange}
                                        />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col>
                                <Card style={{ minHeight: '600px' }}>
                                    <CardBody>
                                        <Row>
                                            <Col lg={4} className="mx-auto text-center" style={{ marginTop: '160px' }}>
                                                <img src={require('../../assets/img/no_result.svg')} alt={'No result found'} />
                                                <h5>Sorry, no results found</h5>
                                                <p>What you searched for is unfortunately not found or doesn’t exist. Try adjusting your search to find what you are looking for.</p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, sites }) => {
    return {
        token: user.token,
        sites: sites.data,
        totalSize: sites.count
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSites: (token, params, start, limit, count) => SiteActions.getSites(dispatch, token, params, start, limit, count),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Search);