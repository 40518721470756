import React from 'react';
import { Roles, QueryTypes, DocTypes } from 'constants/index';
import { selectFilter } from 'react-bootstrap-table2-filter';
import {
    Badge,
} from "reactstrap";

export default (role, state) => {
    console.log(state.queriesRaisedBy);
    switch (role) {
        case Roles.SITE_SCANNER:
        case Roles.SITE_SCANNER_RESOLVER:
        case Roles.CCRA:
        case Roles.PM:
        case Roles.DE:
            return [
                {
                    dataField: 'monitorDocument.docType',
                    text: 'Document Type',
                    filter: selectFilter({
                        options: DocTypes
                    })
                },
                {
                    dataField: 'subject.uniqueCode',
                    text: 'Trial Participant ID',
                    filter: selectFilter({
                        options: state && state.subjects ? state.subjects : []
                    })
                },
                {
                    dataField: 'queryType',
                    text: 'Query Type',
                    filter: selectFilter({
                        options: QueryTypes
                    })
                },
                {
                    dataField: 'monitorDocument.domainData',
                    text: 'Domain',
                    filter: selectFilter({
                        options: state && state.domainData ? state.domainData : []
                    })
                },
                {
                    dataField: 'visit.name',
                    text: 'Visit ID',
                    filter: selectFilter({
                        options: state && state.visits ? state.visits : []
                    })
                },
                {
                    dataField: 'owner._id',
                    text: 'Raised By',
                    formatter: (cell, row) => {
                        return `${row.owner.firstName} ${row.owner.lastName}`
                    },
                    filter: selectFilter({
                        options: state && state.queriesRaisedBy ? state.queriesRaisedBy : []
                    })

                },
                {
                    dataField: 'queryStatus',
                    text: 'Status',
                    filter: selectFilter({
                        options: { OPENED: 'Open', CLOSED: 'Closed', RESOLVED: 'Resolved', RE_OPENED: 'Re Open' }
                    }),
                    formatter: (cell, row) => {
                        // OPENED / CLOSED / RESOLVED / REOPENED
                        switch (row.queryStatus) {
                            case 'OPENED':
                                return <Badge className="verified-badge">Open</Badge>
                            case 'RE_OPENED':
                                return <Badge className="verified-badge">Re Opened</Badge>
                            case 'CLOSED':
                                return <Badge className="closed-badge">Closed</Badge>
                            case 'RESOLVED':
                                return <Badge className="submitted-badge">Resolved</Badge>
                            default:
                                return <Badge className="verified-badge">{row.queryStatus}</Badge>
                        }
                    }
                }]
        
        case Roles.DM:
        case Roles.CSM:
            return [
                {
                    dataField: 'subject.uniqueCode',
                    text: 'Trial Participant ID',
                    filter: selectFilter({
                        options: state && state.subjects ? state.subjects : []
                    })
                },
                {
                    dataField: 'queryType',
                    text: 'Query Type',
                    filter: selectFilter({
                        options: QueryTypes
                    })
                },
                {
                    dataField: 'queryStatus',
                    text: 'Status',
                    filter: selectFilter({
                        options: { OPENED: 'Open', CLOSED: 'Closed', RESOLVED: 'Resolved', RE_OPENED: 'Re Open' }
                    }),
                    formatter: (cell, row) => {
                        // OPENED / CLOSED / RESOLVED / REOPENED
                        switch (row.queryStatus) {
                            case 'OPENED':
                                return <Badge className="verified-badge">Open</Badge>
                            case 'RE_OPENED':
                                return <Badge className="verified-badge">Re Opened</Badge>
                            case 'CLOSED':
                                return <Badge className="closed-badge">Closed</Badge>
                            case 'RESOLVED':
                                return <Badge className="submitted-badge">Resolved</Badge>
                            default:
                                return <Badge className="verified-badge">{row.queryStatus}</Badge>
                        }
                    }
                }]
        default:
            return [];

    };
};
