import React from "react";
import { connect } from 'react-redux';

import {
    Button,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Label,
    Col,
    Breadcrumb,
    BreadcrumbItem,
    Input,
} from 'reactstrap';
import { AvForm, AvField, AvGroup, AvFeedback, AvInput } from 'availity-reactstrap-validation';
//core components
import { PanelHeader } from "components";
import DashboardLayout from "layouts/Admin"
import { Routes, Roles, DEFAULT_PASSWORD, Validations} from 'constants/index.js';
import { SiteActions, StaffActions, StudyActions } from 'actions'
import { createSiteStaffPivot, createStaff } from "api";
import cogoToast from "cogo-toast";
import Select from "react-select";
class AddStudyUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            createType: 'select',
            isUploading: false
        }
        this.form = null;
    }

    componentDidMount() {
        const token = this.props.token;
        const studyId = this.props.match.params.id;
        this.props.getStudyDetails(token, studyId);
    }

    onRoleSelection = (role) => {
        this.setState({ role })
        this.props.getStaff(this.props.token, `user.role.name=${role}`, 0, -1)
    }

    handleValidSubmit = async (event, values) => {
        this.setState({ isUploading: true })
        const study = this.props.match.params.id;
        const createType = this.state.createType;
        const token = this.props.token;
        var userProfile = null;
        try {
            if (createType === 'select') {
                userProfile = this.props.staff.filter(val => val._id === values.user)[0]
                const role = userProfile.user.role;
                const studyStaffPivot = { 
                    accessLevel: 'CLINICAL_STUDY', 
                    staffProfile: userProfile._id, 
                    clinicalStudy: study, 
                    dataEntryRole: values.dataEntryRole ? values.dataEntryRole : 'DE1', 
                    role: role,
                }
                await createSiteStaffPivot(token, studyStaffPivot);
            } else {
                var staff = { 
                    firstName: values.firstName, 
                    lastName: values.lastName, 
                    mobileNo: values.mobileNo, 
                    email: values.email, 
                    role: values.role, 
                    staffType: 'EMPLOYEE', 
                    password: DEFAULT_PASSWORD 
                }

                var staffResponse = await createStaff(token, staff);
                const studyStaffPivot = { 
                    accessLevel: 'CLINICAL_STUDY', 
                    staffProfile: staffResponse.data._id, 
                    clinicalStudy: study, 
                    dataEntryRole: values.dataEntryRole ? values.dataEntryRole : 'DE1', 
                    role: values.role 
                };
                
                await createSiteStaffPivot(token, studyStaffPivot);
                cogoToast.success('Study User added successfully.', {position:"top-right"});
            }

            this.props.history.push(`${Routes.STUDIES}/${this.props.match.params.id}`);
        } catch (error) {
            console.log(this.isUploading,"isAddddddddd")
            if (error.response && error.response.status === 400) {
                if (error.response.data.message.email)
                    alert(error.response.data.message.email.message);
                if (error.response.data.message.staffProfile)
                    alert(error.response.data.message.staffProfile.message);
            } else {
                console.log(error);
            }
        }
    }

    render() {
        const { createType,isUploading } = this.state;
        const { staff } = this.props;
        return (
            <>
                <DashboardLayout>
                    <PanelHeader size="sm" >
                        <Col>
                            <Breadcrumb tag="nav" listTag="div">
                                <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                                <BreadcrumbItem tag="a" href={`${Routes.STUDIES}`}>Studies</BreadcrumbItem>
                                <BreadcrumbItem tag="a" href={`${Routes.STUDIES}/${this.props.match.params.id}`}>Study Details</BreadcrumbItem>
                                <BreadcrumbItem active tag="span">Add User</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </PanelHeader>
                    <div className="content">
                        <Row>
                            <Col md="12">
                                <Card style={{ minHeight: '755px' }}>
                                    <CardHeader>
                                        <CardTitle tag="h4">Add New User</CardTitle>
                                        <p>Select user from the list or create a new user.</p>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                                            <Row style={{ marginBottom: '20px' }}>
                                                <Col sm={3}>
                                                    <AvGroup check className="form-check-radio" inline>
                                                        <Label check style={{ paddingLeft: '12px' }}>
                                                            <Input
                                                                name="createType"
                                                                type="radio"
                                                                checked={createType === 'select'}
                                                                onChange={(value) => {
                                                                    this.setState({ createType: 'select' })
                                                                }}
                                                            />
                                                            <span className="form-check-sign" />
                                                            Select User
                                                        </Label>
                                                        <Label check style={{ paddingLeft: '12px', marginLeft: '20px' }}>
                                                            <Input
                                                                name="createType"
                                                                type="radio"
                                                                checked={createType === 'create'}
                                                                onChange={(value) => {
                                                                    this.setState({ createType: 'create' })
                                                                }}
                                                            />
                                                            <span className="form-check-sign" />
                                                    Create New User
                                                </Label>
                                                    </AvGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {
                                                    (createType === 'select') ?
                                                        <>
                                                            <Col sm={3}>
                                                                <AvField className="minimal" type="select" name="role" label="Select Role" required errorMessage='Please select a role.'
                                                                    onChange={(e) => this.onRoleSelection(e.target.value)}
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value={Roles.CSM}>{Roles.CSM}</option>
                                                                    <option value={Roles.DM}>{Roles.DM}</option>
                                                                    <option value={Roles.DE}>{Roles.DE}</option>
                                                                </AvField>
                                                                {
                                                                    (this.state.role === Roles.DE) &&
                                                                    <AvField className="minimal" type="select" placeholder="DE Type" name="dataEntryRole" label="Data Entry Role" errorMessage='Please select a data entry role.'
                                                                        required={true} >
                                                                        <option value="">Select</option>
                                                                        <option value={'DE1'}>{'DE1'}</option>
                                                                        <option value={'DE2'}>{'DE2'}</option>
                                                                    </AvField>
                                                                }
                                                                {/* <AvField className="minimal" type="select" placeholder="User" name="user" label="Select User" errorMessage='Please select a user.'
                                                                    required={true} >
                                                                    <option value="">Select</option>
                                                                    {
                                                                        staff && staff.map((val, i) => {
                                                                            return <option key={i} value={val._id}>{`${val.firstName} ${val.lastName}`}</option>
                                                                        })
                                                                    }
                                                                </AvField> */}
                                                                <AvGroup>
                                                                    <Label>Select User</Label>
                                                                    <Select
                                                                        className="react-select primary"
                                                                        classNamePrefix="react-select"
                                                                        placeholder="Select User"
                                                                        name="user_select"
                                                                        options={staff ?
                                                                            staff.map((val, index) => {
                                                                                return ({
                                                                                    value: val._id,
                                                                                    label: `${val.firstName} ${val.lastName}`
                                                                                })
                                                                            }) : []}
                                                                        onChange={(value) => { this.setState({user_select: value.value}) }}
                                                                    />
                                                                    <AvInput name="user" value={this.state.user_select} required hidden autoComplete="off" />
                                                                    <AvFeedback>Please select a User.</AvFeedback>
                                                                </AvGroup>
                                                                <AvField className="minimal" type="select" name="selectStudy" label="Select Study" errorMessage='Please select a study.'
                                                                    defaultValue={this.props.study && this.props.study._id}
                                                                    disabled
                                                                    required={true}  >
                                                                    <option value="">Select</option>
                                                                    <option value={this.props.study && this.props.study._id}>{this.props.study && this.props.study.name}</option>
                                                                </AvField>
                                                            </Col>
                                                        </> : null
                                                }
                                                {
                                                    (createType === 'create') ?
                                                        <>
                                                            <Col sm={3}>
                                                                <AvField name="firstName" autoComplete="off" placeholder="First Name" label="First Name" type="text" validate={{
                                                                    required: { value: true, errorMessage: 'Please enter a first name.' },
                                                                    pattern: Validations.ALPHABETS_WITH_SPACES,
                                                                    minLength: { value: 4, errorMessage: 'Your first name must be at least 4 characters' },
                                                                    maxLength: { value: 50, errorMessage: 'Your first name must not be more than 50 characters.' },
                                                                }} />
                                                                <AvField name="mobileNo" autoComplete="off" placeholder="+447911123456" label="Contact Number" type="text" validate={{
                                                                    required: { value: true, errorMessage: 'Please enter a contact number.' },
                                                                    pattern: Validations.TELEPHONE,
                                                                }} />
                                                                    
                                                                <AvField className="minimal" type="select" name="role" label="Select Role" required errorMessage='Please select a role.' onChange={(e) => this.setState({ role: e.target.value })}>
                                                                    <option value="">Select</option>
                                                                    <option value={Roles.CSM}>{Roles.CSM}</option>
                                                                    <option value={Roles.DM}>{Roles.DM}</option>
                                                                    <option value={Roles.DE}>{Roles.DE}</option>
                                                                </AvField>
                                                                {
                                                                    (this.state.role === Roles.DE) &&
                                                                    <AvField className="minimal" type="select" placeholder="DE Type" name="dataEntryRole" label="Data Entry Role" errorMessage='Please select a data entry role.'
                                                                        required={true} >
                                                                        <option value="">Select</option>
                                                                        <option value={'DE1'}>{'DE1'}</option>
                                                                        <option value={'DE2'}>{'DE2'}</option>
                                                                    </AvField>
                                                                }
                                                            </Col>
                                                            <Col sm={3}>
                                                                <AvField name="lastName" autoComplete="off" placeholder="Last Name" label="Last Name" type="text" validate={{
                                                                    required: { value: true, errorMessage: 'Please enter a last name.' },
                                                                    pattern: Validations.ALPHABETS_WITH_SPACES,
                                                                    minLength: { value: 4, errorMessage: 'Your last name must be at least 4 characters' },
                                                                    maxLength: { value: 50, errorMessage: 'Your last name must not be more than 50 characters.' }
                                                                }
                                                                } />
                                                                <AvField name="email" autoComplete="off" placeholder="someone@mail.com" required label="Email ID" type="email" validate={{
                                                                    email: { value: true, errorMessage: 'Please enter a valid email address.' }
                                                                }} />
                                                                <AvField className="minimal" type="select" name="selectStudy" label="Select Study" errorMessage='Please select a study.'
                                                                    defaultValue={this.props.study && this.props.study._id}
                                                                    disabled
                                                                    required={true}  >
                                                                    <option value="">Select</option>
                                                                    <option value={this.props.study && this.props.study._id}>{this.props.study && this.props.study.name}</option>
                                                                </AvField>
                                                            </Col>
                                                        </>
                                                        : null
                                                }
                                            </Row>
                                            <Button color="primary" className="btn-round" type="submit" size="sm" disabled={isUploading} >{isUploading ? 'Please wait..' : 'Add User'}</Button>
                                            <Button color="primary" type="button" outline className="btn-round" size="sm"  style={{ marginLeft: '20px' }} href={`${Routes.STUDIES}/${this.props.match.params.id}`}>Cancel</Button>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </DashboardLayout>
            </>
        );
    }
}

const mapStateToProps = ({ user, sites, staff, studies }) => {
    return {
        token: user.token,
        studies: sites.studies,
        staff: staff.data,
        study: studies.current
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getStudyDetails: (token, id) => StudyActions.getStudyDetails(dispatch, token, id),
        getStudies: (token, name) => SiteActions.getSiteStudies(dispatch, token, name),
        getStaff: (token, params, start, limit) => StaffActions.getStaff(dispatch, token, params, start, limit)
    };
};
export default (connect(mapStateToProps, mapDispatchToProps)(AddStudyUser));