import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Button,
    Label
} from "reactstrap";
import moment from 'moment';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Routes, Roles, Validations } from 'constants/index.js';
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin"
import { createMonitorReport } from "api";
import { uploadFileToStrapi } from 'commons';

class UploadMonitorReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            study_id: null,
            fileName: '',
            isUploading: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            study_id: this.props.match.params.id
        })
    }

    async onFileChange(event) {
        const file = event.target.files[0];
        this.setState({ file: file, fileName: file.name })
    }

    async handleSubmit(event, values) {

        this.setState({ isUploading: true })
        const { file } = this.state;

        const siteId = this.props.site._id;
        const studyId = this.props.match.params.id;

        const uploadedBy = `${this.props.user.staffProfile.firstName} ${this.props.user.staffProfile.lastName}`

        try {

            const response = await uploadFileToStrapi(this.props.token, file)
            await createMonitorReport(this.props.token, {
                studySite: siteId,
                reportDocument: [
                    {
                        'version': 1,
                        'description': values.description,
                        'name': values.name,
                        'url': response.data[0].url,
                        'uploadDate': moment.utc().valueOf(),
                        'uploadedBy': uploadedBy
                    }
                ]
            });
            this.props.history.push(`${Routes.STUDIES}/${studyId}`);
        } catch (error) {
            alert('Doc creation failed');
            console.log(error);
            this.setState({ isUploading: false })
        }

    }

    render() {
        const { role, study } = this.props;
        const { isUploading } = this.state;
        const studyId = this.props.match.params.id;

        return (
            <>
                <DashboardLayout>
                    <PanelHeader size="sm" >
                        <Col>
                            <Breadcrumb tag="nav" listTag="div">
                                <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                                {
                                    (role === Roles.SYSTEM_ADMIN) ?
                                        <>
                                            <BreadcrumbItem tag="a" href={`${Routes.STUDIES}`}>Studies</BreadcrumbItem>
                                            <BreadcrumbItem tag="a" href={`${Routes.STUDIES}/${studyId}`}>Study Details</BreadcrumbItem>
                                        </>
                                        : null
                                }
                                {
                                    (role === Roles.SITE_SCANNER_RESOLVER || role === Roles.SITE_SCANNER || role === Roles.CCRA) ?
                                        <>
                                            <BreadcrumbItem tag="a" href={`${Routes.STUDIES}/${studyId}`}>Study Protocol</BreadcrumbItem>
                                        </>
                                        : null
                                }
                                <BreadcrumbItem active tag="span">Upload Report</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </PanelHeader>
                    <div className="content">
                        <Row>
                            <Col md="12">
                                <Card style={{ minHeight: '755px' }}>
                                    <CardHeader>
                                        <CardTitle tag="h4">Upload Monitoring Report</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm onValidSubmit={this.handleSubmit}>
                                            <Row>
                                                <Col sm={4}>
                                                    <AvField name="study" autoComplete="off" defaultValue={study ? study.name : ''} label="Study Name" type="text" readOnly />
                                                    <AvField name="name" autoComplete="off" label="Report Name" type="text" validate={{
                                                        required: { value: true, errorMessage: 'Please enter report name.' },
                                                        pattern: Validations.ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                                        minLength: { value: 4, errorMessage: 'Report name must be at least 4 characters' },
                                                        maxLength: { value: 50, errorMessage: 'Report name must not be more than 50 characters.' },
                                                    }} />
                                                    <AvField name="description" autoComplete="off" label="Report Description" type="textarea" validate={{
                                                        required: { value: true, errorMessage: 'Please enter report description.' },
                                                        minLength: { value: 4, errorMessage: 'Your report name must be at least 4 characters' },
                                                        maxLength: { value: 200, errorMessage: 'Your report description must be max 200 characters' }
                                                    }} />
                                                    <AvGroup>
                                                        <i className=" fa fa-arrow-up text-primary" aria-hidden="true" style={{ cursor: 'pointer' }} />
                                                        <Label htmlFor="file" className="text-primary" style={{ marginLeft: '10px', cursor: 'pointer' }}> Upload Report</Label>
                                                        <AvInput type="file" name="file" style={{ cursor: 'pointer' }} onChange={this.onFileChange} validate={{
                                                            required: true,
                                                        }}/>
                                                        <AvFeedback name="file">Please select a report.</AvFeedback>
                                                    </AvGroup>
                                                    <p className="text-primary">{this.state.fileName}</p>
                                                    <Button color="primary" disabled={isUploading} className="btn-round" type="submit" size="sm">{isUploading ? 'Please wait..' : 'Upload'}</Button>
                                                    <Button color="primary" outline className="btn-round" size="sm"
                                                        type="button"
                                                        onClick={() => this.props.history.goBack()}
                                                        style={{ marginLeft: '20px' }}>
                                                        Cancel
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </DashboardLayout>
            </>
        );
    }
}

const mapStateToProps = ({ user, studies, sites }) => {
    return {
        token: user.token,
        user: user,
        study: studies.current,
        site: sites.current,
        role: user && user.role ? user.role.name : ''
    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadMonitorReport);