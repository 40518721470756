import React from 'react';
import { Row, Col,  Table, Button } from 'reactstrap';
import { Checkbox } from 'components/';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import _ from 'lodash';

const MarkPatients = ((props) => {

    const duplicateCheck = (value, ctx) => {
        if (value) {
            const filtered = _.filter(props.patients, pat => {
                return pat.uniqueCode.toLowerCase() === value.toLowerCase()
            })
            return filtered.length > 0 ? 'Duplicate trial participant id.' : true
        }
        return false
    }

    return (
        <>
            <Row>
                <Col sm={6} xs={12}>
                    <Table responsive>
                        <thead className="info-title">
                            <tr>
                                <th>Trial Participants ID</th>
                                <th>SDV?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.enableCreate ?
                                    <>
                                        {
                                            !props.createPatient ?
                                                <tr>
                                                    <td colSpan="2">
                                                        <div style={{ borderStyle: 'dashed' }} className="text-center border-primary">
                                                            <Button className="btn-neutral" disabled={props.disabled} color="primary" style={{ fontWeight: '700', width: '100%', color:'#42b5a9' }} onClick={props.onAddSubjectTrigger}>
                                                                <i className="fas fa-plus" aria-hidden="true" style={{ marginRight: '5px' }}></i>
                                                    Add New Trial Participant
                                                </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            props.createPatient ?
                                                <tr>
                                                    <td colSpan="2">
                                                        <AvForm onValidSubmit={props.onAddSubject}>
                                                            <Row>
                                                                <Col xs={4}>
                                                                    <AvField 
                                                                        name="uniqueCode" 
                                                                        autoComplete="off" 
                                                                        placeholder="Enter Trial Participant ID" type="text"
                                                                        value = {
                                                                            props.subjectPrefix && props.subjectPrefix.length ? 
                                                                                `${props.subjectPrefix}-${props.patients ? props.patients.length + 1 : ''}` : ''
                                                                        }
                                                                        validate={{
                                                                            required: { value: true, errorMessage: 'Please enter trial participant id.' },
                                                                            myRules: duplicateCheck,
                                                                        }}
                                                                        disabled = {props.subjectPrefix && props.subjectPrefix.length}
                                                                    />
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <AvField className="minimal" type="select" placeholder="SDV?" name="monitorSelect" errorMessage='Please select SDV status.'
                                                                        required >
                                                                        <option value="">Select</option>
                                                                        <option value={true}>Yes</option>
                                                                        <option value={false}>No</option>
                                                                    </AvField>
                                                                </Col>
                                                                <Col xs={1}>
                                                                    <Button className="btn-neutral" color="primary" style={{ fontWeight: '700' }} type="submit">Add</Button>
                                                                </Col>
                                                            </Row>
                                                        </AvForm>
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                    </>
                                    : null
                            }

                            {
                                props.patients && props.patients.map((val, index) => {
                                    return (
                                        <tr key={val._id}>
                                            <td>{val.uniqueCode}</td>
                                            <td>
                                                <Checkbox id={val._id} checked={val.monitorSelect} disabled={props.disabled} onCheckedChange={props.onMarkPatientsUpdate} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );

})

export default MarkPatients;