import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import FilePonyfill from '@tanker/file-ponyfill';

import { FILEUPLOAD_URL } from 'api/list';
import { getFileFromUrl } from 'commons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const uploadFileToStrapi = async (token, file) => {

    const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
    };

    var formdata = new FormData();
    formdata.append("files", file);

    return Axios.post(FILEUPLOAD_URL, formdata, { headers });
}

export const deleteFileFromStrapi = async (token, fileId) => {

    const headers = {
        Authorization: `Bearer ${token}`
    };

    return Axios.delete(`${FILEUPLOAD_URL}/files/${fileId}`, { headers });
}

export const getRole = (user) => {
    return user.role.name;
}

export const uploadBlobToStrapi = async (token, blob) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
    };

    let file = dataURLtoFile(blob);
    console.log(file);
    var formdata = new FormData();
    formdata.append("files", file, file.name);

    return Axios.post(FILEUPLOAD_URL, formdata, { headers });
}

// helper function: generate a new file from base64 String
export const dataURLtoFile = (dataurl) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const ext = mimeToFileExt(mime);
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
    }
    return new FilePonyfill([u8arr], `${uuidv4()}.${ext}`, { type: mime })
}

const mimeToFileExt = (mime) => {
    switch (mime) {
        case 'image/png':
            return 'png';
        case 'image/jpg':
            return 'jpg';
        case 'image/jpeg':
            return 'jpeg';
        case 'application/pdf':
            return 'pdf';
        default:
            return null;
    }
}

export const readFileAsDataURL = async (file) => {
    let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });
    return result_base64;
}

export const readContentAsImage = async (blob, fileNumber) => {

    var images = []

    const mimeType = (blob.match(/^data:([^;]+);/) || '')[1];
    if (mimeType) {
        const fileType = mimeType.includes('image') ? 'image' : mimeType.includes('pdf') ? 'pdf' : undefined
        if (fileType === 'pdf') {
            var pdfObj;
            pdfObj = await pdfjs.getDocument(blob).promise
            if (!fileNumber) {
                for (let i = 0; i < pdfObj.numPages; i++) {
                    const page = await getPage(pdfObj, i + 1);
                    images.push(page);
                }
                return images;

            } else {
                if (fileNumber === 0) {
                    fileNumber = 1
                }
                const page = await getPage(pdfObj, fileNumber);
                images.push(page);
                return images;
            }
        }
    }
    images.push(blob);
    return images;
}

const getPage = (pdf, num) => {
    return new Promise((resolve, reject) => {
        pdf.getPage(num).then(page => {
            const scale = "1.5";
            const viewport = page.getViewport({
                scale: scale
            });
            const canvas = document.createElement('canvas');
            const canvasContext = canvas.getContext('2d');
            canvas.height = viewport.height || viewport.viewBox[3]; /* viewport.height is NaN */
            canvas.width = viewport.width || viewport.viewBox[2];  /* viewport.width is also NaN */
            page.render({
                canvasContext, viewport
            }).promise.then((res) => {
                resolve(canvas.toDataURL());
            })
        })
    })
}

export const readContentFromScreenshot = async (eleRef) => {
    try {
        // start capture streaming
        eleRef.current.srcObject = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: false
        });

        // wait for media stream to start
        await timeout(1000);
        //convert screenshot to image
        let image = await getScreenshot(eleRef);
        // stop capture streaming
        await stopCapture(eleRef);
        return image;

    } catch (e) {
        throw e;
    }
}

export const captureWidth = window.screen.width * 0.8;
export const captureHeight = window.screen.height * 0.8;

const timeout = async (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const stopCapture = async (videoElem) => {
    if (videoElem.current.srcObject) {
        let tracks = videoElem.current.srcObject.getTracks();

        tracks.forEach(track => track.stop());
        videoElem.current.srcObject = null;
    }
}

const getScreenshot = async (videoElem) => {

    let canvas = document.createElement("canvas");
    canvas.width = captureWidth;
    canvas.height = captureHeight;

    let ctx = canvas.getContext('2d');
    ctx.mozImageSmoothingEnabled = true;
    ctx.webkitImageSmoothingEnabled = true;
    ctx.msImageSmoothingEnabled = true;
    ctx.imageSmoothingEnabled = true;

    ctx.drawImage(videoElem.current, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL();
}

export const convertUrlToDataUrl = async (url, token, callback) => {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL();
        callback(dataURL);
    };
    img.src = await getFileFromUrl(url, token);
}

export const checkFileSize = (file, maxSize = 2) => {
    console.log(maxSize);
    // checking the file should be max of 2 MB
    if (file && ((file.size / 1024 / 1024) <= maxSize))
        return { valid: true, message: '' };
    return { valid: false, message: `File size cannot be greater than ${maxSize} MB` };
}

export const getBase64Size = (base64) => {
    let padding = base64.length
        ? getBase64Padding(base64)
        : 0;

    const size = ((Math.ceil(base64.length / 4) * 3) - padding) / 1000;
    if ((size / 1024 / 1024) <= 10)
        return { valid: true, message: '' };
    return { valid: false, message: `File size cannot be greater than 10 MB` };
}

const getBase64Padding = (base64) => {
    return endsWith(base64, '==')
        ? 2
        : 1
}

const endsWith = (str, end) => {
    let charsFromEnd = end.length
    let extractedEnd = str.slice(-charsFromEnd)
    return extractedEnd === end
}
