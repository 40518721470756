import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Button,
    Label,
    Row,
    Col,
} from "reactstrap";

import { RenderImage } from 'components';

import { DocumentActions } from "actions/index.js";
import { createQuery } from "api";
import cogoToast from "cogo-toast";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Validations } from "constants/index";

class RaiseSdvQuery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            designation: null,
            queryDetails: "",
            isUploading:false
        };
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.createSdvQuery = this.createSdvQuery.bind(this);
    }

    componentDidMount() {
        this.props.getDocumentDetails(this.props.token, this.props.id);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onCancel() {
        this.props.close();
    }

    async createSdvQuery(event, values) {
        this.setState({ isUploading: true });
        try {
            //IMAGE_QUERY,SDV_QUERY,SOURCE_DATA_QUERY,META_DATA_QUERY
            let monitorDocQueryObj = {
                subject: this.props.crfDocument.subject.id,
                studySite: this.props.crfDocument.studySite.id,
                monitorDocument: this.props.crfDocId,
                linkSourceCRFDocument: this.props.sourceDocument ? this.props.sourceDocument.id : null,
                docVersion: String(this.props.crfDocument.versionedDocument.length),
                queryType: "SDV_QUERY",
                title: '',
                query: values.queryDetails,
                queryStatus: 'OPENED',
                owner: this.props.user.staffProfile._id,
                linkVersion: this.props.sourceDocument ? String(this.props.sourceDocument.versionedDocument.length) : '1',
                cancelRemarks: "",
                isDeleted: false,
            }
            await createQuery(this.props.token, monitorDocQueryObj);
            this.props.close();
            const { hide } = cogoToast.success('SDV data query raised successfully.', {
                onClick: () => {
                    hide();
                },
                position: "top-right",
                hideAfter: 3
            });
        } catch (error) {
            console.log(error);
            cogoToast.error('Failed to create query.')
            this.setState({ isUploading: false });
        }
        
    }

    render() {
        const document = this.props.crfDocument;
        var sourceDocUrl = this.props.sourceDocUrl;
        var docUrl = this.props.docUrl;
        const {isUploading } = this.state;

        return (
            <div className="content">
                <Row>
                    <h5 tag="h5">Raise Query In SDV System</h5>
                    <Col md="12">
                        <AvForm onValidSubmit={this.createSdvQuery}>
                            <Row style={{ marginLeft: '0px' }}>
                                <Col xs={6}>
                                    <Row>
                                        <Col xs={6}>
                                            Source Image
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div>
                                            <div className="text-center ml-auto" style={{ width: '500px', height: '365px' }}>
                                                <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                                                    <RenderImage
                                                        alt="." width={600} height={400}
                                                        src={sourceDocUrl}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                <Col xs={6}>
                                    <Row>
                                        <Col xs={6}>
                                            EDC Image / CRF
                                        </Col>
                                        <Col xs={6} style={{ textAlign: 'center', color: '#42B5A9' }}>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div>
                                            <div className="text-center ml-auto" style={{ width: '500px', height: '365px' }}>
                                                <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                                                    <RenderImage
                                                        alt="." width={600} height={400}
                                                        src={docUrl}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{ marginLeft: '0px', marginTop: '45px' }}>
                                <Col>
                                    <Label>Trial Participant ID</Label>
                                    <p>{document && document.subject ? document.subject.uniqueCode : ''}</p>
                                </Col>
                                <Col>
                                    <Label>Visit ID</Label>
                                    <p>{document && document.visit ? document.visit.name : ''}</p>
                                </Col>
                                <Col>
                                    <Label>Data Domain</Label>
                                    <p><p>{document && document.domainData ? document.domainData : ''}</p></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <AvField name="queryDetails" autoComplete="off" label="Enter Query Details Here" type="textarea" validate={{
                                        required: { value: true, errorMessage: 'Please fill query details.' },
                                        pattern: Validations.MULTI_LINE_ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                                        minLength: { value: 6, errorMessage: 'Query details must be at least 6 characters' },
                                        maxLength: { value: 500, errorMessage: 'Query details must not be more than 500 characters.' },
                                    }} />
                                </Col>
                            </Row>
                            <Row>
                                <Button color="primary ml-3" className="btn-round" size="sm" type="submit" disabled={isUploading}>
                                    {isUploading ? 'Please wait..' : 'Raise SDV Query'}
                                </Button>
                                <Button color="primary ml-3" className="btn-round" size="sm" type="button" onClick={this.props.cancel}>
                                    Cancel
                                            </Button>
                            </Row>
                        </AvForm>
                    </Col>
                </Row>
            </div>

        )
    }
}


const mapStateToProps = ({ user, documents, studies }) => {
    return {
        token: user.token,
        details: documents.details,
        study: studies.myStudy,
        user: user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDocumentDetails: (token, id) => DocumentActions.getDocumentDetails(dispatch, token, id),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RaiseSdvQuery);
