import React from 'react';
import {Modal, ModalHeader, ModalBody, Row, Col} from 'reactstrap';

import { RenderImage } from 'components';

const SourceData = (props) => {

    const [docUrl, setDocUrl] = React.useState(null);
    
    const data = props.query;
    const attachment = data.attachment ? data.attachment : null;
    const latestResponse = (props.responseHistory && props.responseHistory.length > 0) ?
                            props.responseHistory[0] : null;
    const latestDocument =  latestResponse && props.responseDocument ? 
                                props.getDocUrl({versionedDocument : props.responseDocument}, 0) : null;
    return (
            <div className="text-center">
                <Modal
                    isOpen={docUrl !== null}
                    size="lg"
                    centered
                    toggle={() => setDocUrl(null)}
                    backdrop="static"
                    keyboard={false}
                >
                    <ModalHeader toggle={() => setDocUrl(null)}>
                        Document Preview
                    </ModalHeader>
                    <ModalBody>
                    {   
                        docUrl &&
                        <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                           <RenderImage alt="CRF Document" className="mx-auto"  src={docUrl} />
                        </div>
                    }
                    </ModalBody>
                </Modal>
                <Row>
                    <Col sm={6} xs={12} style={{cursor : 'pointer'}} onClick= { e => setDocUrl(attachment)}>
                        <h6>Query Attachment</h6>
                        <RenderImage alt="Query Attachment" className="mx-auto"  src={attachment} />
                    </Col>
                    {
                        latestDocument &&
                        <Col sm={6} xs={12} style={{cursor : 'pointer'}} onClick= { e => setDocUrl(latestDocument)}>
                            <h6>Source Document</h6>
                            <RenderImage alt="Source Document" className="mx-auto"  src={latestDocument} />
                        </Col>
                    }
                </Row>
            </div>
    );
}

export default SourceData;