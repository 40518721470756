import React from 'react';
import { getFileFromUrl } from 'commons';
import secureStorage from '../../secureStorage';

export default class RenderImage extends React.Component {
    state = { image : null};
    token = null;
    
    getToken = () => {
        if (!this.token) {
            let value = secureStorage.getItem('state');
            if (value) {
                this.token = value.user.token;
            }
        }
        return this.token;
    }
    
    async componentDidMount() {
        if (this.props.src && this.props.src.length) {
            let image = await getFileFromUrl(this.props.src, this.getToken(), false);
            await this.setState({image : image});
        }
    }
    
    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.src !== this.props.src) {
            let image = await getFileFromUrl(this.props.src, this.getToken(), false);
            await this.setState({image : image});
        }
    }
    
    render() {

        let newProps = {...this.props};
        delete newProps.src;

        return (this.state.image ? <img src={this.state.image} alt={this.props.alt} {...newProps} /> : <React.Fragment/>);
    }
}
