import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    Breadcrumb,
    BreadcrumbItem,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin"
import { SponsorActions } from "actions"
import { Routes } from 'constants/index.js';
import { PAGE_SIZE } from "api/list";
import { RemoteTable, RenderImage } from "components";

class Sponsors extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sponsors: [],
            page: 1,
        };
        this.addSponsor = this.addSponsor.bind(this);
    }

    componentDidMount() {
        this.props.getSponsors(this.props.token, 0, PAGE_SIZE, true)
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                sponsors: this.props.sponsors
            })
        }
    }

    addSponsor() {
        if (this.props.history) {
            this.props.history.push(`${Routes.SPONSORS}/add`)
        }
    }

    onItemClick = (e, row, index) => {
        this.props.history.push(`${Routes.SPONSORS}/${row._id}`)
    }

    handleTableChange = (type, { page, sizePerPage }) => {

        const currentIndex = (page - 1) * sizePerPage;
        this.setState({ page })

        this.props.getSponsors(this.props.token, currentIndex, sizePerPage, false)

    }

    render() {
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Sponsors</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col md="12" className="ml-auto">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col sm={12}>
                                            <div>
                                                <CardTitle tag="h5" className="float-left">
                                                    Sponsors
                                            </CardTitle>
                                                <Button color="primary" className="btn-round float-right" size="sm" onClick={this.addSponsor}>
                                                    Add New Sponsor
                                            </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <RemoteTable
                                        columns={[
                                            {
                                                dataField: 'id',
                                                text: 'S. No',
                                                formatter: (cell, row, index) => {
                                                    return `${(index + ((this.state.page - 1) * PAGE_SIZE)) + 1}`
                                                }
                                            },
                                            {
                                                dataField: 'name',
                                                text: 'Sponsor\'s Name',
                                            },
                                            {
                                                dataField: 'numberOfStudies',
                                                text: 'No. of Studies',
                                            },
                                            {
                                                dataField: 'logo',
                                                text: 'Logo',
                                                formatter: (cell, row, index) => {
                                                    return row.logo ? <RenderImage
                                                        alt="..." style={{ maxHeight: '36px' }}
                                                        src={row.logo}
                                                    /> : ''
                                                }
                                            }
                                        ]}
                                        page={this.state.page}
                                        totalSize={this.props.totalSize ? this.props.totalSize : 0}
                                        sizePerPage={PAGE_SIZE}
                                        data={this.props.sponsors}
                                        onItemClick={this.onItemClick}
                                        onTableChange={this.handleTableChange}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, sponsors }) => {
    return {
        token: user.token,
        sponsors: sponsors.data,
        totalSize: sponsors.count
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSponsors: (token, start, limit,count=false) => SponsorActions.getSponsors(dispatch, token, start, limit, count)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sponsors);