import React from "react";
import { connect } from 'react-redux';
import * as moment from 'moment'
import _ from 'lodash';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import DashboardLayout from "layouts/Admin";
import { Routes, DATE_TIME_FORMAT } from 'constants/index.js';
import { QueryActions, SiteActions } from "actions/index.js";
import { RenderImage } from "components";
import { updateMonitorDocumentQuery } from "api/index.js";
    import cogoToast from "cogo-toast";
class EditQueryDetails extends React.Component {

    state = {
        documentPopup : false,
        isUploading:false
    }

    componentDidMount() {
        this.props.getQueryDetails(this.props.token, this.props.match.params.id)
        this.props.getQueryHistory(this.props.token, this.props.match.params.id)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.query !== this.props.query) {
            this.props.getSiteSubjects(this.props.token, this.props.query.studySite._id)
            this.props.getSiteVisits(this.props.token, this.props.query.studySite._id)
        }
    }

    onCancel = () => {
        this.props.history.push(`${Routes.QUERIES}/${this.props.match.params.id}`)
    }

    onMonitorQueryUpdate = async (event, values) => {
        this.setState({ isUploading: true });
        try {
            await updateMonitorDocumentQuery(this.props.token, this.props.match.params.id, { query: values.query })
            this.props.getQueryDetails(this.props.token, this.props.match.params.id)
            this.setState({ edit: false });
            this.onCancel();
            const { hide } = cogoToast.success('Query updated successfully.', {
              onClick: () => {
                hide();
              },
              position:"top-right",
              hideAfter:3
            });
        } catch (error) {
            this.setState({ isUploading: false });
            console.log(error);
            alert('update doc failed!')
        }
    }

    render() {

        const { query } = this.props;
        const { docUrl,isUploading } = this.state;
        const document =  (query && query.attachment) ? query.attachment :
                            (query && query.monitorDocument && query.monitorDocument.versionedDocument) ? 
                                _.last(query.monitorDocument.versionedDocument).url : null;
        
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.QUERIES}`}>Queries</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.QUERIES}/${this.props.match.params.id}`}>Query Details</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Edit Query</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Modal
                        isOpen={this.state.documentPopup}
                        size="lg"
                        centered
                        toggle={async () => await this.setState({ documentPopup: false })}
                        backdrop="static"
                        keyboard={false}
                    >
                        <ModalHeader toggle={async () => await this.setState({ documentPopup: false })}>
                            Document Preview
                        </ModalHeader>
                        <ModalBody>
                        {   
                            docUrl &&
                            <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                                <RenderImage alt="." src={docUrl} /> :
                            </div>
                        }
                        </ModalBody>
                    </Modal>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Query Details</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {
                                        query &&
                                        <Row>
                                            <Col lg={4}>
                                                <AvForm onValidSubmit={this.onMonitorQueryUpdate}>
                                                    <AvField name="query" autoComplete="off"
                                                        required
                                                        label="Query Description"
                                                        type="textarea"
                                                        value={query.query}
                                                        errorMessage='Please enter query description.'
                                                        validate={{
															minLength: { value: 6, errorMessage: 'Query details must be at least 6 characters' },
                                                            maxLength: { value: 500, errorMessage: 'Description must not be more than 500 characters.' }
                                                        }}/>
                                    <Button color="primary ml-3" className="btn-round" size="sm" type="submit" disabled = {isUploading} >{isUploading ? 'Please Wait..' : 'Submit'}</Button>
                                                    <Button color="primary ml-3" className="btn-round" size="sm" onClick = {this.onCancel}>Back To Query</Button>
                                                </AvForm>
                                            </Col>
                                            <Col lg={8}>
                                                {   
                                                    document &&
                                                    <div className="query-image" style={{ cursor : 'pointer'}} 
                                                        onClick = { e =>  this.setState({ documentPopup: true, docUrl: `${document}`}) }
                                                    > 
                                                        <RenderImage alt="Query Attachment" src={document} /> :
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        this.props.responseHistory && this.props.responseHistory.length > 0 ?
                                            <Row className="mt-4 mb-4">
                                                <Col>
                                                    <span style={{ fontWeight: 500, fontSize: '18px' }}>Response History</span>
                                                    {
                                                        this.props.responseHistory.map((val, index) => {
                                                            return (
                                                                <Row key={index} height={110} className="mt-4 ml-2 border-bottom">
                                                                    <Col sm={1}>
                                                                        <RenderImage alt="." className="rounded-circle" width={48} height={48} style={{ maxHeight: 48, maxWidth: 48 }} 
                                                                            src={val.ownerStaff.profilePicture ? val.ownerStaff.profilePicture : require('assets/img/default_profile.jpg')} 
                                                                        />
                                                                    </Col>
                                                                    <Col sm={2}>
                                                                        <span style={{ fontSize: '14px', fontWeight: 700 }}>{`${val.ownerStaff.firstName} ${val.ownerStaff.lastName}`}</span><br />
                                                                        <Label style={{ fontSize: '12px', fontWeight: 700, marginBottom: '0px' }}>{moment(val.createdAt).format(DATE_TIME_FORMAT)}</Label>
                                                                        <p style={{ fontSize: '14px', fontWeight: 500 }}>{val.comment}</p>
                                                                    </Col>
                                                                    {
                                                                        val.attachment ?
                                                                            <Col sm={2}>
                                                                                <RenderImage alt="." className="rounded" width={48} height={48} style={{ maxHeight: 48, maxWidth: 48 }} src={val.attachment} onClick={() => {
                                                                                    this.setState({ documentPopup: true, docUrl: val.attachment})
                                                                                }} />
                                                                            </Col>
                                                                            : null
                                                                    }
                                                                </Row>
                                                            );
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                            : null
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout >
        )
    }
}

const mapStateToProps = ({ user, queries, sites, studies }) => {
    return {
        token: user.token,
        query: queries.current,
        study: studies.current,
        site: sites.current,
        subjects: sites.subjects,
        visits: sites.visits,
        responseHistory: queries.history
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQueryDetails: (token, id) => QueryActions.getQueryDetails(dispatch, token, id),
        getQueryHistory: (token, id) => QueryActions.getQueryHistory(dispatch, token, id),
        getSiteSubjects: (token, siteId) => SiteActions.getSiteSubjects(dispatch, token, siteId),
        getSiteVisits: (token, siteId) => SiteActions.getSiteVisitDetails(dispatch, token, siteId)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditQueryDetails);