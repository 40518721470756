import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Input,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
    InputGroupAddon,
    InputGroup
} from "reactstrap";
import { Routes } from 'constants/index.js';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";

export default class UpdateImageRecertify extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.back = this.back.bind(this);
    }
    back() {
        this.props.history.push(`${Routes.DOCUMENTS}/add`)
    }
    render() {
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.DOCUMENTS}`} >Documents</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Document Details</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Rase SDV Query</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h6">Update Image and Re-Certify</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={4}>
                                            <FormGroup>
                                                <Label>Site ID</Label>
                                                <p>C100-101</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Visit ID</Label>
                                                <p>V3</ p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Data Domain</Label>
                                                <p>Randomization</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Document Type</Label>
                                                <p>Medical Review</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <p>Signature Copy</p>
                                                <Row>
                                                    <Input type="checkbox" />
                                                    <Label className="mt-1">Check to accept guidelines</Label>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <p>Name</p>
                                                <Input type="text" placeholder="Anderson" />
                                            </FormGroup>
                                            <FormGroup>
                                                <p>Password</p>
                                                <InputGroup>
                                                    <Input
                                                        type="password"
                                                        placeholder="XXXXXXX"
                                                        style={{ minHeight: '45px' }}
                                                    />
                                                    <InputGroupAddon addonType="prepend">
                                                        <i className="fa fa-eye mt-3 mr-3" aria-hidden="true" />
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </FormGroup>
                                            <Row>
                                                <Button color="primary ml-4" className="btn-round" size="sm" onClick={this.back}>
                                                    Submit
                                                    </Button>
                                                <Button color="primary ml-4" className="btn-round" size="sm" onClick={this.back}>Cancel</Button>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Col>
                                                <img
                                                    alt="..." style={{ maxHeight: '346px' }}
                                                    src={require("assets/img/bg14.jpg")}
                                                />
                                            </Col>
                                            <Row >
                                                <FormGroup >
                                                    <i className="fa fa-undo " aria-hidden="true" style={{ fontSize: '8px', marginLeft: '90px' }} ></i>
                                                    <Label>Rotate</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <i className="fa fa-pen ml-4" aria-hidden="true"></i>
                                                    <Label>Redact</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <i className="fa fa-crop-alt ml-4" />
                                                    <Label>Crop</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <i className="far fa-window-close ml-4"></i>
                                                    <Label>Remove</Label>
                                                </FormGroup>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout >
        )
    }
}
