import Axios from 'axios';
import { Roles } from 'constants/index.js';
import { STUDIES_URL, SITES_URL, PROCESS_DOCS_URL, SITE_STAFF_PIVOT_URL, MONITOR_REPORTS_URL, PAGE_SIZE } from 'api/list';

const getStudies = (dispatch, token, params, start = 0, limit = PAGE_SIZE, count = false) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    if (count) {
        Axios.get(`${STUDIES_URL}/count?${params ? params : ''}`, { headers })
            .then((response) => {
                dispatch({
                    type: 'GET_STUDIES_COUNT',
                    response: response.data
                });
            })
            .catch((e) => {
                if (e && e.response && e.response.status === 401) {
                    dispatch({
                        type: 'GET_TOKEN_ERROR',
                    });
                }
                console.log('error', e);
            });
    }
    
    Axios.get(`${STUDIES_URL}?_start=${start}&_limit=${limit}&${params ? params : ''}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_STUDIES',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getStudyDetails = (dispatch, token, id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${STUDIES_URL}/${id}`, { headers })
        .then((response) => {
            dispatch({
                type: 'STUDY_DETAILS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getSitesByStudy = (dispatch, token, studyId, start = 0, limit = PAGE_SIZE) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    Axios.get(`${SITES_URL}/count?clinicalStudy._id=${studyId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'SITES_BY_STUDY_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
    
    Axios.get(`${SITES_URL}?clinicalStudy._id=${studyId}&_start=${start}&_limit=${limit}`, { headers })
        .then((response) => {
            dispatch({
                type: 'SITES_BY_STUDY',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getProcessDocByStudy = (dispatch, token, params, dataIndex = 0) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
 
    Axios.get(`${PROCESS_DOCS_URL}/count?${params}`, { headers })
        .then((response) => {
            dispatch({
                type: 'PROCESSDOCS_COUNT_BY_STUDY',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });


    Axios.get(`${PROCESS_DOCS_URL}?_start=${dataIndex}&_limit=${PAGE_SIZE}&${params}`, { headers })
        .then((response) => {
            dispatch({
                type: 'PROCESSDOCS_BY_STUDY',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getStudiesByStaff = (dispatch, token, staffId, role) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    switch (role) {
        case Roles.CCRA:
        case Roles.SITE_SCANNER:
        case Roles.SITE_SCANNER_RESOLVER:
            Axios.get(`${SITE_STAFF_PIVOT_URL}?staffProfile._id=${staffId}&isAccess=true&studySite.isActive=true&_limit=-1`, { headers })
                .then((response) => {
                    dispatch({
                        type: 'STUDIES_BY_STAFF',
                        response: response.data
                    });
                })
                .catch((e) => {
                    if (e && e.response && e.response.status === 401) {
                        dispatch({
                            type: 'GET_TOKEN_ERROR',
                        });
                    }
                    console.log('error', e);
                });
            break;
        case Roles.PM:
        case Roles.DM:
        case Roles.CSM:
        case Roles.DE:
            Axios.get(`${SITE_STAFF_PIVOT_URL}?staffProfile._id=${staffId}&isAccess=true&clinicalStudy.isActive=true&_limit=-1`, { headers })
                .then((response) => {
                    dispatch({
                        type: 'STUDIES_BY_STAFF',
                        response: response.data
                    });
                })
                .catch((e) => {
                    if (e && e.response && e.response.status === 401) {
                        dispatch({
                            type: 'GET_TOKEN_ERROR',
                        });
                    }
                    console.log('error', e);
                });
            break;
        default:
            dispatch({
                type: 'STUDIES_BY_STAFF',
                response: []
            });
            break;
    }
}

const getCCRADetails = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${SITE_STAFF_PIVOT_URL}?studySite._id=${siteId}&isAccess=true&role.name=cCRA`, { headers })
        .then((response) => {
            dispatch({
                type: 'STUDY_CCRA',
                response: response.data && response.data.length > 0 ? response.data[0] : null
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getMonitorReports = (dispatch, token, siteId, start=0, limit=PAGE_SIZE) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };

    Axios.get(`${MONITOR_REPORTS_URL}/count?studySite._id=${siteId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'MONITOR_REPORTS_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });

    Axios.get(`${MONITOR_REPORTS_URL}?studySite._id=${siteId}&_start=${start}&_limit=${limit}`, { headers })
        .then((response) => {
            dispatch({
                type: 'MONITOR_REPORTS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getActiveSites = (dispatch, token, studyId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${SITES_URL}?isActive=true&clinicalStudy._id=${studyId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'ACTIVE_SITES',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

export default { getStudies, getStudyDetails, getSitesByStudy, getProcessDocByStudy, getStudiesByStaff, getCCRADetails, getMonitorReports, getActiveSites }

