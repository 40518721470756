import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    Breadcrumb,
    BreadcrumbItem,
} from "reactstrap";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin"
import { StudyActions, SponsorActions } from "actions"
import { Routes } from 'constants/index.js';
import { RemoteTable } from "components";
import { PAGE_SIZE } from "api/list";

class Studies extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sponsor: { value: "All", label: "All" },
            studies: [],
            page:1
        };
        this.addStudy = this.addStudy.bind(this)
    }

    componentDidMount() {
        const token = this.props.token
        this.props.getStudies(token, '', 0, PAGE_SIZE, true);
        this.props.getSponsors(token, 0, PAGE_SIZE, true);
    }
    handleClick(e, index) {
        e.preventDefault();
        const data = this.props.studies.slice(this.state.currentPage * this.state.itemsPerPage, (this.state.currentPage + 1) * this.state.itemsPerPage);
        this.setState({
            currentPage: index,
            data: data,
            click: this.state.click + 1,
        });
    }

    onItemClick = (e, row, index) => {
        this.props.history.push(`${Routes.STUDIES}/${row._id}`)
    }

    addStudy() {
        this.props.history.push(`${Routes.STUDIES}/add`)
    }

    onFilterChange = (value) => {
        console.log(value);
    }

    handleTableChange = (type, { page, sizePerPage }) => {

        const currentIndex = (page - 1) * sizePerPage;
        this.setState({ page })

        this.props.getStudies(this.props.token, '', currentIndex, PAGE_SIZE)

    }

    render() {

        const { sponsor, page } = this.state;
        var studies = this.props.studies;
        var sponsors = this.props.sponsors;
        var sponsorFilter = [{ value: "All", label: "All" }]
        sponsors.map(val => {
            return sponsorFilter.push({ value: val._id, label: val.name })
        })

        studies = sponsor.value !== 'All' ? studies.filter(val => { return (val.sponsorerData && val.sponsorerData._id === sponsor.value) }) : studies

        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Studies</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col sm={12}>
                                            <div>
                                                <CardTitle tag="h5" className="float-left mt-2">Studies</CardTitle>
                                                <Button className="btn-round float-right" color="primary" onClick={this.addStudy} size="sm">
                                                    Add New Study
                                                </Button>
                                                <div className="float-right mt-2 mr-2" style={{ lineHeight: 1, width: '160px' }} >
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        placeholder="Filter by Study"
                                                        name="singleSelect"
                                                        value={sponsor}
                                                        options={sponsorFilter}
                                                        onChange={(value) =>
                                                        {
                                                            this.setState({ sponsor: value })
                                                            this.onFilterChange(value.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <RemoteTable
                                        data={studies}
                                        page={page}
                                        totalSize={this.props.totalSize}
                                        sizePerPage={PAGE_SIZE}
                                        onItemClick={this.onItemClick}
                                        columns={
                                            [
                                                {
                                                    dataField: 'id',
                                                    text: 'S. No',
                                                    formatter: (cell, row, index) => {
                                                        return `${(index + ((this.state.page - 1) * PAGE_SIZE)) + 1}`
                                                    }
                                                },
                                                {
                                                    dataField: 'name',
                                                    text: 'Study Name',
                                                },
                                                {
                                                    dataField: 'numberOfSites',
                                                    text: 'No. of Sites',
                                                },
                                                {
                                                    dataField: 'sponsorerData.name',
                                                    text: 'Sponsor\'s Name',
                                                }
                                            ]
                                        }
                                        onTableChange={this.handleTableChange}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, studies, sponsors }) => {
    return {
        token: user.token,
        studies: studies.data ? studies.data : [],
        totalSize: studies.count,
        sponsors: sponsors.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getStudies: (token, params, start, limit, count) => StudyActions.getStudies(dispatch, token, params, start, limit, count),
        getSponsors: (token,  start, limit, count) => SponsorActions.getSponsors(dispatch, token,  start, limit, count)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Studies);