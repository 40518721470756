import React from 'react';
import {
    Card,
    Row,
    Col, Button
} from "reactstrap";
import { captureWidth, captureHeight, readContentFromScreenshot } from 'commons/Utils';
import ScriptTag from 'react-script-tag';
import "assets/css/scanner.css";

const UploadFile = ((props) => {

    const fileInput = React.useRef();
    const videoElem = React.useRef();

    const startCapture = async () => {
        try {
            // start capture streaming
            const image = await readContentFromScreenshot(videoElem);
            props.onScreenshotCapture(image);
        } catch (e) {
            alert('capture screenshot failed')
            console.log(e);
        }
    }
  
    const scanToWebPage = async () => {
        try {
            if (window.scanner) {
                window.scanner.scan(async (successful, mesg, response) => {
                    if (!successful) { // On error
                        console.error('Failed: ' + mesg);
                        return;
                    }
                    if (successful && mesg != null && mesg.toLowerCase().indexOf('user cancel') >= 0) { // User cancelled.
                        console.info('User cancelled scan');
                        return;
                    }
                    await props.onScanCompleted(window.scanner.getScannedImages(response, true, false));
                }, {
                    "use_asprise_dialog": false, // Whether to use Asprise Scanning Dialog
                    "show_scanner_ui": false, // Whether scanner UI should be shown
                    "twain_cap_setting" : {
                        "ICAP_PIXELTYPE" : "TWPT_RGB", // Color
                        "ICAP_SUPPORTEDSIZES" : "TWSS_USLETTER" // Paper size: TWSS_USLETTER, TWSS_A4, ...
                    }, 
                    "output_settings" : [
                        { "type" : "return-base64", "format" : "jpg"} // return images to web page
                       // { "type": "upload", "format": "pdf", // upload as PDF
                       //     "upload_target": { 
                       //         "url": "https://asprise.com/scan/applet/upload.php?action=dump"
                       //     }
                       // }
                    ]
                });
            } else {
                alert("No scanner detected. Please attach a scanner");
            }
        } catch (error) {
            console.log(error);
        }
        
    }

    return (
        <Card style={{ minHeight: props.small ? '200px' : '626px'}}>
            <Col style={{ marginTop: props.small ? '0px' : '120px', textAlign: 'center' }}>
                {
                    !props.small &&
                    <Col>
                        <h5 style={{ fontWeight: 500, fontSize: '24px', marginBottom: '5px' }}>Upload New Document</h5>
                        <span style={{ fontSize: '14px' }}>Upload a document using below options and proceed to enter meta data</span>
                    </Col>
                }
                <Row style={{ justifyContent: 'center' }}>
                    <div className="uploadinfo-card" onClick={() => fileInput.current.click()}>
                        <input
                            hidden
                            type="file"
                            multiple
                            accept='image/*,.pdf'
                            onChange={props.onFileUploadFromComputer}
                            ref={fileInput}
                        />
                        <div className="monitor" />
                        <div className="label">Upload from Computer</div>
                    </div>
                    <div className="uploadinfo-card" onClick={startCapture}>
                        <video
                            autoPlay
                            hidden
                            ref={videoElem}
                            width={captureWidth}
                            height={captureHeight}
                        />
                        <div className="screen" />
                        <div className="label">Screen</div><div>Capture</div>
                    </div>
                    <div className="uploadinfo-card" onClick = {async e => await scanToWebPage()}>
                        <ScriptTag type="text/javascript" src="/scanner.js" />
                        <div className="scanner" />
                        <div className="label">Upload from Scanner</div>
                    </div>
                </Row>
                <Row className="text-center" hidden={props.hideCancel}>
                    <Col>
                        <Button color="primary" outline className="btn-round" type="button" size="sm" onClick={props.onCancel}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Card>
    );
});

export default UploadFile;