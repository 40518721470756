import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Button
} from "reactstrap";
// reactstrap validations
import { AvForm, AvField } from 'availity-reactstrap-validation';
import 'tui-image-editor/dist/tui-image-editor.css'
import { DocTypes } from "constants/index.js";
import { RenderImage } from 'components';

function EditUploadDocument(props) {

    const [selectedImage, setSelectedImage] = useState(0);

    const document = props.details ? props.details[0] : undefined;

    const documentUrl = props.details && props.details[selectedImage].versionedDocument[props.details[selectedImage].versionedDocument.length - 1].url

    const handleValidSubmit = (event, values) => {
        console.log(values);
        const subject = props.subjects.filter(val => val._id === values.subject)[0];
        const visit = props.visits.filter(val => val._id === values.visit)[0];
        values['subject'] = subject;
        values['visit'] = visit;
        props.onDocumentDataCollected(values);
    }
    
    let domainData = undefined;
    let visit = undefined;
    
    // for ICF documents, set domainData and visit as fixed
    if (props.visits && document) {
        if (document.docType === 'ICF') {
            domainData = 'ICF';
            visit =  props.visits.find(v => v.name === 'Screening');
            visit = visit ? visit._id : undefined;
        } else {
            domainData = document.domainData;
            visit = document.visit ? document.visit.id : undefined;
        }
    }
    
    return (
        <Card style={{ minHeight: '726px' }}>
            <CardHeader>
                <CardTitle tag="h5">Edit Document Meta Information</CardTitle>
            </CardHeader>
            <CardBody>
                <AvForm onValidSubmit={handleValidSubmit}>
                    <Row style={{ marginLeft: '0px' }}>
                        <Col xs={6} md={12} lg={3}>
                            <AvField name="site" autoComplete="off" value={props.site ? props.site.name : ''} placeholder="Site Name" label="Site Name" type="text" disabled />
                            <AvField name="protocol" autoComplete="off" value={props.study ? props.study.name : ''} placeholder="Protocol" label="Study Name" type="text" disabled />
                            <AvField className="minimal" type="select" value={document ? document.docType : undefined} name="docType" label="Document Type" errorMessage='Please select a doc type.'>
                                {
                                    DocTypes.map((val, index) => {
                                        return (
                                            <option key={val.value} value={val.value}>{val.label}</option>
                                        )
                                    })
                                }
                            </AvField>
                            <AvField className="minimal" type="select" value={document && document.subject ? document.subject._id : undefined} name="subject" label="Trial Participant ID" errorMessage='Please select a subject.'
                                >
                                {
                                    props.subjects && props.subjects.map(val =>
                                        <option key={val._id} value={val._id}>{val.uniqueCode}</option>
                                    )
                                }
                            </AvField>
                            <AvField className="minimal" type="select" value={domainData} name="domainData" label="Domain Data" errorMessage='Please select a domain.'
                               disabled = {document && document.docType === 'ICF'}>
                                <option value="">Select</option>
                                {
                                    props.site && props.site.domainData && props.site.domainData.map(val =>
                                        <option key={val} value={val}>{val}</option>
                                    )
                                }
                            </AvField>
                            <AvField className="minimal" type="select" value={visit}  name="visit" label="Visit" errorMessage='Please select a visit.'
                                disabled = {document && document.docType === 'ICF'}>
                                <option value="">Select</option>
                                {
                                    props.visits && props.visits.map(val =>
                                        <option key={val._id} value={val._id}>{val.name}</option>
                                    )
                                }
                            </AvField>
                            <div className="text-center" style={{ marginTop: '10px' }}>
                                <Button color="primary" className="btn-round" size="sm" type="submit" >Update</Button>
                                <Button color="primary" className="btn-round ml-4" outline size="sm" type="button" onClick={props.onCancel} >Cancel</Button>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={9}>
                            <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                                <Col xs={10}>
                                    <div>
                                        <div className="mx-auto" style={{ width: '600px', height: '400px', borderColor: 'gray', borderWidth: '1px', borderStyle: 'solid', borderRadius: '8px' }}>
                                            <div>
                                                {
                                                    document && document.versionedDocument && document.versionedDocument.length > 0 &&
                                                    <RenderImage
                                                        alt="." width={'600px'} height={'400px'} style={{ borderRadius: '8px' }}
                                                        src={documentUrl}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={2}>
                                    <div style={{ height: '320px', overflowY: 'scroll' }}>
                                        <ul style={{ listStyle: 'none', paddingInlineStart: '0px' }}>
                                            {
                                                props.details && props.details.map((val, i) => {
                                                    const files = val.versionedDocument;
                                                    const file = files[files.length - 1].url;
                                                    return (
                                                        <li key={i}>
                                                            <div className="mx-auto" style={{ width: '66px', height: '66px', position: 'relative' }}>
                                                                {/* <i class="fa fa-times" aria-hidden="true" style={{ position: 'absolute', top: '-5px', right: '-1px', cursor: 'pointer', zIndex: 2 }} onClick={() => { props.onImageDelete(i) }}></i> */}
                                                                <div className={`extra-document ${selectedImage === i ? 'selected' : ''} mx-auto`}
                                                                    onClick={() => {
                                                                        setSelectedImage(i)
                                                                    }}>
                                                                    <RenderImage alt='.' src={file} style={{ borderRadius: '6px' }} />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </AvForm>
            </CardBody>
        </Card>
    );
};

export default EditUploadDocument;
