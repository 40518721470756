import React, { Fragment } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Validations } from 'constants/index';

const AboutStudy = (props) => {

    const handleSubmit = (event, values) => {
        props.onAboutStudySubmit(event, values);
    }

    return (
        <Fragment>
            <Row>
                <Col sm={5}>
                    <h6>Detalis About Study</h6>
                    <AvForm onValidSubmit={handleSubmit}
                    // model={{ name: 'New study test', protocolId: 'P01', regulatoryAuthority: 'EMA', quanticateNo: 'Q01', noOfSites: '2', projectManager: '5f1b3826e1623566c4f33ff4' }}
                    >
                        <AvField name="name" autoComplete="off" label="Study Name" type="text" validate={{
                            required: { value: true, errorMessage: 'Please enter a study name.' },
                            pattern: Validations.ALPHA_NUMERICS_WITH_SPECIAL_CHARS,
                            minLength: { value: 6, errorMessage: 'Study name must be at least 6 characters' },
                            maxLength: { value: 50, errorMessage: 'Study name must not be more than 50 characters.' },
                        }} />
                        <AvField className="minimal" name="regulatoryAuthority" autoComplete="off" label="Regulatory Authority" required errorMessage='Please select an authority.' type="select">
                            <option value='null'>Select</option>
                            <option value='EMA'>EMA</option>
                            <option value='FDA'>FDA</option>
                        </AvField>
                        <AvField name="protocolId" autoComplete="off" label="Protocol ID" type="text" validate={{
                            required: { value: true, errorMessage: 'Please enter a protocol Id.' },
                            pattern: Validations.ALPHA_NUMERCIS,
                            minLength: { value: 2, errorMessage: 'Protocol ID must be at least 2 characters' },
                            maxLength: { value: 20, errorMssage: 'Protocol ID must not be more than 20 characters.' }
                        }} />
                        <AvField name="quanticateNo" autoComplete="off" label="Q ID" type="text" validate={{
                            required: { value: true, errorMessage: 'Please enter a queue number.' },
                            pattern: Validations.ALPHA_NUMERCIS,
                            minLength: { value: 2, errorMessage: 'Quanticate No. must be at least 2 characters' },
                            maxLength: { value: 20, errorMessage: 'Quanticate No. must not be more than 20 characters.' }
                        }} />
                        <AvField name="noOfSites" autoComplete="off" label="No. of Sites" type="number" validate={{
                            required: { value: true, errorMessage: 'Please enter number of sites.' },
                            pattern: Validations.POSITIVE_NUMBERS,
                            min: { value: 1, errorMessage: 'Atleast one site must be created.' },
                            maxLength: { value: 3, errorMessage: 'No. of sites cannot be more than 999' },
                        }} />
                        <AvField className="minimal" type="select" name="projectManager" label="Select PM" 
                            validate={{
                                    required: { value: true, errorMessage: 'Please select a PM.' },
                            }}
                        >
                            <option value='null'>Select</option>
                            {
                                props.managers && props.managers.map((val) => {
                                    return <option key={val._id} value={val._id}>{`${val.firstName} ${val.lastName}`}</option>
                                })
                            }
                        </AvField>
                        <Button color="primary" style={{ marginLeft: '20px' }} className="btn-round" size="sm" type="submit">
                            Next
                        </Button>
                    </AvForm>
                </Col>
            </Row>
        </Fragment>
    );
}

export default AboutStudy