import React from "react";
// reactstrap components
import {
    Button,
    CardTitle,
    Row,
    Col,
} from "reactstrap";

export default class CRFPage extends React.Component {
    constructor() {
        super();
        this.state = {
            data: false,
        }
        this.onclickPlan = this.onclickPlan.bind(this);
    }
    onclickPlan() {
        this.setState({ data: true })
    }
    render() {
        return (
            <>
                <Col>
                    <Row>
                        <Col>
                            <CardTitle>Customise CRF Pages</CardTitle>
                        </Col>
                        <Col className="text-right">
                            {this.state.data ?
                                <Button color="primary" style={{ minWidth: '190px' }} className="btn-round" size="sm" onClick={this.addSite}>
                                    Submit Plan
                                 </Button> :
                                <Button color="primary" style={{ minWidth: '190px' }} className="btn-round" size="sm" onClick={this.onclickPlan}>
                                    Change Plan
                                </Button>
                            }
                        </Col>
                    </Row>
                    <Col sm={8}>
                        <Row style={{ fontSize: '8px', marginLeft: '-15px', fontWeight: '600px' }}>
                            <Button color="success" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page1
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page2
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page3
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page4
                             </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page5
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page6
                            </Button>
                        </Row>
                        <Row style={{ fontSize: '9px', marginLeft: '-15px', fontWeight: '600px', marginTop: '-18px' }}>
                            <Button color="success" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page7
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page8
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page9
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page10
                             </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page11
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page12
                            </Button>
                        </Row>
                        <Row style={{ fontSize: '8px', marginLeft: '-15px', fontWeight: '600px', marginTop: '-18px' }}>
                            <Button color="success" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page13
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page14
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page15
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page16
                             </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page17
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page18
                            </Button>
                        </Row>
                        <Row style={{ fontSize: '8px', marginLeft: '-15px', fontWeight: '600px', marginTop: '-18px' }}>
                            <Button color="success" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page19
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page20
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page21
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page22
                             </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page23
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page24
                            </Button>
                        </Row>
                        <Row style={{ fontSize: '8px', marginLeft: '-15px', fontWeight: '600px', marginTop: '-18px' }}>
                            <Button color="success" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page25
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page26
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor} >
                                CRF Page27
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page28
                             </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page29
                            </Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" onClick={this.createSponsor}>
                                CRF Page30
                            </Button>
                        </Row>
                    </Col>
                </Col>
            </>
        )
    }
}
