import React, { Fragment } from 'react';
import { Navbar, Card, CardBody, Container, Button, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import queryString from 'query-string';
import { activateAccount, resetPassword, updateStaff } from 'api';
import { connect } from 'react-redux';
import { Roles, Routes, Validations } from 'constants/index';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import AvFeedback from 'availity-reactstrap-validation/lib/AvFeedback';
import cogoToast from 'cogo-toast';

const passwordMatch = (value, ctx) => {
    if (ctx.password !== ctx.passwordConfirmation) {
        return "Password didn't match";
    }
    return true;
}

class SetResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            option: props.option,
            valid: true
        };
    }

    passwordRules = (value, ctx) => {
        const rules = Validations.PASSWORD
        const isValid = rules.values.test(value)
        return isValid ? isValid : rules.errorMessage;
    }

    onValidSubmit = async (event, values) => {
		let roleName = '';
        switch (this.props.option) {
            case 'set':
                try {
                    if (this.props.user && this.props.user.staffProfile) {
                        const staffId = this.props.user.staffProfile._id;
                        const staffResponse = await updateStaff(this.props.user.token, staffId, { password: values.password });
                        this.props.updateProfile(staffResponse.data);
						roleName = this.props.user.role.name;
                    } else {
                        cogoToast.warn('Missing staff profile!')
                    }
                } catch (error) {
                    console.log(error);
                    cogoToast.error('Failed to activate account!')
                }
                break;
            case 'reset':
                let params = queryString.parse(this.props.location.search)
                values['code'] = params.code;
				try {
                    const response = await resetPassword(values);
					if (response.status === 200) {
						this.props.saveToken(response.data);
						roleName = response.data ? response.data.user.role.name : '';
					} else {
						console.log(response);
						alert('Failed to reset password')
					}
                } catch (error) {
                    console.log(error);
                    alert('Failed to reset password')
                }
                break;
            default:
                break;
        }
		
		switch (roleName) {
			case Roles.SYSTEM_ADMIN:
				this.props.history.push(Routes.DASHBOARD);
				break;
			case Roles.SITE_SCANNER_RESOLVER:
			case Roles.SITE_SCANNER:
			case Roles.CCRA:
			case Roles.PM:
			case Roles.DM:
			case Roles.DE:
			case Roles.CSM:
				this.props.history.push(Routes.PROTOCOL);
				break;
			default:
				return
		}
    }

    componentDidMount = async () => {
        let params = queryString.parse(this.props.location.search);
        switch (this.props.option) {
            case 'set':

                if (params.confirmation) {
                    try {
                        const response = await activateAccount(params.confirmation);
                        this.props.saveToken(response.data);
                    } catch (error) {
                        console.log(error);
                        cogoToast.error('Activation failed!')
                        if (this.state.valid)
                            this.setState({ valid: false });
                    }
                } else {
                    this.setState({ valid: false });
                }
                break;
            case 'reset':
                if (!params.code) {
                    this.setState({ valid: false });
                }
                break;
            default:
                break;
        }
    }

    setFormRef = (ref) => {
        this.form = ref;
        // if (ref) {
            // const old = ref.setError;
            // ref.setError = (...args) => {
                // old.call(ref, ...args);
                // this.forceUpdate();
            // }
        // }
    }

    render() {
        const { option, valid, showPassword, showConfirmPassword } = this.state;
        return (
            <Fragment>
                <Navbar style={{ backgroundColor: '#42B5A9', marginBottom: '0px' }} >
                    <Container fluid>
                        <a href="/" className="mx-auto" >
                            <img alt="Logo" src={require('../../assets/img/logo.png')} height={40} />
                        </a>
                    </Container>
                </Navbar>
                <div className="wrapper wrapper-full-page reset-page" style={{ backgroundColor: '#edf0fa', minHeight: 'calc(100vh - 80px)' }}>
                    <Card className="mx-auto" style={{ width: '535px', height: '570px', paddingTop: '100px' }}>
                        <CardBody className="text-center">
                            {
                                valid ?
                                    <Fragment>
                                        <img src={require('../../assets/img/reset_password.svg')} alt='Reset Password' />
                                        <p className="reset-title">{option === 'set' ? 'Activate Your Account' : 'Reset your password'}</p>
                                        <p className="reset-sub-title">{option === 'set' ? 'Create your password to activate your account. Make sure it has special character like “!,@,#,$,%,&”' : 'Reset your password for your account using the form below. Make sure it has special character like “!,@,#,$,%,&”'}</p>
                                        <AvForm ref={this.setFormRef} onValidSubmit={this.onValidSubmit} style={{ marginTop: '40px', marginLeft: '80px', marginRight: '80px' }}>
                                            <AvGroup>
                                                <InputGroup className={
                                                    this.state.passwordFocus ? "input-group-focus" : ""
                                                }>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText><i className="fa fa-lock" aria-hidden="true" /></InputGroupText>
                                                    </InputGroupAddon>
                                                    <AvInput name="password" autoComplete="off" placeholder={option === 'set' ? "Create Password" : 'New Password'} type={showPassword ? "text" : "password"}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter a password.' },
                                                            myRules: this.passwordRules
                                                        }}
                                                        onFocus={(e) =>
                                                            this.setState({ passwordFocus: true })
                                                        }
                                                        onBlur={(e) =>
                                                            this.setState({ passwordFocus: false })
                                                        }
                                                        style={{ minHeight: '45px', borderRight: 'none', borderRadius: '0px', maxWidth: '330px' }} />
                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText style={{ paddingRight: '18px', borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }} onClick={() => { this.setState({ showPassword: !showPassword }) }}><i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true" /></InputGroupText>
                                                    </InputGroupAddon>
                                                    <AvFeedback>{this.form && this.form.getChildContext().FormCtrl.getInputState("password").errorMessage}</AvFeedback>
                                                </InputGroup>
                                            </AvGroup>
                                            <AvGroup>
                                                <InputGroup className={this.state.confirmPasswordFocus ? "input-group-focus" : ""} style={{ marginTop: '20px', }}>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText><i className="fa fa-lock" aria-hidden="true" /></InputGroupText>
                                                    </InputGroupAddon>
                                                    <AvInput name="passwordConfirmation" autoComplete="off" placeholder={option === 'set' ? "Confirm Password" : 'Confirm New Password'} type={showConfirmPassword ? "text" : "password"}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Password doesn\'t match.' },
                                                            myValidation: passwordMatch,
                                                            minLength: { value: 8, errorMessage: 'Password should be min 8 characters length.' },
                                                        }}
                                                        onFocus={(e) =>
                                                            this.setState({ confirmPasswordFocus: true })
                                                        }
                                                        onBlur={(e) =>
                                                            this.setState({ confirmPasswordFocus: false })
                                                        }
                                                        style={{ minHeight: '45px', borderRight: 'none', borderRadius: '0px', maxWidth: '330px' }} />
                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText style={{ paddingRight: '18px', borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }} onClick={() => { this.setState({ showConfirmPassword: !showConfirmPassword }) }}><i className={showConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true" /></InputGroupText>
                                                    </InputGroupAddon>
                                                    <AvFeedback>Password doesn't match.</AvFeedback>
                                                </InputGroup>
                                            </AvGroup>
                                            <Button color="primary" className="btn-round" size="sm" type="submit">
                                                {option === 'set' ? 'Activate Account' : 'Reset Password'}
                                            </Button>
                                        </AvForm>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <img src={require('../../assets/img/invalid.svg')} alt='Reset Password' />
                                        <p className="reset-title">Invalid Code</p>
                                        <p className="reset-title">Please contact your administrator</p>
                                    </Fragment>
                            }
                        </CardBody>
                    </Card>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return {
        user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveToken: (data) => dispatch({
            type: 'GET_TOKEN',
            response: { ...data }
        }),
        updateProfile: (data) => dispatch({ type: 'UPDATE_PROFILE', response: data }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SetResetPassword);