import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    BreadcrumbItem,
    Col,
    Breadcrumb,
} from "reactstrap";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";
import { Routes } from 'constants/index.js';
import { SiteActions, DocumentActions } from "../../actions";
import { getToken, updateCurrentMonitorDocument, createMonitorDocVersion } from "api/index.js";
import { CertifyUploadDocument, UploadConfirm } from "./Components";
import { convertUrlToDataUrl, uploadBlobToStrapi } from "commons/Utils.js";
import { memoize } from "lodash";

class CertifyDocument extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            alertType: 'warning',
            alertMessage: '',
            documentPopup: false,
            file : '',
            stage: 'data',
            monitorDoc: undefined
        }
    }

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    }

    componentDidMount() {
        const token = this.props.token;
        const siteId = this.props.site && this.props.site._id;
        if (siteId) {
            this.props.getSiteSubjects(token, siteId);
            this.props.getSiteVisits(token, siteId)
        }

        this.props.getDocumentDetails(token, this.props.match.params.id)
    }

    onDocumentDataCollected = (values) => {
        this.setState({ monitorDoc: values, stage: 'confirm' })
    }

    onEditClick = () => {
        this.setState({ editMode: true });
    }

    saveRedactedDocument = async (image) => {
        var oldFile = this.state.file;
        oldFile = { fileType: 'image', previewUrl: image }
        await this.setState({ file: oldFile, stage: 'data', editMode: false });
    }

    onUploadConfirmClick = (values) => {
        const identifier = this.props.user.email;
        getToken(identifier, values.password).then(async (response) => {
            try {

               
                this.props.saveToken(response.data);
                const { file, monitorDoc } = this.state;

                console.log(values);
                console.log(monitorDoc);

                const monitorDocObj = {
                    docType: monitorDoc.docType,
                    domainData: monitorDoc.domainData,
                }

                if (monitorDoc.subject) {
                    monitorDocObj['subject'] = monitorDoc.subject._id
                }

                if (monitorDoc.visit) {
                    monitorDocObj['visit'] = monitorDoc.visit._id
                }

                await updateCurrentMonitorDocument(this.props.token, this.props.match.params.id, monitorDocObj);

               
                const fileResponse = await uploadBlobToStrapi(this.props.user.token, file.previewUrl);
                const versionResponse = await createMonitorDocVersion(this.props.user.token, {
                    'monitorDocument': this.props.match.params.id,
                    'uploadedBy': this.props.user.staffProfile._id,
                    'url': fileResponse.data[0].url,
                    'employeeAccess' : true,
                    'siteStaffAccess' : false // no access to site staff for ccra copy
                });
                console.log(versionResponse);
           
                this.props.history.push(Routes.DOCUMENTS);
            } catch (error) {
                alert('API failed!')
                console.log(error);
            }

        }).catch((e) => {
            const m = e.response ? e.response.data.data[0].messages[0].message : 'Something went wrong';
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        title="Alert"
                        onConfirm={this.hideAlert}
                    >
                        {m}
                    </SweetAlert>
                )
            });
        });
    }

    onCancel = () => {
        this.props.history.push(this.props.details ? `${Routes.DOCUMENTS}/${this.props.details.uploadGroup}` : '');
    }

    // Re-run the filter whenever the list array or filter text changes:
    processDetails = memoize(
        (details) => {
            if (details) {
                var docUrl = details && details.versionedDocument && details.versionedDocument.length > 0 ? _.last(details.versionedDocument).url : undefined;
                if (docUrl) {
                    convertUrlToDataUrl(docUrl, this.props.token, (result) => {
                        this.setState({ file: {fileType: 'image', previewUrl: result } })
                    })
                }
            }
        }
    );

    render() {
        this.processDetails(this.props.details);
        const { stage, monitorDoc, editMode, file } = this.state;
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.DOCUMENTS}`}>Documents</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={this.props.details ? `${Routes.DOCUMENTS}/${this.props.details.uploadGroup}` : ''}>Document Details</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Certify Document</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {this.state.alert}
                    {
                        stage === 'data' ?
                            <CertifyUploadDocument
                                {...this.props}
                                // onDescriptionChange={this.onDescriptionChange}
                                // onSubjectChange={this.onSubjectChange}
                                // onDocTypeChange={this.onDocTypeChange}
                                // onAddMorePagesClick={this.onAddMorePagesClick}
                                file={file}
                                editMode={editMode}
                                closeRedactWindow={async e => await this.setState({ editMode: false })}
                                saveRedactedDocument={this.saveRedactedDocument}
                                onEditClick={this.onEditClick}
                                onCancel = {this.onCancel}
                                documentPopup = {this.state.documentPopup}
                                toggle = { () =>{ console.log("hello"); this.setState({documentPopup : !this.state.documentPopup})}}
                                onDocumentDataCollected={this.onDocumentDataCollected} />
                            : null
                    }
                    {
                        stage === 'confirm' ?
                            <UploadConfirm
                                files={[file]}
                                {...this.props}
                                document={monitorDoc}
                                onUploadConfirmClick={this.onUploadConfirmClick} />
                            : null
                    }
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, sites, documents, studies }) => {
    return {
        token: user.token,
        user: user,
        role: user.role ? user.role.name : undefined,
        details: documents.sdvDocument,
        subjects: sites.subjects,
        visits: sites.visits,
        study: studies.current,
        site: sites.current
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDocumentDetails: (token, id) => DocumentActions.getDocumentDetailsById(dispatch, token, id),
        getSiteVisits: (token, siteId) => SiteActions.getSiteVisitDetails(dispatch, token, siteId),
        getSiteSubjects: (token, siteId) => SiteActions.getSiteSubjects(dispatch, token, siteId),
        saveToken: (data) => dispatch({
            type: 'GET_TOKEN',
            response: { ...data }
        }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CertifyDocument);