import React, { useState } from 'react';
import { Row, Col, Label, FormGroup} from 'reactstrap';
import MultiSlider from "multi-slider";


export const RiskParameter = (props) => {

    // const [study, setStudy] = useState(null)
    const [green, setGreen] = useState(5)
    const [amber, setAmber] = useState(20)
    const [red, setRed] = useState(75)


    const onChange = (e) => {
        setGreen(e[0]);
        setAmber(e[1]);
        setRed(e[2]);
        props.onChange({ green: green, amber: amber, red: red });
    }

    return (
        <>
            <Row>
                <Col>
                    <FormGroup>
                        <br />
                        <Label tag="h5">Define Risk Assessment Categorization</Label>
                        <Label>Use the sliders to define the risk categories. Define Green and Amber, rest will be red.</Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <MultiSlider
                        handleSize={8}
                        trackSize={5}
                        handleStrokeSize={8}
                        handleInnerDotSize={0}
                        colors={["#0db70c", "#e7ad3a", "#f00"]}
                        defaultValues={[green, amber, red]}
                        onChange={onChange} />
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Label tag="p"><i className="fa fa-circle" style={{ color: "#0db70c", paddingRight: '10px' }} aria-hidden="true"></i>Green less than {green}%</Label>
                </Col>
            </Row>
            <Row>
                <Col sm={5}>
                    <Label tag="p"><i className="fa fa-circle" style={{ color: "#e7ad3a", paddingRight: '10px' }} aria-hidden="true"></i>Amber from {green}% to {amber}%</Label>
                </Col>
            </Row>
            <Row>
                <Col sm={5}>
                    <Label tag="p"><i className="fa fa-circle" style={{ color: "#f00", paddingRight: '10px' }} aria-hidden="true"></i>Red greater than {amber}%</Label>
                </Col>
            </Row>
        </>
    )

}
export default RiskParameter;
