import React from 'react';
import { Roles } from "constants/index"
import { Badge } from "reactstrap"
import { PAGE_SIZE } from 'api/list';

export default (role, state) => {
    switch (role) {
        case Roles.SYSTEM_ADMIN:
            return [
                {
                    dataField: 'id',
                    text: 'S. No',
                    formatter: (cell, row, index) => {
                        return `${(index + ((state.page - 1) * PAGE_SIZE)) + 1}`
                    }
                },
                {
                    dataField: 'name',
                    text: 'Name',
                    formatter: (cell, row) => {
                        return `${row.firstName} ${row.lastName}`
                    }
                },
                {
                    dataField: 'user.role',
                    text: 'Role',
                },
                {
                    dataField: 'user.email',
                    text: 'Email',
                },
                {
                    dataField: 'mobileNo',
                    text: 'Mobile No.',
                },
                {
                    dataField: 'user.blocked',
                    text: 'Status',
                    formatter: (cell, row) => {
                        if(row.user && row.user.blocked)
                            return <Badge className="inactive-badge">Inactive</Badge>
                        else
                            return <Badge className="active-badge">Active</Badge>
                    }
                }
            ]
        case Roles.CCRA:
            return [
                {
                    dataField: 'id',
                    text: 'S. No',
                    formatter: (cell, row, index) => {
                        return `${(index + ((state.page - 1) * PAGE_SIZE)) + 1}`
                    }
                },
                {
                    dataField: 'name',
                    text: 'Name',
                    formatter: (cell, row) => {
                        return `${row.firstName} ${row.lastName}`
                    }
                },
                {
                    dataField: 'user.role',
                    text: 'Role',
                },
                {
                    dataField: 'user.email',
                    text: 'Email',
                },
                {
                    dataField: 'mobileNo',
                    text: 'Mobile No.',
                },
            ]
        default: 
            return [
                {
                    dataField: 'id',
                    text: 'S. No',
                    formatter: (cell, row, index) => {
                        return `${(index + ((state.page - 1) * PAGE_SIZE)) + 1}`
                    }
                },
                {
                    dataField: 'name',
                    text: 'Name',
                    formatter: (cell, row) => {
                        return `${row.staffProfile.firstName} ${row.staffProfile.lastName}`
                    }
                },
                {
                    dataField: 'role.name',
                    text: 'Role',
                },
                {
                    dataField: 'email',
                    text: 'Email',
                },
                {
                    dataField: 'staffProfile.mobileNo',
                    text: 'Mobile No.',
                },
            ]
    }
}