import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import './queries.css'

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";
import { Routes } from 'constants/index.js';
import { createQuery } from "api";
import { UploadFile } from "views/Documents/Components";
import { checkFileSize, dataURLtoFile, readContentAsImage, readFileAsDataURL, uploadBlobToStrapi } from "commons/Utils";

import { DocumentActions, SiteActions } from "actions/index.js";
import cogoToast from "cogo-toast";
import { AvForm } from "availity-reactstrap-validation";
import AvField from "availity-reactstrap-validation/lib/AvField";


class RaiseSourceDataQuery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadimg: false,
            file: '',
            documentPopup: false,
            imagePreviewUrl: '',
            queryDetails: "",
            selectedSubject: "",
            isUploading: false
        }
        this.onCancel = this.onCancel.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async componentDidMount() {
        await this.props.getDocumentDetails(this.props.token, this.props.match.params.id);
        if (this.props.site) {
            await this.props.getSiteSubjects(this.props.token, this.props.site._id);
        }
    }

    onScreenshotCapture = async (image) => {
        let file = await dataURLtoFile(image);
        await this.setState({ attachment: file, imagePreviewUrl: image });
    }

    onFileUploadFromComputer = async (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const validFile = checkFileSize(file, 10)
        if (validFile.valid) {
            const result = await readContentAsImage(await readFileAsDataURL(file));
            if (result && result.length > 0) {
                this.setState({ attachment: file, imagePreviewUrl: result[0] });
            }
        } else {
            cogoToast.warn(validFile.message)
        }
    }

    onScanCompleted = async (scannedImages) => {
        // select first document only as attachment url.
        let file = await dataURLtoFile(scannedImages[0].src);
        await this.setState({ attachment: file, imagePreviewUrl: scannedImages[0].src });
    }

    async onNext(event, values) {
        this.setState({ isUploading: true })
        try {
            // upload attachment file
            let fileData = await uploadBlobToStrapi(this.props.user.token, this.state.imagePreviewUrl);

            let monitorDocQueryObj = {
                // subject: this.props.user.myStudy.id,
                studySite: this.props.site._id,
                // docVersion: "1", // no document or version for source data query
                queryType: "SOURCE_DATA_QUERY",
                attachment: fileData && fileData.data.length ? fileData.data[0].url : '',
                query: values.queryDetails,
                queryStatus: 'OPENED',
                owner: this.props.user.staffProfile._id,
                cancelRemarks: "",
                subject: values.subject,
                isDeleted: false,
            }
            let response = await createQuery(this.props.token, monitorDocQueryObj);

            // const { hide } = cogoToast.success('Souce data query raised successfully.', {
            //     onClick: () => {
            //         hide();
            //     },
            //     position: "top-right",
            //     hideAfter: 3
            // });
            this.props.history.push(`${Routes.QUERIES}/${response.data._id}`)
        } catch (error) {
            this.setState({ isUploading: false })
        }
    }

    onCancel() {
        this.props.history.push(`${Routes.QUERIES}`)
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        let { imagePreviewUrl, isUploading } = this.state;

        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.QUERIES}`}>Queries</BreadcrumbItem>
                            <BreadcrumbItem active tag="span"> Raise Query</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Modal
                        isOpen={this.state.documentPopup}
                        size="lg"
                        centered
                        toggle={async () => await this.setState({ documentPopup: false })}
                        backdrop="static"
                        keyboard={false}
                    >
                        <ModalHeader toggle={async () => await this.setState({ documentPopup: false })}>
                            Document Preview
                        </ModalHeader>
                        <ModalBody>
                            <div className="query-image">
                                <img alt="." src={this.state.imagePreviewUrl} />
                            </div>
                        </ModalBody>
                    </Modal>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle className="mt-2"><h4> Raise Source Data Request </h4></CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={4}>
                                            <AvForm onValidSubmit={this.onNext}>
                                                <FormGroup>
                                                    <Label>Site ID</Label>
                                                    <p>{this.props.study.name}</p>
                                                </FormGroup>
                                                <AvField className="minimal" type="select" placeholder="Trial Participant" name="subject" label="Select Trial Participant"
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Please select a trial paticipant.' }
                                                    }} >
                                                    <option value="">Select</option>
                                                    {
                                                        this.props.subjects &&
                                                        this.props.subjects.map(val => {
                                                            return <option key={val._id} value={val._id}>{val.uniqueCode}</option>
                                                        })
                                                    }
                                                </AvField>
                                                <AvField name="queryDetails" autoComplete="off" label="Description for Source Data Request" type="textarea" validate={{
                                                    required: { value: true, errorMessage: 'Please fill query details.' },
                                                    minLength: { value: 6, errorMessage: 'Query details must be at least 6 characters' },
                                                    maxLength: { value: 500, errorMessage: 'Query details must not be more than 500 characters.' },
                                                }} />
                                                <Row>
                                                    <Button color="primary ml-5" className="btn-round" disabled={isUploading} type="submit" size="sm">
                                                        {isUploading ? 'Please wait..' : 'Submit Request'}
                                                    </Button>
                                                    <Button color="primary" outline className="btn-round" type="button" size="sm" onClick={this.onCancel}>
                                                        Cancel
                                                </Button>
                                                </Row>
                                            </AvForm>
                                        </Col>
                                        <Col xs={12} sm={8}>
                                            <Row>
                                                {
                                                    imagePreviewUrl ?
                                                        (<div
                                                            className="text-center ml-auto query-image"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={async () => await this.setState({ documentPopup: true })}
                                                        >
                                                            <img alt="." src={imagePreviewUrl} />
                                                        </div>) :
                                                        (<UploadFile
                                                            small={true}
                                                            hideCancel={true}
                                                            onScanCompleted={this.onScanCompleted}
                                                            onScreenshotCapture={this.onScreenshotCapture}
                                                            onFileUploadFromComputer={this.onFileUploadFromComputer}
                                                        />)
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        )
    }
}

const mapStateToProps = ({ user, documents, studies, sites }) => {
    return {
        token: user.token,
        details: documents.details,
        study: studies.current,
        site: sites.current,
        user: user,
        subjects: sites.subjects
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSiteSubjects: async (token, siteId) => await SiteActions.getSiteSubjects(dispatch, token, siteId),
        getDocumentDetails: async (token, id) => await DocumentActions.getDocumentDetails(dispatch, token, id),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RaiseSourceDataQuery);