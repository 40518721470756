import React from "react";
// reactstrap components
import {
    Button,
    CardTitle,
    Row,
    Col,
    Table,
} from "reactstrap";

export default class MonitoringReports extends React.Component {
    render() {
        return (
            <>
                <Col>
                    <Row>
                        <Col>
                            <CardTitle>Monitoring Reports</CardTitle>
                        </Col>
                        <Col className="text-right">
                            <Button color="primary" style={{ minWidth: '192px' }} className="btn-round" size="sm" onClick={this.addSite}>
                                Upload Monitoring Report
                        </Button>
                        </Col>
                    </Row>
                    <Col>
                        <Table responsive hover>
                            <thead className="info-title">
                                <tr>
                                    <th>Trial Participant ID</th>
                                    <th>Visit ID</th>
                                    <th>Report Name</th>
                                    <th>Description</th>
                                    <th>Uploaded On</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>984958</td>
                                    <td>V1</td>
                                    <td>SIT_CA101_4_05062020</td>
                                    <td>Psum dolor sit amet,consectur Ut anim ad,</td>
                                    <td>06/06/2020</td>
                                    <td className="more text-center" />
                                </tr>
                                <tr>
                                    <td>25646</td>
                                    <td>V3</td>
                                    <td>SIT_DA101_4_03062020</td>
                                    <td>Psum dolor sit amet,consectur Ut anim ad,</td>
                                    <td>05/06/2020</td>
                                    <td className="more text-center" />
                                </tr>
                                <tr>
                                    <td>984958</td>
                                    <td>V5</td>
                                    <td>SIT_BP11_5_02062020</td>
                                    <td>Psum dolor sit amet,consectur Ut anim ad,</td>
                                    <td>02/06/2020</td>
                                    <td className="more text-center" />
                                </tr>
                                <tr>
                                    <td>459845</td>
                                    <td>V1</td>
                                    <td>SIT_DA101_4_03052020</td>
                                    <td>Psum dolor sit amet,consectur Ut anim ad,</td>
                                    <td>02/06/2020</td>
                                    <td className="more text-center" />
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Col>
            </>
        )
    }
}
