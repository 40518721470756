import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
    Modal,
    ModalHeader,
    ModalBody,
    
} from "reactstrap";

import moment from 'moment';
import cogoToast from "cogo-toast";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";
import { Routes, Roles } from 'constants/index.js';
import { DocumentActions } from "actions/index.js";
import { RenderImage } from "components";
import noImageAccess from "assets/img/noImageAccess.jpeg";

class DocumentDetails extends React.Component {

    imageEditorRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            selectedImage: 0,
            showAlert: false,
            // alertType: 'warning',
            alertMessage: '',
            documentPopup: false,
            popupUrl: '',
            verifyDoc: false,
            deleteDoc : false
        }
    }

    componentDidMount() {
        this.props.getDocumentDetails(this.props.token, this.props.match.params.id);
    }

    onImageChange = (index) => {
        this.setState({ selectedImage: index })
    }

    hideAlert = async () => {
        await this.setState({ showAlert: false, alertMessage: '' });
    }

    onConfirmClick = async () => {
        if (this.state.verifyDoc) {
            let data = {};
            switch (this.props.role) {
                case Roles.CCRA:
                    data['isCCRAVerified'] = true;
                    data['ccraVerifyTime'] = moment.utc().format();
                 break;
                case Roles.DE:
                    if (this.props.dataEntryRole === 'DE1') {
                        data['de1CompletedBy'] = this.props.user.staffProfile.id;
                    } else if (this.props.dataEntryRole === 'DE2') {
                        data['de2CompletedBy'] = this.props.user.staffProfile.id;
                    }
                break;
                case Roles.DM:
                    data['deCompletedBy'] = this.props.user.staffProfile.id;
                break;    
                default:
                break;
            }
           
            // update document entry
            await this.props.setDocumentVerified(this.props.token, 
                this.props.details[this.state.selectedImage]._id,
                this.state.selectedImage, data);
            
            if (this.state.selectedImage === this.props.details.length - 1) {
                await this.props.history.push(`${Routes.DOCUMENTS}`);
            } else {
                const { hide } = cogoToast.success('Document verification status updated successfully.', {
                    onClick: () => {
                        hide();
                    },
                    position: "top-right",
                    hideAfter: 3
                });
                await this.setState({
                    selectedImage: this.state.selectedImage + 1,
                    verifyDoc: false,
                    showAlert: false,
                    alertMessage: ''
                });
            }
        }
        
        if (this.state.deleteDoc) {
            switch (this.props.role) {
                case Roles.SITE_SCANNER_RESOLVER:
                    // remove access to everyone
                    await this.props.updateDocumentAccess(this.props.token, 
                            this.props.details[this.state.selectedImage],
                            this.state.selectedImage, 
                            {employeeAccess : false, siteStaffAccess : false});
                    if (this.state.selectedImage === this.props.details.length - 1) {
                        await this.props.history.push(`${Routes.DOCUMENTS}`);
                    } else {
                        const { hide } = cogoToast.success('Document deleted successfully.', {
                            onClick: () => {
                                hide();
                            },
                            position: "top-right",
                            hideAfter: 3
                        });
                        await this.setState({
                            selectedImage: this.state.selectedImage + 1,
                            deleteDoc: false,
                            showAlert: false,
                            alertMessage: ''
                        });
                    }
                break;
                default:
                break;
            }
        }
    
        if (this.state.docAccess) {
            switch (this.props.role) {
                case Roles.SITE_SCANNER_RESOLVER:
                    // grant access to employees 
                    await this.props.updateDocumentAccess(this.props.token, 
                            this.props.details[this.state.selectedImage],
                            this.state.selectedImage,
                            {employeeAccess : this.state.docAccess === 'grant'});
                    if (this.state.selectedImage === this.props.details.length - 1) {
                        await this.props.history.push(`${Routes.DOCUMENTS}`);
                    } else {
                        const { hide } = cogoToast.success((this.state.docAccess === 'grant' ? 'Granted ' : 'Revoked') + 
                                            ' document access successfully.', {
                            onClick: () => {
                                hide();
                            },
                            position: "top-right",
                            hideAfter: 3
                        });
                        
                        await this.setState({
                            selectedImage: this.state.selectedImage + 1,
                            docAccess: null,
                            showAlert: false,
                            alertMessage: ''
                        });
                    }
                break;
                default:
                break;
            }
        }
    }

    canProceedToSdv = (document) => {
        // check for optional parameters which ccRA fills in.
        // other mandatory parameters are filled during doc creation
        //
        if (document && document.domainData && document.visit) {
            return true;
        }

        return false;
    }
    
    canRaiseQuery = (document) => {
        if (this.props.role === Roles.CCRA && 
            this.props.details && 
            document && document.isCCRAVerified === false) {
            return true;
        }
        
        return false;
    }

    canAcceptDocument = (document) => {
        if (this.props.role === Roles.CCRA && 
            this.props.details && 
            document && document.openQueryCount === 0 &&
            !document.isCCRAVerified) {
            return true;
        }

        return false;
    }
    
    canMakeCopy = (document) => {
        if (document && this.props.role === Roles.CCRA) {
            // check if study regulatory authority is EMA or FDA
            if (this.props.study.regulatoryAuthority === 'EMA') {
                return false;
            }
            
            // check if ccRA already created a copy of the document
            if (document.versionedDocument.findIndex(
                    version => (
                        version.uploadedBy.id === this.props.user.staffProfile.id && 
                        version.employeeAccess === true)) >= 0) {
            
                return false;
            }
            
            // check if ccRA has access to the site staff document version
            if (document.versionedDocument.findIndex(
                    version => 
                        (version.uploadedBy.staffType === 'SITE_STAFF' && 
                        version.employeeAccess === true)) >= 0) {
                return true;
            }
        }
        
        return false;
    }
    
    canDeleteDocument = (document) => {
        if (document && Roles.SITE_SCANNER_RESOLVER === this.props.role) {
            // check if there is atleast one version of the document uploaded by site staff
            // and site staff has access
            //
            let hasAccess = document.versionedDocument.findIndex(
                                version => 
                                    (version.uploadedBy.staffType === 'SITE_STAFF' && 
                                    version.siteStaffAccess === true)) >= 0;
            
            // check if document is in verified state.
            if (['SOURCE', 'ICF'].includes(document.docType) && document.isCCRAVerified) return hasAccess;
            if (document.docType === 'CRF' && document.deCompletedBy) return hasAccess;
        }
        return false;
    }
    
    canGrantAccess = (document) => {
        if (document && Roles.SITE_SCANNER_RESOLVER === this.props.role) {
            // check if there is atleast one version of the document uploaded by site staff
            // and employee has access
            //
            let hasNoAccess = document.versionedDocument.findIndex(
                                version => 
                                    (version.uploadedBy.staffType === 'SITE_STAFF' && 
                                     version.employeeAccess === false && version.siteStaffAccess === true)) >= 0;
            
            // check if document is in verified state.
            if (['SOURCE', 'ICF'].includes(document.docType) && document.isCCRAVerified) return hasNoAccess;
            if (document.docType === 'CRF' && document.deCompletedBy) return hasNoAccess;
        }
        return false;
    }
    
    canRevokeAccess = (document) => {
        if (document && Roles.SITE_SCANNER_RESOLVER === this.props.role) {
            // check if there is atleast one version of the document uploaded by site staff
            // and employee has access
            //
            let hasAccess = document.versionedDocument.findIndex(
                                version => 
                                    (version.uploadedBy.staffType === 'SITE_STAFF' && 
                                     version.employeeAccess === true && version.siteStaffAccess === true)) >= 0;
            
            // check if document is in verified state.
            if (['SOURCE', 'ICF'].includes(document.docType) && document.isCCRAVerified) return hasAccess;
            if (document.docType === 'CRF' && document.deCompletedBy) return hasAccess;
        }
        return false;
    }
    
    canDataEntryVerify = (document) => {
        switch (this.props.role) {
            case Roles.DE:
                if (this.props.dataEntryRole === 'DE1') {
                    return (document && !document.de1CompletedBy);
                } else if (this.props.dataEntryRole === 'DE2') {
                    return (document && !document.de2CompletedBy);
                }
                return false;
            case Roles.DM:
                return (document && 
                    document.de1CompletedBy && 
                    document.de2CompletedBy &&
                    !document.deCompletedBy);
            default:
                return false;
        }
    }

    editDocument = () => {
        this.setState({ edit: true })
    }
    
    getDocUrl = (document) => {
        
        let docUrl = noImageAccess;
        
        if (document && document.versionedDocument) {
            for (let i = 0; i < document.versionedDocument.length; ++i) { 
                // check if current user has access to the url
                if (this.props.user && this.props.user.staffProfile.staffType === 'EMPLOYEE' &&
                    document.versionedDocument[i].employeeAccess) {
                    docUrl = document.versionedDocument[i].url;
                }
                
                if (this.props.user && this.props.user.staffProfile.staffType === 'SITE_STAFF' &&
                    document.versionedDocument[i].siteStaffAccess) {
                    docUrl = document.versionedDocument[i].url;    
                }
            }
        }
        return docUrl;
    }

    render() {
        const { selectedImage, showAlert, alertMessage } = this.state;
        const { details, study, site } = this.props;
        const document = details && details[selectedImage];
        var docUrl = this.getDocUrl(document);
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.DOCUMENTS}`} >Documents</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Document Details</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <SweetAlert
                        show={showAlert}
                        style={{width : '480px'}}
                        title={this.state.confirmDialog ? 'Confirmation' : 'Warning'}
                        closeOnClickOutside={false}
                        allowEscape={false}
                        onConfirm={this.state.confirmDialog ? this.onConfirmClick : this.hideAlert}
                        onCancel={this.hideAlert}
                        confirmBtnBsStyle="primary btn-round btn-sm"
                        cancelBtnBsStyle="outline-primary btn-round btn-sm"
                        confirmBtnText={this.state.confirmDialog ? "Submit" : "Ok"}
                        cancelBtnText="Cancel"
                        showCancel={this.state.confirmDialog}
                    >
                        {alertMessage}
                    </SweetAlert>
                    <Modal
                        isOpen={this.state.documentPopup}
                        size="lg"
                        centered
                        toggle={async () => await this.setState({ documentPopup: false })}
                        backdrop="static"
                        keyboard={false}
                    >
                        <ModalHeader toggle={async () => await this.setState({ documentPopup: false })}>
                            Document Preview
                        </ModalHeader>
                        <ModalBody>
                            <RenderImage alt="." src={this.state.popupUrl} />
                        </ModalBody>
                    </Modal>
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '755px' }}>
                                <CardHeader>
                                    <Row>
                                        <Col sm={6} md={6} lg={10}>
                                            <CardTitle tag="h5" style={{ marginLeft: '10px' }}>DOC GROUP {document ? document.uploadGroup : ''}</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row style={{ marginLeft: '0px' }}>
                                        <Col xs={12} sm={4}>
                                            <FormGroup>
                                                <Label>Site Name</Label>
                                                <p>{site ? site.name : ''}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Study Name</Label>
                                                <p>{study ? study.name : ''}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Trial Participant ID</Label>
                                                <p>{document && document.subject ? document.subject.uniqueCode : ''}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Document Type</Label>
                                                <p>{document ? document.docType : ''}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Document Description</Label>
                                                <p>{document ? document.description : ''}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Domain Data</Label>
                                                <p>{document && document.domainData ? document.domainData : '--'}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Visit Name</Label>
                                                <p>{document && document.visit ? document.visit.name : '--'}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Page Status</Label>
                                                <p>{document && (document.isCCRAVerified === true) ? "Verified" : 'Submitted'}</p>
                                            </FormGroup>
                                            {
                                                [Roles.DM, Roles.DE, Roles.PM, Roles.CSM].includes(this.props.role) &&
                                                <>
                                                    <FormGroup>
                                                        <Label>Data Entry 1 Status</Label>
                                                        <p>{(document && !document.de1CompletedBy)? 'Pending' : 'Completed'}</p>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Data Entry 2 Status</Label>
                                                        <p>{(document && !document.de2CompletedBy)? 'Pending' : 'Completed'}</p>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Data Manager Status</Label>
                                                        <p>{(document && !document.deCompletedBy)? 'Pending' : 'Completed'}</p>
                                                    </FormGroup>
                                                </>
                                            }
                                            {
                                                (this.props.role === Roles.CCRA && document && document.isCCRAVerified === false) &&
                                                <Button color="primary" className="btn-round mr-3" size="sm" href={`${Routes.DOCUMENTS}/${this.props.match.params.id}/edit`}>
                                                    Edit Document
                                                </Button>
                                            }
                                            <Button color="primary" className="btn-round" outline size="sm" href={`${Routes.DOCUMENTS}`} >Back To Documents</Button>
                                            
                                        </Col>
                                        <Col xs={12} sm={8} >
                                            <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                <Col xs={9}>
                                                    <div>
                                                        <div className="img-fluid" style={{ width: '756px' }}>
                                                            <div style={{ cursor: 'pointer' }} onClick={async () => await this.setState({
                                                                documentPopup: true,
                                                                popupUrl: docUrl,
                                                            })}
                                                            >
                                                          
                                                                <div className="query-image" style={{width:'100%', height: 'auto', display:'block'}} >
                                                                    <RenderImage alt="." src={docUrl} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={3}>
                                                    <div style={{ height: '420px', overflowY: 'scroll' }}>
                                                        <ul style={{ listStyle: 'none', paddingInlineStart: '0px' }}>
                                                            {
                                                                details && details.map((val, i) => {
                                                                    const file = this.getDocUrl(val);
                                                                    return (
                                                                        <li key={i + 1}>
                                                                            <div className={`extra-document ${selectedImage === i ? 'selected' : ''} mx-auto`} onClick={() => this.onImageChange(i)} ><RenderImage alt='.' src={file} /></div>
                                                                        </li>
                                                                    );
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                            {
                                                this.props.details && this.canGrantAccess(document) &&
                                                <Col xs={12} sm={5} className="text-center">
                                                    <Button color="primary" outline className="btn-round btn-primary" 
                                                        size="sm" 
                                                        onClick={async e => {
                                                            await this.setState({ 
                                                                showAlert: true, 
                                                                docAccess : 'grant', 
                                                                confirmDialog : true,
                                                                alertMessage: 'Are you sure you want to grant access to the document ?' });
                                                        }}
                                                    >
                                                        Grant Access To Document
                                                    </Button>
                                                </Col>
                                                
                                            }
                                            {
                                                this.props.details && this.canRevokeAccess(document) &&
                                                <Col xs={12} sm={5} className="text-center">
                                                    <Button color="primary" outline className="btn-round btn-primary" 
                                                        size="sm" 
                                                        onClick={async e => {
                                                            await this.setState({ 
                                                                showAlert: true, 
                                                                docAccess : 'revoke',
                                                                confirmDialog : true,
                                                                alertMessage: 'Are you sure you want to revoke access to the document ?' });
                                                        }}
                                                    >
                                                        Revoke Access To Document
                                                    </Button>
                                                </Col>
                                                
                                            }
                                            {
                                                this.props.details && this.canDeleteDocument(document) &&
                                                <Col xs={12} sm={4} className="text-center">
                                                    <Button color="primary" className="btn-round btn-primary" 
                                                        size="sm" 
                                                        onClick={async e => {
                                                            await this.setState({ 
                                                                showAlert: true, 
                                                                deleteDoc : true, 
                                                                confirmDialog : true,
                                                                alertMessage: 'Are you sure you want to delete the document ?' });
                                                        }}
                                                    >
                                                        Delete Document
                                                    </Button>
                                                </Col>
                                                
                                            }
                                            {
                                                this.props.details && this.canAcceptDocument(document) &&
                                                <Col xs={12} sm={12} className="text-center mx-auto py-3">
                                                    <Button color="primary ml-3" className="btn-round btn-outline-primary" 
                                                        size="sm" 
                                                        onClick={async e => {
                                                            await this.setState({ 
                                                                showAlert: true, 
                                                                verifyDoc : true, 
                                                                confirmDialog : true,
                                                                alertMessage: 'Are you sure you want to mark the document verified ?' });
                                                        }}
                                                    >
                                                        { this.state.selectedImage < this.props.details.length - 1 ?
                                                            'Accept Image & Go To Next Page' :
                                                            'Accept Image'
                                                        }
                                                    </Button>
                                                </Col>
                                            }
                                             {
                                                this.props.details && this.canDataEntryVerify(document) &&
                                                <Col xs={12} sm={12} className="text-center mx-auto py-3">
                                                    <Button color="primary ml-3" className="btn-round btn-primary" 
                                                        size="sm" 
                                                        onClick={async e => {
                                                            await this.setState({ 
                                                                showAlert: true, 
                                                                verifyDoc : true, 
                                                                confirmDialog : true,
                                                                alertMessage: 'Are you sure data entry is completed for the document ?' });
                                                        }}
                                                    >
                                                        { this.state.selectedImage < this.props.details.length - 1 ?
                                                            'Confirm Data Entry & Go To Next Page' :
                                                            'Confirm Data Entry'
                                                        }
                                                    </Button>
                                                </Col>
                                            }
                                                <Col className="text-center mx-auto">
                                                {
                                                    this.props.details && this.canRaiseQuery(document) &&
                                                    <Button color="primary ml-3" className="btn-round btn-title" size="sm" href={`${Routes.DOCUMENTS}/${this.props.match.params.id}/${selectedImage}/raiseImg`}>
                                                        Create Image Query
                                                    </Button>
                                                }
                                                {
                                                    this.props.details && this.canRaiseQuery(document) &&
                                                    <Button color="primary ml-3" className="btn-round btn-title" size="sm" href={`${Routes.DOCUMENTS}/${this.props.match.params.id}/${selectedImage}/raiseMeta`}>
                                                        Create Meta Data Query
                                                    </Button>
                                                }
                                                {
                                                    this.props.details && this.canRaiseQuery(document) &&
                                                    <Button color="primary ml-3" 
                                                        hidden={document && ['SOURCE', 'ICF'].includes(document.docType) ? true : false} 
                                                        className="btn-round btn-title" size="sm"
                                                        onClick={async e => {
                                                            if (this.canProceedToSdv(document)) {
                                                                await this.props.history.push(`conductSdv/${details[selectedImage]._id}`);
                                                            } else {
                                                                await this.setState({ 
                                                                    showAlert: true, 
                                                                    confirmDialog : false,
                                                                    alertMessage: 'Please assign Domain data and visit before proceeding to SDV' });
                                                            }
                                                        }}
                                                    >
                                                        Proceed To SDV
                                                    </Button>
                                                }
                                                {
                                                    this.props.details && this.canMakeCopy(document) &&
                                                    <Button 
                                                        href={details && details.length ? `${Routes.DOCUMENTS}/${details[selectedImage]._id}/certify` : ''}
                                                        color="primary ml-3" 
                                                        className="btn-round btn-title" 
                                                        size="sm" >
                                                        Update &amp; Certify
                                                    </Button>
                                                }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout >
        )
    }
}

const mapStateToProps = ({ user, documents, studies, sites }) => {
    // determine data entry role based on the selected study
    let entry = studies.myStudies && studies.current ? 
                studies.myStudies.find( study => study.clinicalStudy.id === studies.current.id) : null;
    return {
        token: user.token,
        user : user,
        role: user.role ? user.role.name : undefined,
        details: documents.details,
        study: studies.current,
        site: sites.current,
        dataEntryRole : entry ? entry.dataEntryRole : null
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDocumentDetails: (token, id) => DocumentActions.getDocumentDetails(dispatch, token, id),
        setDocumentVerified: (token, docId, index, data) => DocumentActions.setDocumentVerified(dispatch, token, docId, index, data),
        updateDocumentAccess: (token, document, index, data) => DocumentActions.updateDocumentAccess(dispatch, token, document, index, data)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetails);
