import React from 'react';
import { Row, Col } from 'reactstrap';
import { RenderImage } from 'components';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';


const ImageQuery = (props) => {

    const [docUrl, setDocUrl] = React.useState(null);
    
    const data = props.query;
    
    const latestResponse = (props.responseHistory && props.responseHistory.length > 0) ?
                            props.responseHistory[0] : null;
    const latestDocument =  latestResponse && props.responseDocument ? 
                                props.getDocUrl({versionedDocument : props.responseDocument}, 0) : null;
    const monitorDocument = data.monitorDocument ? props.getDocUrl(data.monitorDocument, data.docVersion) : null;
    return (
        <Row className="text-center mx-auto">
            <Modal
                isOpen={docUrl !== null}
                size="lg"
                centered
                toggle={() => setDocUrl(null)}
                backdrop="static"
                keyboard={false}
            >
                <ModalHeader toggle={() => setDocUrl(null)}>
                    Document Preview
                </ModalHeader>
                <ModalBody>
                {   
                    docUrl &&
                    <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                       <RenderImage alt="CRF Document" className="mx-auto"  src={docUrl} />
                    </div>
                }
                </ModalBody>
            </Modal>
            {
                latestDocument &&
                <Col xs={12} sm={6} className="text-center"  style={{cursor : 'pointer'}} onClick= { e => setDocUrl(latestDocument)}>
                    <h6>Corrected Document</h6>
                    <RenderImage className="query-image mx-auto"
                        alt="Corrected Document"
                        src={latestDocument}
                    />
                </Col>
            }
            <Col xs={12} sm={6} className="text-center" style={{cursor : 'pointer'}} onClick= { e => setDocUrl(monitorDocument)}>
                <h6>Actual Document</h6>
                <RenderImage className="query-image mx-auto"
                    alt="Actual Document"
                    src={monitorDocument}
                />
            </Col>
            
        </Row>
    );
}
    
export default ImageQuery;