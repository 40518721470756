import React from 'react';
import {
    Label,
    Row,
    Col,
    Table,
    Button,
} from 'reactstrap';
import { Routes } from 'constants/index.js';

export const StudyDocument = (props) => {
    return (
        <>
            <Row sm={12} md={12} lg={12} style={{ justifyContent: 'space-between' }} >
                <Label tag="p" className="info-title" >Documents Uploaded</Label>
                <Button color="primary" href={`${Routes.STUDYINFORMATION}/document`} className="btn-round ml-5" size="sm">
                    Upload Document
                </Button>
            </Row>
            <Row>
                <Col>
                    <Table responsive>
                        <thead className="info-title">
                            <tr>
                                <th style={{ width: '25%' }}>Document Name</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {
                                props.documents && props.documents.map((val, index) => {
                                    return (
                                        <tr key={val.id}>
                                            <td>{val.name}</td>
                                            <td>{val.description}</td>
                                            <td className="more text-center" />
                                        </tr>
                                    );
                                })
                            } */}
                            <tr>
                                <td>History of Past ilness</td>
                                <td>
                                    psum dolor sit amet,consecture adipiscing elit,sed do eiusmod tempor. incididum ut labore et
                                    dolore magna aliqua.Ut enim ad.
                                </td>
                                <td className="more text-center" />
                            </tr>
                            <tr>
                                <td>Guidlines for Diagnosis</td>
                                <td>
                                    psum dolor sit amet,consectrture adipiscing elit,sed.
                                </td>
                                <td className="more text-center" />
                            </tr>
                            <tr>
                                <td>History of Past ilness</td>
                                <td>psum dolor sit amet,consecture adipiscing elit,sed do eiusmod tempor.</td>
                                <td className="more text-center" />
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
export default StudyDocument;
