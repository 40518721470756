import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Button
} from "reactstrap";
// reactstrap validations
import { AvForm, AvField } from 'availity-reactstrap-validation';

import BasicImageRedactor from './BasicImageRedactor';

import { DocTypes } from "constants/index.js";

function UploadDocument(props) {

    const [selectedImage, setSelectedImage] = useState(0);
    
    const handleValidSubmit = (event, values) => {
        const subject = props.subjects.filter(val => val._id === values.subject)[0];
        values['subject'] = subject;
        values['site'] = props.site;
        values['study'] = props.study.clinicalStudy;
        props.onDocumentDataCollected(values);
    }     
    return (
        <Card style={{ minHeight: '726px' }}>
            <CardHeader>
                <CardTitle tag="h5">Upload New Document</CardTitle>
            </CardHeader>
            <CardBody>
                <AvForm onValidSubmit={handleValidSubmit}>
                    <Row style={{ marginLeft: '0px' }}>
                        <Col xs={6} md={12} lg={3}>
                            <AvField name="site" autoComplete="off" value={props.site ? props.site.name : ''} placeholder="Site Name" label="Site Name" type="text" disabled />
                            <AvField name="protocol" autoComplete="off" value={props.study ? props.study.name : ''} placeholder="Protocol" label="Study Name" type="text" disabled />
                            <AvField className="minimal" type="select" value={props.document && props.document.docType} name="docType" label="Document Type" errorMessage='Please select a type.'
                                required
                                onChange={(e) => { console.log(e.target.value); props.onDocTypeChange(e.target.value) }} >
                                <option value="">Select</option>
                                {
                                    DocTypes.map((val, index) => {
                                        return (
                                            <option key={val.value} value={val.value}>{val.label}</option>
                                        )
                                    })
                                }
                            </AvField>
                            <AvField className="minimal" type="select" value={props.document && props.document.subject ? props.document.subject._id : undefined} name="subject" label="Trial Participant ID" errorMessage='Please select a trail participant.'
                                required
                                onChange={(e) => {
                                    const subjectId = e.target.value;
                                    const subject = props.subjects.filter(val => val._id === subjectId)[0];
                                    props.onSubjectChange(subject)
                                }}>
                                <option value="">Select</option>
                                {
                                    props.subjects && props.subjects.map(val =>
                                        <option key={val._id} value={val._id}>{val.uniqueCode}</option>
                                    )
                                }
                            </AvField>
                            <AvField name="description" autoComplete="off"
                                required
                                value={props.document && props.document.description}
                                label="Description of Uploaded Image"
                                type="textarea"
                                errorMessage='Please enter document description.'
                                onChange={(e) => props.onDescriptionChange(e.target.value)}
                                validate={{
									minLength: { value: 6, errorMessage: 'Query details must be at least 6 characters' },
                                    maxLength: { value: 500, errorMessage: 'Description must not be more than 500 characters.'}
                                }}/>
                        </Col>
                        <Col xs={12} md={12} lg={9}>
                            <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                                <Col xs={10}>
                                    <Row className="mx-auto">
                                        {
                                            <BasicImageRedactor 
                                                editMode = {props.editMode}
                                                closeRedactWindow = {props.closeRedactWindow}
                                                url = {props.files ? props.files[selectedImage].previewUrl : null}
                                                saveRedactedDocument = { async (image) => await props.saveRedactedDocument(image, selectedImage)} />
                                        }
                                        <div className="query-image">
                                            <img
                                                alt="." width={'600px'} height={'400px'} style={{ borderRadius: '8px' }}
                                                src={props.files ? props.files[selectedImage].previewUrl : null}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col className="text-center">
                                            <Button className="btn-round" outline color="primary" size="sm" onClick={props.onEditClick}>
                                                <i className="fa fa-pen mr-2" aria-hidden="true" style={{}}></i>
                                            Edit/Redact
                                        </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-center" style={{ marginTop: '10px' }}>
                                            <Button color="primary" className="btn-round" size="sm" type="submit" >Upload</Button>
                                            <Button color="primary" className="btn-round ml-4" outline size="sm" type="button" onClick={props.onCancel} >Cancel</Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                    <div style={{ height: '320px', overflowY: 'scroll' }}>
                                        <ul style={{ listStyle: 'none', paddingInlineStart: '0px' }}>
                                            {
                                                props.files && props.files.map((file, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <div className="mx-auto" style={{width:'66px', height:'66px', position:'relative'}}>
                                                                <i class="fa fa-trash" aria-hidden="true" style={{ position: 'absolute', top: '30px', right: '-15px', cursor: 'pointer', zIndex:2 }} 
                                                                  onClick={async () => { 
                                                                    if (i === selectedImage) 
                                                                        setSelectedImage(0); 
                                                                        props.onImageDelete(i) 
                                                                    }}>
                                                                </i>
                                                                <div className={`extra-document ${selectedImage === i ? 'selected' : ''} mx-auto`}
                                                                    onClick={() => {
                                                                        setSelectedImage(i)
                                                                    }}>
                                                                    <img alt='.' src={file.previewUrl} style={{ borderRadius: '6px' }} />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className='extra-document mx-auto border border-primary' style={{ marginTop: '15px' }}>
                                        <Button className='btn-neutral' style={{ padding: '0px', margin: '7px' }} onClick={props.onAddMorePagesClick} >Add More Pages</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </AvForm>
            </CardBody>
        </Card>
    );
};

export default UploadDocument;
