import React from "react";
import { connect } from 'react-redux';

// react plugin used to create switch buttons
import DashboardLayout from "layouts/Admin"

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Label,
} from "reactstrap";
import Chart from "react-google-charts";
import "react-circular-progressbar/dist/styles.css";
// core components
import { PanelHeader } from "components";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import documentsIcon from "assets/img/documents.svg"
import queryIcon from "assets/img/query.svg"
import subjectsIcon from "assets/img/subject.svg"
import { DashboardActions } from "actions";
import { Routes } from "constants/index";
import { RemoteTable } from "components";
import { icfTableColumns, queryTableColumns } from './TableColumns';
import { PAGE_SIZE } from "api/list";

const options = {
    legend: "none",
    toolTip: 'none',
    chartArea: {
        left: 30,
        right: 20,
        bottom: 20,
        top: 20
    },
    height: 250,
    tooltip: {
        trigger: 'none'
    },
    bar: {
        groupWidth: 36
    }
};

class SiteScannerResolverDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = { icfPage : 1, queryPage : 1 }
    }

    componentDidMount() {
        const { token, site } = this.props;
        if (this.props && site && site._id) {
            console.log(site._id);
            this.props.getMyMetrics(token, site._id);
            this.props.getSiteStaffPendingReviewQueriesCount(token, site._id);
            this.props.getPendingICFReviewDocsCount(token, site._id);
            this.props.getSiteStaffPendingReviewQueries(token, site._id, 0, PAGE_SIZE);
            this.props.getPendingICFReviewDocs(token, site._id, 0, PAGE_SIZE);
        }
    }

    onQueryClick = (event, row, rowIndex) => {
        this.props.history.push(`${Routes.QUERIES}/${row._id}`)
    }

    onDocumentClick = (event, row, rowIndex) => {
        this.props.history.push(`${Routes.DOCUMENTS}/${row.uploadGroup}`)
    }
    
    handleTableChange = (type, paging) => {
        let start = (paging.page - 1) * paging.sizePerPage;
        if (type === 'icf') {
            this.props.getPendingICFReviewDocs(this.props.token, this.props.site._id, start, paging.sizePerPage);
            this.setState({ icfPage : paging.page });
        } else if (type === 'query') {
            this.props.getSiteStaffPendingReviewQueries(this.props.token, this.props.site._id, start, paging.sizePerPage);
            this.setState({ queryPage : paging.page });
        }
    }

    render() {
        const { subjectMetrics, documentMetrics, queryMetrics } = this.props.data ? this.props.data : {};
        const { pendingICFDocs, pendingReviewQueries, pendingICFDocsCount, pendingReviewQueriesCount } = this.props
        return (
            <DashboardLayout>
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col lg="4" sm="6" tag="a" href={Routes.DOCUMENTS}>
                            <Card className="card-stats">
                                <CardBody className="dashboard-card bg-blue">
                                    <div className="statistics statistics-horizontal">
                                        <div className="info info-horizontal">
                                            <Row className="d-flex align-items-center">
                                                <Col className="text-left" xs="9">
                                                    <h1 className="dashboard-card-title">{documentMetrics ? documentMetrics.totalUploaded : 0}</h1>
                                                    <Label className="dashboard-card-subtitle">Total Documents</Label>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="icon icon-circle bg-blue-light" style={{ boxShadow: 'none' }}>
                                                        <img alt="..." src={documentsIcon} style={{ margin: '22px' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" sm="6" tag="a" href={Routes.QUERIES}>
                            <Card className="card-stats">
                                <CardBody className="dashboard-card bg-green">
                                    <div className="statistics statistics-horizontal">
                                        <div className="info info-horizontal">
                                            <Row className="d-flex align-items-center">
                                                <Col className="text-left" xs="9">
                                                    <h1 className="dashboard-card-title">{queryMetrics ? queryMetrics.totalQueries : 0}</h1>
                                                    <Label className="dashboard-card-subtitle">Total Queries</Label>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="icon icon-circle bg-green-light" style={{ boxShadow: 'none' }}>
                                                        <img alt="..." src={queryIcon} style={{ margin: '20px' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" sm="6" tag="a">
                            <Card className="card-stats">
                                <CardBody className="dashboard-card bg-pink">
                                    <div className="statistics statistics-horizontal">
                                        <div className="info info-horizontal">
                                            <Row className="d-flex align-items-center">
                                                <Col className="text-left" xs="9">
                                                    <h1 className="dashboard-card-title">{subjectMetrics ? subjectMetrics.totalSubjects : 0}</h1>
                                                    <Label className="dashboard-card-subtitle">Total Trial Participants</Label>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="icon icon-circle bg-pink-light" style={{ boxShadow: 'none' }}>
                                                        <img alt="..." src={subjectsIcon} style={{ margin: '22px' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6" xs="12">
                            <Card style={{ maxHeight: '334px', minHeight: '334px' }}>
                                <CardHeader>
                                    <CardTitle className="graph-card-title">Outstanding Queries Based on Query Type</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Chart
                                        chartType="ColumnChart"
                                        options={options}
                                        data={[
                                            ["Element", "Density", { role: "style" }],
                                            ["SDV Queries", queryMetrics ? queryMetrics.totalSDVQueries : 0, "#F15A67"], // RGB value
                                            ["Meta Data", queryMetrics ? queryMetrics.totalMetaDataQueries : 0, "#F9A50F"], // English color name
                                            ["Source Data", queryMetrics ? queryMetrics.totalSourceDataQueries : 0, "#31ABB9"],
                                            ["Image Queries", queryMetrics ? queryMetrics.totalImageQueries : 0, "color: #BFCE65"], // CSS-style declaration
                                            ["EDC Queries", queryMetrics ? queryMetrics.totalEDCQueries : 0, "#FF7F50"]
                                        ]}
                                        legendToggle
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6" xs="12">
                            <Card style={{}}>
                                <CardHeader>
                                    <CardTitle className="graph-card-title">Queries Resolved</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className='mx-auto' style={{ width: "200px" }}>
                                        <CircularProgressbarWithChildren
                                            value={Math.round(queryMetrics && queryMetrics.totalQueries > 0 ? (queryMetrics.totalQueriesResolved / queryMetrics.totalQueries) * 100 : 0)}
                                            strokeWidth={5}
                                            styles={buildStyles({
                                                strokeLinecap: 'butt',
                                                textColor: 'black',
                                                pathColor: `#42B5A9`,
                                                trailColor: 'rgba(66, 181, 169, .2)',
                                            })} >
                                            <div>{Math.round(queryMetrics && queryMetrics.totalQueries > 0 ? (queryMetrics.totalQueriesResolved / queryMetrics.totalQueries) * 100 : 0)}%</div>
                                            <div>Queries</div>
                                            <div>Resolved</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                    <Row>
                                        <Col className='text-center'>
                                            <div className='circular-graph-legend mx-auto' style={{ backgroundColor: "rgba(66, 181, 169, .2)" }}></div>
                                            <div>Queries Raised</div>
                                            <div >{queryMetrics ? queryMetrics.totalQueries : 0}</div>
                                        </Col>
                                        <Col className='text-center'>
                                            <div className='circular-graph-legend mx-auto bg-primary'></div>
                                            <div>Queries Resolved</div>
                                            <div >{queryMetrics ? queryMetrics.totalQueriesResolved : 0}</div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" sm="12">
                            <Card style={{ minHeight: '80px' }}>
                                <CardHeader>
                                    <CardTitle className="graph-card-title">Pending ICF Review Documents</CardTitle>
                                </CardHeader>
                                <CardBody>
                                     <RemoteTable
                                        columns={icfTableColumns((this.state.icfPage -1) * PAGE_SIZE)}
                                        onTableChange={(type, paging) => this.handleTableChange('icf', paging)}
                                        data={pendingICFDocs}
                                        onItemClick={this.onDocumentClick}
                                        page={this.state.icfPage}
                                        sizePerPage={PAGE_SIZE}
                                        totalSize={pendingICFDocsCount}
                                        
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6" sm="12">
                            <Card style={{ minHeight: '80px' }}>
                                <CardHeader>
                                    <CardTitle className="graph-card-title">Queries Waiting For My Review</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <RemoteTable
                                        columns={queryTableColumns((this.state.queryPage -1) * PAGE_SIZE)}
                                        onTableChange={(type, paging) => this.handleTableChange('query', paging)}
                                        data={pendingReviewQueries}
                                        onItemClick={this.onQueryClick}
                                        page={this.state.queryPage}
                                        sizePerPage={PAGE_SIZE}
                                        totalSize={pendingReviewQueriesCount}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}


const mapStateToProps = ({ user, dashboard, studies, sites }) => {
    return {
        token: user.token,
        data: dashboard.metrics,
        pendingICFDocs: dashboard.pendingICFDocs || [],
        pendingReviewQueries: dashboard.pendingReviewQueries || [],
        pendingICFDocsCount: dashboard.pendingICFDocsCount,
        pendingReviewQueriesCount: dashboard.pendingReviewQueriesCount,
        study: studies.current,
        site: sites.current
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMyMetrics: (token, siteId) => DashboardActions.getSSRMetrics(dispatch, token, siteId),
        getPendingICFReviewDocsCount: (token, siteId) => DashboardActions.getPendingICFReviewDocsCount(dispatch, token, siteId),
        getSiteStaffPendingReviewQueriesCount: (token, siteId) => DashboardActions.getSiteStaffPendingReviewQueriesCount(dispatch, token, siteId),
        getPendingICFReviewDocs: (token, siteId, start, limit) => DashboardActions.getPendingICFReviewDocs(dispatch, token, siteId, start, limit),
        getSiteStaffPendingReviewQueries: (token, siteId, start, limit) => DashboardActions.getSiteStaffPendingReviewQueries(dispatch, token, siteId, start, limit),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteScannerResolverDashboard);