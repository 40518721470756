import React from "react";
import * as moment from 'moment';
import { DATE_FORMAT } from "constants/index";
import { Badge, Button } from "reactstrap";
import { RenderImage } from 'components';
import { Routes } from "constants/index.js";


export const icfTableColumns = (start) => {
    return [
        {
            dataField: 'id',
            text: 'S.No',
            formatter: (value, row, index, field) => {
                return start + index + 1;
            }
        },
        {
            dataField: 'subject.uniqueCode',
            text: 'Trial Participant ID',
        },
        {
            dataField: 'createdAt',
            text: 'Submitted Date',
            formatter: (value, row, index, field) => {
                return value ? moment(value).format(DATE_FORMAT) : ''
            }
        }, 
    ];        
}

export const queryTableColumns = (start) => {
    return [
        {
            dataField: 'id',
            text: 'S.No',
            formatter: (value, row, index, field) => {
                return start + index + 1;
            }
        },
        {
            dataField: 'queryType',
            text: 'Query Type',
        },
        {
            dataField: 'createdAt',
            text: 'Query Raised Date',
            formatter: (value, row, index, field) => {
                return value ? moment(value).format(DATE_FORMAT) : ''
            }
        },
        {
            dataField: 'queryStatus',
            text: 'Query Status',
        }, 
    ]           
};

export const dataPendingColumns = (start) => {
    return [
        {
            dataField: 'id',
            text: 'S.No',
            formatter: (value, row, index, field) => {
                return start + index + 1;
            }
        },
        {
            dataField: 'subject.uniqueCode',
            text: 'Trial Participant ID',
        },
        {
            dataField: 'de1CompletedBy',
            text: 'DE1 Status',
            formatter: (value, row, index, field) => {
                return (value === row.numberOfDocuments ? 
                    <Badge className="submitted-badge">Completed</Badge> : 
                    <Badge className="verified-badge">Pending</Badge>)
            
            }
        },
        {
            dataField: 'de2CompletedBy',
            text: 'DE2 Status',
            formatter: (value, row, index, field) => {
                return (value === row.numberOfDocuments ? 
                    <Badge className="submitted-badge">Completed</Badge> : 
                    <Badge className="verified-badge">Pending</Badge>)
            
            }
        },
        {
            dataField: 'deCompletedBy',
            text: 'Overall DE Status',
            formatter: (value, row, index, field) => {
                return (value === row.numberOfDocuments ? 
                    <Badge className="submitted-badge">Completed</Badge> : 
                    <Badge className="verified-badge">Pending</Badge>)
            
            }
        },
    ];
}

export const crfVerifiedTableColumns = (start) => {
    return [
        {
            dataField: 'id',
            text: 'S.No',
            formatter: (value, row, index, field) => {
                return start + index + 1;
            }
        },
        {
            dataField: 'subject.uniqueCode',
            text: 'Trial Participant ID',
        },
        {
            dataField: 'domainData',
            text: 'Domain Data',
        },
         {
            dataField: 'numberOfDocuments',
            text: 'No. Of Pages',
        },
        
    ]
}

export const sponsorTableColumns =  (start) => {
    return [
        {
            dataField: 'id',
            text: 'S.No',
            formatter: (value, row, index, field) => {
                return start + index + 1;
            }
        },
        {
            dataField: 'name',
            text: `Sponsor's Name`,
        },
        {
            dataField: 'logo',
            text: 'Logo',
            formatter: (value, row, index, field) => {
                return (value ?
                    <RenderImage
                        alt="..." style={{ maxHeight: '36px' }}
                        src={value}
                    /> : '');
            }
        },   
    ];
}

export const studyTableColumns = (start, action, history) => {
    return [
        {
            dataField: 'id',
            text: 'S.No',
            formatter: (value, row, index, field) => {
                return (<div onClick = {() => history.push(`${Routes.STUDIES}/${row._id}`)}>
                    <p>{start + index + 1}</p>
                </div>);
            }
        },
        {
            dataField: 'name',
            text : 'Study Name',
            formatter: (value, row, index, field) => {
                return (<div onClick = {() => history.push(`${Routes.STUDIES}/${row._id}`)}>
                    <p>{value}</p>
                </div>);
            }
        },
        {
            dataField: 'isActive',
            text: 'Status',
            formatter: (value, row, index, field) => {
                return (<div onClick = {() => history.push(`${Routes.STUDIES}/${row._id}`)}>
                    <Badge className={value ? "active-badge" : "inactive-badge"}>{value ? 'Active' : 'Inactive'}</Badge>
                </div>);
            }
        },
        {
            dataField: 'isActive',
            text : 'Action',
            formatter: (value, row, index, field) => {
                return (<Button className="btn-neutral" onClick={() => action(!value, row._id)}>{value ? "Deactivate" : "Activate"}</Button>);
            }
            
        }
    ]
}
