import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
    Table,
    NavLink,
    NavItem,
    Nav,
    TabContent,
    TabPane,
} from "reactstrap";
import Select from "react-select";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";
import StudyDocument from "./StudyDocument";
import MonitoringPlan from './MonitoringPlan';
import MonitoringReports from './MonitoringReports';
import CRFPage from './CRFPage';
import { RiskParameter } from "./RiskParameter";
// import SweetAlert from 'react-bootstrap-sweetalert';
import { Routes } from 'constants/index.js';
import 'react-confirm-alert/src/react-confirm-alert.css';

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

var selectOptions = [
    { value: "study related", label: "Study Related" },
    { value: "site specific", label: "Site Specific" },
];
export default class StudyInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "ht1",
            change: true,
            popup: false,
            alert: true,
            disabled: false,
            activePage: 1,
            itemsPerPage: 5,
            item: '',
            startIndex: 0,
            endIndex: 5,
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.monitorPlan = this.monitorPlan.bind(this);
        this.onAlert = this.onAlert.bind(this);
    }

    handlePageChange = (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
    }
    onAlert = () => {
        return {
            alert: (
                <SweetAlert
                    warning
                    title="Alert"
                    onConfirm={this.hideAlert}
                >
                    <div>
                        <p>Submit Change</p>
                        <Label>Are you sure you want to submit the change?</Label>
                        <Row>
                            <Button color="primary" className="btn-round " size="sm" >Submit</Button>
                            <Button color="primary" className="btn-round rbc-show-more rbc-off-range border" size="sm" style={{ marginLeft: '20px' }} >Cancel</Button>
                        </Row>
                    </div>
                </SweetAlert>
            )
        }

        // confirmAlert({
        //     title: 'Submit Change',
        //     message: 'Are you sure you want to submit the changes?  .',
        //     className: "btn-round rbc-show-more rbc-off-range border",
        //     buttons: [
        //         {
        //             className: 'btn-round rbc-show-more rbc-off-range border',
        //             label: 'Submit',
        //         },
        //         {
        //             className: 'btn-round rbc-show-more rbc-off-range border',
        //             label: 'Cancel',
        //         }
        //     ]
        // }
        // );
    }
    onChange() {
        this.setState({ change: false })
    }
    onSubmit() {
        this.setState({ popup: true })
    }
    hideAlert() {
        this.setState({ active: "ht3" })
    }
    monitorPlan() {
        this.setState({ active: "ht3" })
    }
    render() {
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={Routes.DASHBOARD}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Study Protocol</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col >
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h6">Study Information</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Label>Site Name</Label>
                                                <p>University Of Virginia</p>
                                            </Col>
                                            <Col>
                                                <Label>Study Name</Label>
                                                <p>Captopril Prevention Project</p>
                                            </Col>
                                            <Col>
                                                <Label>Address</Label>
                                                <p>Charlottesville,VA,United States</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label>Contact Person</Label>
                                                <p>Daniel Chapple</p>
                                            </Col>
                                            <Col>
                                                <Label>Designation</Label>
                                                <p>Project Manager</p>
                                            </Col>
                                            <Col>
                                                <Label>Contact Number</Label>
                                                <p>+1 404 795 7684</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label>No. of Trial Participants</Label>
                                                <p>12</p>
                                            </Col>
                                            <Col>
                                                <Label>Site Admin</Label>
                                                <p>Holly Jackson</p>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Nav pills className="nav-pills-primary" style={{ padding: '2px', fontSize: '8px', marginLeft: '-15px' }} sm={12} lg={12} md={12}>
                                            <Row>
                                                <NavItem >
                                                    <NavLink
                                                        className={this.state.activeTab === "ht1" ? "active" : ""}
                                                        onClick={() => this.setState({ activeTab: "ht1" })}
                                                    >
                                                        Documents
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem >
                                                    <NavLink
                                                        className={this.state.activeTab === "ht2" ? "active" : ""}
                                                        onClick={() => this.setState({ activeTab: "ht2" })}
                                                    >
                                                        Mark Trial Participants
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem >
                                                    <NavLink
                                                        className={this.state.activeTab === "ht3" ? "active" : ""}
                                                        onClick={() => this.setState({ activeTab: "ht3" })}
                                                    >
                                                        Monitoring Plan
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem >
                                                    <NavLink
                                                        className={this.state.activeTab === "ht4" ? "active" : ""}
                                                        onClick={() => this.setState({ activeTab: "ht4" })}
                                                    >
                                                        Monitoring Reports
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem >
                                                    <NavLink
                                                        className={this.state.activeTab === "ht5" ? "active" : ""}
                                                        onClick={() => this.setState({ activeTab: "ht5" })}
                                                    >
                                                        Customise CRF Pages
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem >
                                                    <NavLink
                                                        className={this.state.activeTab === "ht6" ? "active" : ""}
                                                        onClick={() => this.setState({ activeTab: "ht6" })}
                                                    >
                                                        Risk Parameters
                                                    </NavLink>
                                                </NavItem>
                                            </Row>
                                        </Nav>
                                        <Col>
                                            <TabContent
                                                activeTab={this.state.activeTab} style={{ border: '2px' }}
                                                className="tab-space"
                                            >
                                                <TabPane tabId="ht1">
                                                    <Col sm={3} className="pull-right ml-5">
                                                        <Select
                                                            className="react-select "
                                                            classNamePrefix="react-select"
                                                            placeholder="Filter "
                                                            options={selectOptions}
                                                        />
                                                    </Col>
                                                    <StudyDocument id={this.props.match.params.id} documents={[{ name: 'History of past illness', description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and, making it look like readable English.' }]} />
                                                </TabPane>
                                                <TabPane tabId="ht2">
                                                    <Row>
                                                        <Col xs={8} md={9} lg={10} >
                                                            <Label tag="p" className="info-title" style={{ marginTop: '15px' }}>Mark Trial Participants</Label>
                                                        </Col>
                                                        <Col xs={4} md={3} lg={2} className="text-right">
                                                            {this.state.change ?
                                                                <Button color="primary" style={{ minWidth: '132px' }} className="btn-round" size="sm" onClick={this.onChange}>
                                                                    Change
                                                             </Button> :
                                                                <Button color="primary" style={{ minWidth: '132px' }} className="btn-round" size="sm" onClick={this.onAlert}>
                                                                    Submit
                                                             </Button>}
                                                        </Col>
                                                    </Row>
                                                    <Col xs={3} md={3} lg={4}>
                                                        <Table responsive hover >
                                                            <thead className="info-title">
                                                                <tr>
                                                                    <th>Petient ID</th>
                                                                    <th>SDV?</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>CA0001</td>
                                                                    <td><input type="radio" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>CA0002</td>
                                                                    <td><input type="radio" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>CA0003</td>
                                                                    <td><input type="radio" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>CA0004</td>
                                                                    <td><input type="radio" /></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </TabPane>
                                                <TabPane tabId="ht3">
                                                    <Row>
                                                        <Col xs={8} md={9} lg={8} >
                                                            <Label tag="p" className="info-title" style={{ marginTop: '15px' }}>Monitoring Plan</Label>
                                                        </Col>
                                                        <Col xs={4} md={3} lg={2} className="text-right">
                                                            {this.state.change ?
                                                                <Button color="primary" style={{ minWidth: '132px' }} className="btn-round" size="sm" onClick={this.onChange}>
                                                                    Change Plan
                                                            </Button> :
                                                                <Button color="primary" style={{ minWidth: '132px' }} className="btn-round" size="sm" onClick={this.onAlert}>
                                                                    Submit Plan
                                                            </Button>}
                                                        </Col>
                                                    </Row>
                                                    <MonitoringPlan onDataChange={this.onMonitorDataChange} />
                                                </TabPane>
                                                <TabPane tabId="ht4">
                                                    <MonitoringReports />
                                                </TabPane>
                                                <TabPane tabId="ht5">
                                                    <Row>
                                                        <CRFPage />
                                                    </Row>

                                                </TabPane>
                                                <TabPane tabId="ht6">
                                                    <Row>
                                                        <Col>
                                                            <RiskParameter />
                                                        </Col>
                                                        <Col xs={4} md={3} lg={2} className="text-right">
                                                            {this.state.change ?
                                                                <Button color="primary" style={{ minWidth: '132px' }} className="btn-round mr-3" size="sm" onClick={this.onChange}>
                                                                    Change Plan
                                                            </Button> :
                                                                <Button color="primary" style={{ minWidth: '128px' }} className="btn-round mr-3" size="sm" onClick={this.onAlert}>
                                                                    Submit
                                                            </Button>}
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        )
    }
}
