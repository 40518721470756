import React from "react";
import { connect } from 'react-redux';
import {
    Col, Breadcrumb, BreadcrumbItem,
    CardHeader, CardTitle, Card, Row,
    CardBody,
} from 'reactstrap';
import { Routes } from "constants/index.js";
import PanelHeader from "components/PanelHeader/PanelHeader";
import DashboardLayout from "layouts/Admin";

import { MetricCard } from "./Components";
import { SiteActions, DashboardActions } from "actions";

class DEMetrics extends React.Component {
    constructor() {
        super();
        this.state = {
        }
    }

    componentDidMount() {
        const { token, site } = this.props;
        if (site && site._id) {
            this.props.getMyMetrics(token, site._id);
        }
    }

    render() {
        const { data } = this.props;

        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={Routes.DASHBOARD}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Metrics</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Card style={{minHeight:'700px'}}>
                        <CardHeader>
                            <CardTitle tag="h5">Metrics</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={4}>
                                    <MetricCard label={"DE1 Completed"} totalLabel={'Pages Uploaded'} actualLabel={'DE1 Completed'} measure={'DE 1 Completed'} total={data && data.documentMetrics.totalUploaded} actual={data && data.documentMetrics.totalDE1Completed} />
                                </Col>
                                <Col xs={4}>
                                    <MetricCard label={"DE2 Completed"} totalLabel={'Pages Uploaded'} actualLabel={'DE2 Completed'} measure={'DE 2 Completed'} total={data && data.documentMetrics.totalUploaded} actual={data && data.documentMetrics.totalDE2Completed} />
                                </Col>
                                <Col xs={4}>
                                    <MetricCard label={"Total DE Completed"} totalLabel={'Pages Uploaded'} actualLabel={'DE Completed'} measure={'DE Completed'} total={data && data.documentMetrics.totalUploaded} actual={data && data.documentMetrics.totalDECompleted} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, dashboard, studies, sites }) => {
    return {
        token: user.token,
        data: dashboard.metrics,
        study: studies.current,
        site: sites.current,
        subjects: sites.subjects,
        visits: sites.visits
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getMyMetrics: (token, siteId) => DashboardActions.getSSRMetrics(dispatch, token, siteId),
        getSiteSubjects: (token, id) => SiteActions.getSiteSubjects(dispatch, token, id),
        getSiteVisitDetails: (token, id) => SiteActions.getSiteVisitDetails(dispatch, token, id),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DEMetrics);