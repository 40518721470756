import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    BreadcrumbItem,
    Col,
    Breadcrumb,
} from "reactstrap";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";
import { Routes } from 'constants/index.js';
import { SiteActions, DocumentActions } from "actions";

import { getToken, updateMonitorDocument } from "api/index.js";
import EditUploadDocument from "./Components/EditUploadDocument.js";
import EditUploadConfirm from "./Components/EditUploadConfirm.js";

class EditDocument extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedImage: 0,
            showAlert: false,
            alertType: 'warning',
            alertMessage: '',
            documentPopup: false,
            popupUrl: '',
            stage: 'data',
            monitorDoc: undefined
        }
    }

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    }

    componentDidMount() {
        const token = this.props.token;
        const siteId = this.props.site && this.props.site._id;
        if (siteId) {
            this.props.getSiteSubjects(token, siteId);
            this.props.getSiteVisits(token, siteId)
        }

        this.props.getDocumentDetails(token, this.props.match.params.id)
    }

    onDocumentDataCollected = (values) => {
        this.setState({ monitorDoc: values, stage: 'confirm' })
    }

    onUploadConfirmClick = (values) => {
        const identifier = this.props.user.email;
        getToken(identifier, values.password).then(async (response) => {
            try {
                this.props.saveToken(response.data);
                const monitorDoc = this.state.monitorDoc;
                const monitorDocObj = {
                    subject: monitorDoc.subject ? monitorDoc.subject._id : null,
                    docType: monitorDoc.docType,
                    domainData: monitorDoc.domainData ,
                    visit: monitorDoc.visit ? monitorDoc.visit._id : null
                }
                await updateMonitorDocument(this.props.token, this.props.match.params.id, monitorDocObj);
                this.props.history.push(Routes.DOCUMENTS);
            } catch (error) {
                alert('API failed!')
                console.log(error);
            }

        }).catch((e) => {
            const m = e.response ? e.response.data.data[0].messages[0].message : 'Something went wrong';
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        title="Alert"
                        onConfirm={this.hideAlert}
                    >
                        {m}
                    </SweetAlert>
                )
            });
        });
    }

    onCancel = () => {
        this.props.history.push(`${Routes.DOCUMENTS}`)
    }
    
    onScanCompleted = async(scannedImages) => { 
        for (let i = 0; i < scannedImages.length; ++i) {
            this.saveLoadedFile(scannedImages[0].src);
        }
    }

    render() {
        const { stage, monitorDoc } = this.state;
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.DOCUMENTS}`}>Documents</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.DOCUMENTS}/${this.props.match.params.id}`}>Document Details</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Update Document</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {this.state.alert}
                    {
                        stage === 'data' ?
                            <EditUploadDocument
                                {...this.props}
                                // onDescriptionChange={this.onDescriptionChange}
                                // onSubjectChange={this.onSubjectChange}
                                // onDocTypeChange={this.onDocTypeChange}
                                // onAddMorePagesClick={this.onAddMorePagesClick}
                                onCancel = {this.onCancel}
                                onDocumentDataCollected={this.onDocumentDataCollected} />
                            : null
                    }
                    {
                        stage === 'confirm' ?
                            <EditUploadConfirm
                                {...this.props}
                                document={monitorDoc}
                                onUploadConfirmClick={this.onUploadConfirmClick} />
                            : null
                    }
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, sites, documents, studies }) => {
    return {
        token: user.token,
        user: user,
        role: user.role ? user.role.name : undefined,
        details: documents.details,
        subjects: sites.subjects,
        visits: sites.visits,
        study: studies.current,
        site: sites.current
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDocumentDetails: (token, id) => DocumentActions.getDocumentDetails(dispatch, token, id),
        getSiteVisits: (token, siteId) => SiteActions.getSiteVisitDetails(dispatch, token, siteId),
        getSiteSubjects: (token, siteId) => SiteActions.getSiteSubjects(dispatch, token, siteId),
        saveToken: (data) => dispatch({
            type: 'GET_TOKEN',
            response: { ...data }
        }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditDocument);