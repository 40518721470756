import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Button,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    TabContent,
    TabPane,
    Badge,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
} from "reactstrap";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
import FileSaver from 'file-saver';
import _ from 'lodash';
import EasyEdit, { Types } from "react-easy-edit";
// core components
import DashboardLayout from "layouts/Admin"
import { Routes, Roles } from "constants/index.js";
import { MonitoringPlan, PanelHeader, RiskParameters, Documents } from "components";
import { SponsorInfo } from ".";
// react plugin used to create DropdownMenu for selecting items
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import { StudyActions, StaffActions } from 'actions'
import { CRFPages } from "views/Sites/Components";
import { RemoteTable } from "components";
import { userTableColumns } from 'views/Sites/Components/TableColumns';
import { updateStudy, updateSite, updateSiteStaffPivot } from "api";
import { PAGE_SIZE } from "api/list";
import cogoToast from "cogo-toast";
import { getFileFromUrl } from "commons";


const tabs = [
    { id: 'ht1', title: 'Documents' },
    { id: 'ht2', title: 'Sponsor' },
    { id: 'ht3', title: 'Monitoring Plan' },
    { id: 'ht4', title: 'Customise CRF Pages' },
    { id: 'ht5', title: 'Risk Assessment' },
    { id: 'ht6', title: 'Sites' },
    { id: 'ht7', title: 'User Manangement' }
]

class StudyDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            singleSelect: null,
            activeTab: "ht1",
            riskAssessment: undefined,
            page: 1,
            sitesPage: 1,
            alert: null,
            userPage: 1,
        };
        this.onCRFDataChange = this.onCRFDataChange.bind(this);
        this.alterEditMode = this.alterEditMode.bind(this);
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.onMonitorDataChange = this.onMonitorDataChange.bind(this);

        this.fileDownload = React.createRef()
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.study) {
                this.loadMonitoringPlan()
            }
        }
    }

    loadMonitoringPlan() {
        var study = this.props.study
        var visits = study.visits;
        var totalCRFPages = visits && visits.length > 0 ? parseInt(study.totalCRFPages[visits[0]]) : 0;
        var selectedPages = visits && visits.length > 0 ? study.monitorCRFPages[visits[0]] : [];
        var monitorData = []

        if (study && study.monitorTemplate) {
            var keys = Object.keys(study.monitorTemplate)
            keys.forEach(element => {
                var items = study.monitorTemplate[element]
                for (var i = 0; i < items.length; i++) {
                    monitorData.push(`${element}:${items[i]}`)
                }
            });
        }

        this.setState({
            study: study,
            totalCRFPages: totalCRFPages,
            selectedPages: selectedPages,
            monitorData: monitorData,
			riskAssessment : study ? study.riskAssessment : null
        });
    }

    componentDidMount() {
        const token = this.props.token;
        const studyId = this.props.match.params.id;

        this.props.getStudyDetails(token, studyId);
        this.props.getSitesByStudy(token, studyId);
        this.props.getProcessDocsByStudy(token, `clinicalStudy._id=${studyId}`);
        this.props.getStudyStaff(token, studyId, 0, PAGE_SIZE, this.props.role !== Roles.SYSTEM_ADMIN);
        this.props.getProjectManagers(token)
    }

    async onMonitorDataChange(selectedId, checked) {
		var monitorData;
		if (this.state.updatedMonitorData) {
			monitorData = this.state.updatedMonitorData;
		} else {
			monitorData = _.cloneDeep(this.state.monitorData);
		}
		
        if (checked) {
            monitorData.push(selectedId);
        } else {
            _.remove(monitorData, val => val === selectedId);
        }
        await this.setState({
            updatedMonitorData: monitorData
        })
    }

    onTotalCRFPagesChange = (e) => {
        this.setState({ updatedTotalCRFPages: parseInt(e.target.value) })
    }

    onTotalCRFPagesBlur = (e) => {
        if (!this.state.updatedTotalCRFPages)
            this.setState({ updatedTotalCRFPages: this.state.totalCRFPages })
    }

    onRiskParametersChange = (e) => {
        this.setState({ updatedRiskAssessment: e })
    }

    async onCRFDataChange(e) {
        const selectedId = e.target.id;
        var selectedPages = _.clone(this.state.selectedPages);

        if (this.state.updatedSelectedPages)
            selectedPages = this.state.updatedSelectedPages;

        if (e.target.checked) {
            selectedPages.push(selectedId);
        } else {
            _.remove(selectedPages, val => val === selectedId);
        }
        await this.setState({ updatedSelectedPages: selectedPages })
    }

    async alterEditMode() {
        const { editMode } = this.state;
        if (editMode) {
            this.setState({
                alert: {
                    title: 'Confirmation',
                    onConfirm: this.onConfirmClick,
                    confirmBtnText: 'Submit',
                    confirmMessage: 'Changes will reflect for the new sites. For existing sites, please ' +
                        'change from site details tab.Are you sure you want to submit the changes ?',
                    onCancel: this.onCancelEdit
                }
            });
        } else {
            this.setState({ editMode: !editMode })
        }
    }

    async onConfirmClick() {
        const { editMode, activeTab } = this.state;
        const studyId = this.props.match.params.id;
        const token = this.props.token;

        switch (activeTab) {
            case 'ht3':
                const monitorObj = {}
                const { updatedMonitorData, monitorData } = this.state;
                updatedMonitorData.forEach(val => {
                    const d = val.split(':')
                    const key = d[0]
                    const value = d[1]
                    if (monitorObj[key]) {
                        monitorObj[key].push(value)
                    } else {
                        monitorObj[key] = [value]
                    }
                })
                updateStudy(token, studyId, { monitorTemplate: monitorObj }).then(res => {
                    this.setState({ 
						editMode: false, 
						alert: null, 
          				monitorData : updatedMonitorData ? updatedMonitorData : monitorData,
						updatedMonitorData : undefined 
					})
                }).catch(e => console.log(e))
                break;
            case 'ht4':
                const { updatedTotalCRFPages, updatedSelectedPages, totalCRFPages, selectedPages } = this.state;
                let visits = this.props.visits;
                var totalCRFPagesObj = {}
                var selectedPagesObj = {}
                visits.forEach(val => {
                    totalCRFPagesObj[val] = `${updatedTotalCRFPages ? updatedTotalCRFPages : totalCRFPages}`;
                    selectedPagesObj[val] = updatedSelectedPages ? updatedSelectedPages : selectedPages;
                })

                updateStudy(token, studyId, { monitorCRFPages: selectedPagesObj, totalCRFPages: totalCRFPagesObj })
                    .then(res => {
                        this.setState({ 
							editMode: false, 
							alert: null, 
							totalCRFPages : updatedTotalCRFPages ? updatedTotalCRFPages : totalCRFPages,
							selectedPages : updatedSelectedPages ? updatedSelectedPages : selectedPages,
							updatedTotalCRFPages: undefined, 
							updatedSelectedPages: undefined
						});
                    }).catch(e => console.log(e))
                break;
            case 'ht5':
				const { riskAssessment, updatedRiskAssessment } = this.state;
                updateStudy(token, studyId, { riskAssessment: updatedRiskAssessment }).then(res => {
                    this.setState({ 
						editMode: !editMode, 
						alert: null, 
						riskAssessment: updatedRiskAssessment ? updatedRiskAssessment : riskAssessment ,
						updatedRiskAssessment : undefined
					})
                }).catch(e => console.log(e))
                break;
            case 'ht6':

                break;
            default:
                return
        }
    }

    onCancelEdit = () => {
        this.setState({
            editMode: false,
            alert: null,
            updatedMonitorData: undefined,
            updatedRiskAssessment: undefined,
            updatedSelectedPages: undefined,
            updatedTotalCRFPages: undefined
        })
    }

    onStatusChange = (el, state) => {
        const studyId = this.props.match.params.id;
        const title = state ? 'Activate' : 'Deactivate';
        let confirmMessage = `This will Deactivate the study in all the running sites. Are you sure you want to ${title} ?`
        if (state) {
            confirmMessage = `Are you sure you want to Activate this study?. Individual sites have to be activated separately`;
        }

        this.setState({
            alert: {
                title: 'Confirmation',
                onConfirm: () => this.confirmUpdateStudyStatus(state, studyId),
                confirmBtnText: title,
                confirmMessage: confirmMessage
            }
        });
    }

    changeUserAccess = (staffAccess) => {
        const confirmMessage = `Are you sure you want to ${staffAccess.isAccess ? 'Revoke' : 'Grant'} access to user for this study?`;
        this.setState({
            alert: {
                title: 'Confirmation',
                onConfirm: async () => await this.confirmChangeUserAccess(staffAccess),
                confirmBtnText: 'Confirm',
                confirmMessage: confirmMessage
            }
        });

    }

    handleSiteTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        this.props.getSitesByStudy(this.props.token, this.state.selectedStudy, currentIndex, PAGE_SIZE);
        this.setState({ sitesPage: page })
    }

    handleUserTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        this.props.getSiteStaff(this.props.token, this.state.selectedStudy,
            currentIndex, PAGE_SIZE, this.props.role !== Roles.SYSTEM_ADMIN);
        this.setState({ userPage: page })
    }

    confirmChangeUserAccess = (staffAccess) => {
        updateSiteStaffPivot(this.props.token, staffAccess.id, { isAccess: !staffAccess.isAccess }).then(res => {
            this.props.getStudyStaff(this.props.token, this.props.match.params.id,
                (this.state.userPage - 1) * PAGE_SIZE, PAGE_SIZE, this.props.role !== Roles.SYSTEM_ADMIN);
            this.setState({ alert: false })
        }).catch(e => {
            console.log(e);
            this.setState({ alert: null });
        });
    }

    confirmUpdateStudyStatus = (status, studyId) => {
        const { token } = this.props;
        updateStudy(token, studyId, { isActive: status }).then(res => {
            this.props.getStudyDetails(token, studyId);
            this.props.getSitesByStudy(token, studyId);
        }).catch(e => {
            console.log(e);
        });
        this.setState({ alert: null });
    }

    confirmUpdateSiteStatus = (status, siteId) => {
        const { token } = this.props;
        updateSite(token, siteId, { isActive: status }).then(res => {
            this.setState({ alert: null });
            this.props.getSitesByStudy(token, this.props.match.params.id);
        }).catch(e => console.log(e))
    }

    onSiteStatusChange = (status, siteId) => {
        const title = status ? 'Activate' : 'Deactivate';
        this.setState({
            alert: {
                title: 'Confirmation',
                onConfirm: () => this.confirmUpdateSiteStatus(status, siteId),
                confirmBtnText: title,
                confirmMessage: `Are you sure you want to ${title} study in this Site?`
            }
        });
    }

    onDocumentItemClick = (e, doc, rowIndex) => {
        if (doc.url) {
            this.setState({
                alert: {
                    title: '',
                    onConfirm: this.onDownloadConfirmClick,
                    confirmBtnText: 'Download',
                    confirmMessage: 'Are you sure you want to Download the document?'
                },
                doc: doc
            })
        }
    }

    onDownloadConfirmClick = async () => {
        const { doc } = this.state;
        const filename = `${doc.name}.${doc.url.split('.').pop()}`;

        // close pop up before downloading the file.
        // sync file saver is synchronous, state change before 
        // file is downloaded is cancelling the download action
        //
        await this.setState({ alert: false });

        let fileBlob = await getFileFromUrl(doc.url, this.props.token, true);
        FileSaver.saveAs(fileBlob, filename);
    }

    onTabChangeClick = (tabId) => {
        this.setState({
            alert: {
                title: '',
                onConfirm: this.onTabChangeConfirmClick,
                confirmBtnText: 'Ok',
                confirmMessage: 'Do you want to discard the changes and proceed?'
            },
            nextTabId: tabId,
        })
    }

    onTabChangeConfirmClick = async () => {
        await this.setState({
            activeTab: this.state.nextTabId,
            nextTabId: undefined,
        })
        this.onCancelEdit()
    }

    rowsWithData() {
        const sites = this.props.sites ? this.props.sites : [];
        return sites.map((val, index) => {
            return (
                <tr key={val.id}>
                    <td>{index + 1}</td>
                    <td>{val.name}</td>
                    <td>{val.investigator}</td>
                    <td className="text-center"><Badge className={val.isActive ? "active-badge" : "inactive-badge"}>{val.isActive ? 'Active' : 'Inactive'}</Badge></td>
                    <td>
                        <Button
                            className="btn-neutral"
                            onClick={() => this.onSiteStatusChange(!val.isActive, val._id)}
                        >
                            {val.isActive ? "Deactivate" : "Activate"}</Button>
                    </td>
                </tr>
            )
        })
    }

    handleProcessDocTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        this.setState({ page })

        const token = this.props.token;
        const studyId = this.props.match.params.id;

        this.props.getProcessDocsByStudy(token, `clinicalStudy._id=${studyId}`, currentIndex);
    }

    updateStudyItem = async (key, value) => {
        const studyId = this.props.match.params.id;
        const token = this.props.token;
        try {
            updateStudy(token, studyId, { [key]: value })
        } catch (error) {
            console.log(error);
            cogoToast.error('Failed to update!')
        }
    }

    render() {

        const { study, visits, domains, processDocs, processDocsCount } = this.props
        const { editMode, alert, riskAssessment, page, updatedRiskAssessment } = this.state;

        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.STUDIES}`}>Studies</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Study Details</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {
                        alert &&
                        <SweetAlert
                            show={true}
                            title={alert.title}
                            style={{ minHeight: '200px' }}
                            closeOnClickOutside={false}
                            allowEscape={false}
                            onConfirm={alert.onConfirm}
                            onCancel={alert.onCancel ? alert.onCancel : () => this.setState({ alert: null })}
                            confirmBtnBsStyle="primary btn-round btn-sm"
                            cancelBtnBsStyle="outline-primary btn-round btn-sm"
                            confirmBtnText={alert.confirmBtnText}
                            cancelBtnText="Cancel"
                            showCancel>
                            {alert.confirmMessage}
                        </SweetAlert>
                    }
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '755px' }}>
                                <CardHeader>
                                    <Row>
                                        <Col sm={6} md={6} lg={10}>
                                            <CardTitle tag="h4">
                                                <EasyEdit
                                                    type={Types.TEXT}
                                                    placeholder="Study Name"
                                                    validationMessage="Please provide Study Name"
                                                    value={study ? study.name : ''}
                                                    onSave={(val) => this.updateStudyItem('name', val)}
                                                    onValidate={value => { return value.trim() !== '' }}
                                                    saveButtonLabel={<i class="fa fa-check" aria-hidden="true" />}
                                                    cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" />}
                                                />
                                            </CardTitle>
                                            <p>There {study && study.numberOfSites > 1 ? 'are' : 'is'}  {study ? study.numberOfSites : ''} sites associated with this study</p>
                                        </Col>
                                        <Col sm={6} md={6} lg={2}>
                                            <p className="info">Change Status</p>
                                            <Switch name="isActive" value={this.props.study && this.props.study.isActive} onColor="primary" onChange={(el, state) => this.onStatusChange(el, state)} />
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={3}>
                                            <Row>
                                                <Col>
                                                    <Label>Protocol ID</Label>
                                                    <EasyEdit
                                                        type={Types.TEXT}
                                                        placeholder="Protocol ID"
                                                        validationMessage="Please provide Protocol ID"
                                                        value={study ? study.protocolId : ''}
                                                        onSave={(val) => this.updateStudyItem('protocolId', val)}
                                                        onValidate={value => { return value.trim() !== '' }}
                                                        saveButtonLabel={<i class="fa fa-check" aria-hidden="true" />}
                                                        cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" />}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Label>Project Manager</Label>
                                                    <EasyEdit
                                                        type={Types.SELECT}
                                                        options={
                                                            this.props.managers ? this.props.managers.map((val) => {
                                                                return { value: val._id, label: `${val.firstName} ${val.lastName}` }
                                                            }) : []
                                                        }
                                                        placeholder="Select"
                                                        validationMessage="Please select Project Manager"
                                                        value={study && study.projectManager ? study.projectManager._id : ' '}
                                                        onSave={(val) => this.updateStudyItem('projectManager', val)}
                                                        onValidate={value => { return value.trim() !== '' }}
                                                        saveButtonLabel={<i class="fa fa-check" aria-hidden="true" />}
                                                        cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" />}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={3}>
                                            <Row>
                                                <Col>
                                                    <Label>No. Of Sites</Label>
                                                    <p>{study ? study.numberOfSites : ''}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Label>Regulatory Authority</Label>
                                                    <p>{study ? study.regulatoryAuthority : ''}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={3}>
                                            <Row>
                                                <Col>
                                                    <Label>Q ID</Label>
                                                    <EasyEdit
                                                        type={Types.TEXT}
                                                        placeholder="Quanticate No"
                                                        validationMessage="Please provide Quanticate No"
                                                        value={study ? study.quanticateNo : ''}
                                                        onSave={(val) => this.updateStudyItem('quanticateNo', val)}
                                                        onValidate={value => { return value.trim() !== '' }}
                                                        saveButtonLabel={<i class="fa fa-check" aria-hidden="true" />}
                                                        cancelButtonLabel={<i className="fa fa-times" aria-hidden="true" />}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <br />
                                            <Nav pills className="nav-pills-primary"
                                                style={{ fontSize: '7px', padding: '10px', overflowX: 'auto', overflowY: 'hidden', flexWrap: 'nowrap' }}>
                                                {
                                                    tabs.map((val, index) => {
                                                        return (
                                                            <NavItem key={index}>
                                                                <NavLink
                                                                    className={this.state.activeTab === `${val.id}` ? "active" : ""}
                                                                    onClick={() => {
                                                                        if (this.state.editMode) {
                                                                            this.onTabChangeClick(val.id)
                                                                        } else {
                                                                            this.setState({ activeTab: `${val.id}` })
                                                                        }
                                                                    }}
                                                                >
                                                                    {val.title}
                                                                </NavLink>
                                                            </NavItem>
                                                        )
                                                    })
                                                }
                                            </Nav>
                                            <TabContent
                                                activeTab={this.state.activeTab} style={{ border: '2px' }}
                                                className="tab-space"
                                            >
                                                <TabPane tabId="ht1">
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div>
                                                                <h6 className="float-left mt-2">Documents Uploaded</h6>
                                                                <Button className="btn-round float-right" color="primary" href={`${Routes.STUDIES}/${this.props.match.params.id}/uploaddocument`} size="sm">
                                                                    Upload Document
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Documents
                                                        id={this.props.match.params.id}
                                                        documents={processDocs}
                                                        page={page}
                                                        totalSize={processDocsCount ? processDocsCount : 0}
                                                        handleProcessDocTableChange={this.handleProcessDocTableChange}
                                                        onDocumentItemClick={this.onDocumentItemClick} />
                                                </TabPane>
                                                <TabPane tabId="ht2">
                                                    <SponsorInfo data={study ? study.sponsorer : undefined} />
                                                </TabPane>
                                                <TabPane tabId="ht3">
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div>
                                                                <h6 className="float-left mt-3">Monitoring Plan</h6>
                                                                <Button className="btn-round float-right" color="primary" onClick={this.alterEditMode} size="sm">
                                                                    {editMode ? 'Submit Plan' : 'Change Plan'}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <MonitoringPlan
                                                        visits={visits}
                                                        disabled={!editMode}
                                                        domains={domains}
                                                        data={this.state.updatedMonitorData != null && this.state.updatedMonitorData ? this.state.updatedMonitorData : this.state.monitorData}
                                                        onDataChange={this.onMonitorDataChange} />
                                                </TabPane>
                                                <TabPane tabId="ht4">
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div>
                                                                <h6 className="float-left mt-3">CRF Pages</h6>
                                                                <Button className="btn-round float-right" color="primary" onClick={this.alterEditMode} size="sm">
                                                                    {editMode ? 'Submit' : 'Change'}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <CRFPages
                                                        onCRFDataChange={this.onCRFDataChange}
                                                        onTotalCRFPagesChange={this.onTotalCRFPagesChange}
                                                        onTotalCRFPagesBlur={this.onTotalCRFPagesBlur}
                                                        totalCRFPages={this.state.updatedTotalCRFPages ? this.state.updatedTotalCRFPages : this.state.totalCRFPages}
                                                        selectedPages={this.state.updatedSelectedPages ? this.state.updatedSelectedPages : this.state.selectedPages}
                                                        disabled={!editMode} />
                                                </TabPane>
                                                <TabPane tabId="ht5">
                                                    <Row>
                                                        <Col>
                                                            <Button color="primary" style={{ minWidth: '132px' }} className="btn-round float-right" size="sm" onClick={this.alterEditMode}>{editMode ? 'Submit' : 'Change'}</Button>
                                                        </Col>
                                                    </Row>
                                                    <RiskParameters
                                                        disabled={!editMode}
                                                        data={updatedRiskAssessment ? updatedRiskAssessment : riskAssessment}
                                                        onChange={this.onRiskParametersChange} />
                                                </TabPane>
                                                <TabPane tabId="ht6">
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div>
                                                                <h4 className="float-left mt-1">Sites</h4>
                                                                <Button className="btn-round float-right" color="primary" href={`${Routes.STUDIES}/${this.props.match.params.id}/newsite`} size="sm">
                                                                    Add New Site
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <RemoteTable
                                                        columns={[{
                                                            dataField: 'name',
                                                            text: 'Site ID',
                                                            formatter: (cell, row, index) => {
                                                                return `${(index + ((this.state.page - 1) * PAGE_SIZE)) + 1}`
                                                            }
                                                        },
                                                        {
                                                            dataField: 'name',
                                                            text: 'Site Name',
                                                        },
                                                        {
                                                            dataField: 'investigator',
                                                            text: 'Investigator Name',
                                                        },
                                                        {

                                                            text: 'Status',
                                                            formatter: (cell, row, index) => {
                                                                return <Badge className={row.isActive ? "active-badge" : "inactive-badge"}>{row.isActive ? 'Active' : 'Inactive'}</Badge>
                                                            }
                                                        },
                                                        {
                                                            text: 'Action',
                                                            formatter: (cell, row, index) => {
                                                                return <Button
                                                                    className="btn-neutral"
                                                                    onClick={() => this.onSiteStatusChange(!row.isActive, row._id)}
                                                                >
                                                                    {row.isActive ? "Deactivate" : "Activate"}</Button>
                                                            }
                                                        }
                                                        ]}
                                                        onTableChange={this.handleSiteTableChange}
                                                        data={this.props.sites ? this.props.sites : []}
                                                        page={this.state.sitesPage}
                                                        sizePerPage={PAGE_SIZE}
                                                        totalSize={this.props.sitesCount}
                                                    />
                                                </TabPane>
                                                <TabPane tabId="ht7">
                                                    <Row>
                                                        <Col>
                                                            <Button color="primary" style={{ minWidth: '132px' }} className="btn-round float-right" size="sm" href={`${Routes.STUDIES}/${this.props.match.params.id}/adduser`}>Add User</Button>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        this.props.staff &&
                                                        <RemoteTable
                                                            data={this.props.staff}
                                                            sizePerPage={PAGE_SIZE}
                                                            page={this.state.userPage}
                                                            totalSize={this.props.totalStaff}
                                                            columns={userTableColumns((this.state.userPage - 1) * PAGE_SIZE, this.changeUserAccess, true)}
                                                            // onItemClick={this.onItemClick}
                                                            onTableChange={this.handleUserTableChange}
                                                        />
                                                    }
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, studies, staff }) => {
    return {
        token: user.token,
        role: user ? user.role.name : undefined,
        study: studies.current,
        visits: studies.current ? studies.current.visits : [],
        domains: studies.current ? studies.current.domainData : [],
        sites: studies.sites,
        sitesCount: studies.sitesCount,
        staff: staff.studyStaff,
        totalStaff: staff.studyStaffCount,
        processDocs: studies.processDocs,
        processDocsCount: studies.processDocsCount,
        managers: staff.pms
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getStudyDetails: (token, id) => StudyActions.getStudyDetails(dispatch, token, id),
        getSitesByStudy: (token, id, start, limit) => StudyActions.getSitesByStudy(dispatch, token, id, start, limit),
        getProcessDocsByStudy: (token, params, dataIndex) => StudyActions.getProcessDocByStudy(dispatch, token, params, dataIndex),
        getStudyStaff: (token, id, start, limit, activeOnly) => StaffActions.getStudyStaff(dispatch, token, id, start, limit, activeOnly),
        getProjectManagers: (token) => StaffActions.getProjectManagers(dispatch, token)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(StudyDetails);