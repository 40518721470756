const initialState = {
    data: []
};

const documents = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DOCUMENTS':
            return { ...state, data: action.response }
        case 'GET_DATA_PENDING_DOCUMENTS':
            return { ...state, dataPending: action.response }
        case 'DOCUMENT_DETAILS':
            return { ...state, details: action.response }
        case 'UPDATE_DOCUMENT':
            let details = state.details;
            // replace the updated document entry in the state
            if (action.index !== -1) {
                details[action.index] = action.response;
            }
            return { ...state, details: details };
        case 'DOCUMENT_DETAILS_BY_ID':
            return { ...state, sdvDocument: action.response };
        case 'GET_DOCUMENTS_COUNT':
            return { ...state, count: action.response };
        case 'GET_DATA_PENDING_COUNT':
            return { ...state, dataPendingCount: action.response };
        case 'GET_RESPONSE_DOCUMENT':
            return { ...state, responseDocument : action.response };
        default:
            return state;
    }
}

export default documents;