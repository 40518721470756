import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
    Button,
} from "reactstrap";

import _ from 'lodash';
// core components
import DashboardLayout from "layouts/Admin"
import { Routes } from 'constants/index.js';
import SweetAlert from "react-bootstrap-sweetalert";
import { PublishMonitorPlan, PublishSites, CRFPages, CreateSponsor, AboutStudy, StudyDocuments } from './Components'
import { SiteActions, SponsorActions, StaffActions } from "actions";
import { RiskParameters, PanelHeader } from "components"
import { createStudy, createSponsor, createSite } from "api";

class StudyAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            activeSite: 0,
            alert: null,
            studyDetails: {},
            sponsorDetails: {},
            siteDetails: {},
            visitType: 'automatic',
            siteType: 'select',
            riskAssessment: { green: 5, amber: 20, red: 75 },
            monitorPlanDetails: {},
            templateGenerated: false,
            domains: undefined,
            visitsCount: undefined,
            sponsorId: undefined,
            monitorData: [],
            domainData: [],
            visitsData: [],
            crfPages: [],
            monitorPlanStep: false,
            processDocs: [],
            siteFormData: { name: '', site: undefined, address: '', contactNumber: '', contactPerson: '', designation: '', contactEmail: '', noOfPatients: '' }
        };

        this.onAboutStudySubmit = this.onAboutStudySubmit.bind(this);
        this.onSponsorDetailsSubmit = this.onSponsorDetailsSubmit.bind(this);
        this.onStudyDocumentsSubmit = this.onStudyDocumentsSubmit.bind(this);
        this.onRemoveStudyDocument = this.onRemoveStudyDocument.bind(this);
        this.onRiskParametersNextClick = this.onRiskParametersNextClick.bind(this);
        this.onCreateSiteClick = this.onCreateSiteClick.bind(this);
    }

    getSteps() {
        return [
            { step: 0, title: 'Sponsor', description: 'Select  an existing sponsor or Create a new sponsor.' },
            { step: 1, title: 'About Study', description: 'Enter the study details.' },
            { step: 2, title: 'Upload Study Documents', description: 'Documents like study guidelines, procedures etc.' },
            { step: 3, title: 'Publish Monitoring Plan', description: 'Add study monitoring plan to be followed.' },
            { step: 4, title: 'Publish CRF Pages Template', description: 'Update CRF pages to be verified for Paper based sites' },
            { step: 5, title: 'Define Risk Assesment', description: 'Define risk Indicators.' },
            { step: 6, title: 'Create Sites', description: 'Select an existing site or Create a new site.' }
        ];
    }

    componentDidMount() {
        const { token } = this.props;
        this.loadSubComponent(this.state.currentStep);
        this.props.getSponsors(token, 0, -1);
        this.props.getSites(token);
        this.props.getProjectManagers(token);
		
		if (!this.props.sites || this.props.sites.length === 0) {
			this.setState({siteType : 'create'});
		}
    }
  
    loadSubComponent(currentStep) {
        switch (currentStep) {
            case 0:
                return this.setState({ currentStep: 0, sponsorStep: true, studyStep: false, documentsStep: false, monitorPlanStep: false, crfPagesStep: false, riskAssessmentStep: false, createSitesStep: false });
            case 1:
                return this.setState({ currentStep: 1, sponsorStep: false, studyStep: true, documentsStep: false, monitorPlanStep: false, crfPagesStep: false, riskAssessmentStep: false, createSitesStep: false });
            case 2:
                return this.setState({ currentStep: 2, sponsorStep: false, studyStep: false, documentsStep: true, monitorPlanStep: false, crfPagesStep: false, riskAssessmentStep: false, createSitesStep: false });
            case 3:
                return this.setState({ currentStep: 3, sponsorStep: false, studyStep: false, documentsStep: false, monitorPlanStep: true, crfPagesStep: false, riskAssessmentStep: false, createSitesStep: false });
            case 4:
                return this.setState({ currentStep: 4, sponsorStep: false, studyStep: false, documentsStep: false, monitorPlanStep: false, crfPagesStep: true, riskAssessmentStep: false, createSitesStep: false });
            case 5:
                return this.setState({ currentStep: 5, sponsorStep: false, studyStep: false, documentsStep: false, monitorPlanStep: false, crfPagesStep: false, riskAssessmentStep: true, createSitesStep: false });
            case 6:
                return this.setState({ currentStep: 6, sponsorStep: false, studyStep: false, documentsStep: false, monitorPlanStep: false, crfPagesStep: false, riskAssessmentStep: false, createSitesStep: true });
            default:
                return
        }
    }

    async onSponsorDetailsSubmit(event, values) {
        console.log('onSponsorDetailsSubmit', values);
        const { type, logo } = values;
        const { currentStep } = this.state;

        const sponsorDetails = {};

        if (type === 'select') {
            sponsorDetails['id'] = values.sponsor
            this.setState({
                sponsorDetails: sponsorDetails,
            })
            this.loadSubComponent(currentStep + 1)
        } else if (type === 'create') {
            sponsorDetails['name'] = values.name;
            sponsorDetails['contactNumber'] = values.contactNumber
            sponsorDetails['address'] = values.address
            sponsorDetails['contactName'] = values.contactName
            sponsorDetails['designation'] = values.designation
            sponsorDetails['email'] = values.email
            if (logo && logo.url)
                sponsorDetails['logo'] = logo.url
            //creating sponsor through API.
            try {
                const response = await createSponsor(this.props.token, sponsorDetails);
                console.log(response.data);
                await this.setState({
                    sponsorDetails: response.data,
                })
                this.loadSubComponent(this.state.currentStep + 1)
            } catch (error) {
                if (error.response && error.response.status === 400 && error.response.data.message.name) {
                    this.showAlertMessage(error.response.data.message.name.message);
                } else {
                    alert('Failed Sponsor creation');
                    console.log(error);
                }
            }
        }
    }

    showAlertMessage = (message) => {
        this.setState({
            alert: (
                <SweetAlert
                    title=''
                    style={{ minHeight: '200px' }}
                    closeOnClickOutside={false}
                    allowEscape={false}
                    onConfirm={() => this.setState({ alert: false })}
                    confirmBtnBsStyle="primary btn-round btn-sm"
                    cancelBtnBsStyle="outline-primary btn-round btn-sm"
                    confirmBtnText="Ok"
                >
                    <h6>Alert</h6>
                    {message}
                </SweetAlert>
            )
        });
    }

    async onAboutStudySubmit(event, values) {
        console.log('onAboutStudySubmit', values);
        await this.setState({
            studyDetails: values
        });
        this.loadSubComponent(this.state.currentStep + 1)
    }

    async onStudyDocumentsSubmit(event, values) {
        console.log('onStudyDocumentsSubmit', values);
        await this.setState({ processDocs: values })
        this.loadSubComponent(this.state.currentStep + 1)
    }

    async onRemoveStudyDocument(val, index) {
        var processDocs = this.state.processDocs;
        processDocs.splice(index, 1);
        await this.setState({ processDocs })
    }

    handlePrevious = () => {
        const { currentStep } = this.state
        this.setState({
            currentStep: currentStep - 1
        });
        this.loadSubComponent(currentStep - 1)
    }

    removeDocument = (processDoc, index) => {
        alert(index)
    }

    riskParametersChange = (e) => {
        this.setState({
            riskAssessment: e
        })
    }

    onMonitorDataChange = (e, checked) => {
        //monitor plan data changed
        const { monitorData } = this.state
        if (checked) {
            monitorData.push(e)
        } else {
            _.remove(monitorData, val => val === e);
        }

        var monitorTemplateData = {}

        for (var i = 0; i < monitorData.length; i++) {
            const d = monitorData[i].split(':')
            const key = d[0]
            const value = d[1]
            if (monitorTemplateData[key]) {
                monitorTemplateData[key].push(value)
            } else {
                monitorTemplateData[key] = [value]
            }
        }

        this.setState({
            moveNext: true,
            monitorData: monitorData,
            monitorTemplateData: monitorTemplateData
        })
    }

    onDomainsDataChanged = (e) => {
        var data = []
        e && e.map(val => {
          return  data.push(val.label)
        })

        this.setState({
            domains: e,
            domainData: data,
            monitorData: e ? this.state.monitorData : []
        });
    }

    onVisitsDataChanged = (e) => {
        this.setState({
            visitsData: e
        })
    }

    onVisitsCountChanged = (count) => {
        this.setState({
            visitsCount: count
        })
    }

    onVisitTypeChanged = (e) => {
        this.setState({
            visitType: e
        })
    }

    onMonitorNextClick = (e) => {
        this.loadSubComponent(this.state.currentStep + 1)
    }

    onCRFDataChange = (e) => {
        var { crfPages } = this.state;
        const pageId = e.target.id;
        if (e.target.checked) {
            crfPages.push(pageId);
        } else {
            _.remove(crfPages, val => val === pageId)
        }
        this.setState({ selectedPages: crfPages })
    }

    onTotalCRFPagesChange = (e) => {
        this.setState({ totalCRFPages: e.target.value })
    }

    onCRFNextClick = (e) => {
        this.loadSubComponent(this.state.currentStep + 1)
    }

    async onRiskParametersNextClick(e) {

        const { studyDetails, sponsorDetails, riskAssessment, monitorTemplateData, domainData, visitsData, totalCRFPages, selectedPages, processDocs } = this.state
        var totalCRFPagesObj = {};
        var monitorCRFPagesObj = {}
        visitsData.forEach(val => {
            totalCRFPagesObj[val] = totalCRFPages;
            monitorCRFPagesObj[val] = selectedPages
        });

        const study = {
            name: studyDetails.name,
            protocolId: studyDetails.protocolId,
            quanticateNo: studyDetails.quanticateNo,
            projectManager: studyDetails.projectManager,
            numberOfSites: studyDetails.noOfSites,
            sponsorer: sponsorDetails.id,
            regulatoryAuthority: studyDetails.regulatoryAuthority,
            riskAssessment: riskAssessment,
            monitorTemplate: monitorTemplateData,
            domainData: domainData,
            visits: visitsData,
            processDocs: processDocs,
            totalCRFPages: totalCRFPagesObj,
            monitorCRFPages: monitorCRFPagesObj
        }
        try {
            const response = await createStudy(this.props.token, study);
            await this.setState({
                createdStudy: response.data,
            });
            this.loadSubComponent(this.state.currentStep + 1)
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.message.name) {
                this.showAlertMessage(error.response.data.message.name.message);
            } else {
                alert('Failed Study creation');
                console.log(error);
            }
        }
    }

    onSiteTypeChanged = (type) => {
        this.setState({ siteType: type })
    }

    async onCreateSiteClick(values, siteForm) {

        console.log('onCreateSiteClick', values);
        if (values.site) {
            const selectedSite = this.props.sites.filter(val => val._id === values.site)[0]
            values['site'] = selectedSite
        }
        const { domainData, riskAssessment, createdStudy, studyDetails, processDocs, activeSite, siteType } = this.state;

        const site = {}
        if (values.site && siteType === 'select') {
            site['name'] = values.site.name;
            site['contactPerson'] = values.site.contactPerson;
            site['contactNumber'] = values.site.contactNumber;
            site['address'] = values.site.address;
            site['designation'] = values.site.designation;
			site['contactEmail'] = values.site.contactEmail;
        } else if (siteType === 'create') {
            site['name'] = values.name;
            site['address'] = values.address;
            site['contactNumber'] = values.contactNumber;
            site['contactPerson'] = values.contactPerson;
            site['designation'] = values.designation;
			site['contactEmail'] = values.contactEmail;
        }
        site['crfType'] = values.crfType;
        site['processDocs'] = processDocs;
        site['domainData'] = domainData;
        site['riskAssessment'] = riskAssessment;
        site['clinicalStudy'] = createdStudy._id;
        site['investigator'] = values.investigator;

        if (values.uniqueCodeType === 'manual') {
            site['siteSubjects'] = values.siteSubjects;
        } else {
            site['numSubjects'] = values.numSubjects;
            site['subjectPrefix'] = values.subjectPrefix;
        }

        console.log('final site object', site);

        try {
            await createSite(this.props.token, site);
            if (parseInt(studyDetails.noOfSites) === parseInt(activeSite + 1)) {
                this.props.history.push(Routes.STUDIES);
            } else if (parseInt(studyDetails.noOfSites) > parseInt(activeSite + 1)) {
                siteForm.reset();
                await this.setState({ activeSite: (activeSite + 1), siteFormData: { name: '', site: undefined, address: '', contactNumber: '', contactPerson: '', designation: '', contactEmail: '', noOfPatients: '' } })
            } else {
                alert('Error in logic');
            }
        } catch (error) {
            alert('Failed Site creation');
            console.log(error);
        }
    }

    render() {
        const { currentStep, sponsorStep, crfPagesStep, riskAssessmentStep, monitorPlanStep, documentsStep, studyStep, createSitesStep } = this.state;
        const { processDocs, studyDetails, visitType, siteFormData, monitorData, visitsData, domains, totalCRFPages, selectedPages, riskAssessment, activeSite, visitsCount } = this.state
        const { sponsors, sites, managers, token } = this.props
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.STUDIES}`}>Studies</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Add New Study</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {this.state.alert}
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '755px' }}>
                                <CardHeader>
                                    <CardTitle tag="h4">Create New Study</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs={4} md={3}>
                                            <div className="bs-vertical-wizard">
                                                <ul>
                                                    {
                                                        this.getSteps().map((val, index) => {
                                                            return (
                                                                <li key={val.step} className={index <= currentStep ? 'complete' : ''}>
                                                                    <div>
                                                                        <div className='title'>{val.title}</div>
                                                                        <span className="desc">{val.description}</span>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={8}>
                                            {
                                                sponsorStep ?
                                                    <CreateSponsor
                                                        token={token}
                                                        sponsors={sponsors}
                                                        onSponsorDetailsSubmit={this.onSponsorDetailsSubmit} />
                                                    : null
                                            }
                                            {
                                                studyStep ?
                                                    <AboutStudy
                                                        managers={managers}
                                                        onAboutStudySubmit={this.onAboutStudySubmit} />
                                                    : null
                                            }
                                            {
                                                documentsStep ?
                                                    <StudyDocuments
                                                        token={token}
                                                        processDocs={processDocs}
                                                        onRemoveStudyDocument={this.onRemoveStudyDocument}
                                                        onStudyDocumentsSubmit={this.onStudyDocumentsSubmit} />
                                                    : null
                                            }
                                            {
                                                monitorPlanStep ?
                                                    <PublishMonitorPlan monitorData={monitorData}
                                                        visits={visitsData}
                                                        domains={domains}
                                                        visitType={visitType}
                                                        visitsCount={visitsCount}
                                                        onVisitsCountChanged={this.onVisitsCountChanged}
                                                        onMonitorNextClick={this.onMonitorNextClick}
                                                        onVisitTypeChange={this.onVisitTypeChanged}
                                                        onVisitsDataChanged={this.onVisitsDataChanged}
                                                        onDomainsDataChanged={this.onDomainsDataChanged}
                                                        onMonitorDataChange={this.onMonitorDataChange} />
                                                    : null
                                            }
                                            {
                                                crfPagesStep ?
                                                    <CRFPages
                                                        onCRFDataChange={this.onCRFDataChange}
                                                        onTotalCRFPagesChange={this.onTotalCRFPagesChange}
                                                        totalCRFPages={totalCRFPages}
                                                        selectedPages={selectedPages}
                                                        onCRFNextClick={this.onCRFNextClick} />
                                                    : null
                                            }
                                            {
                                                riskAssessmentStep ?
                                                    <>
                                                        <RiskParameters
                                                            data={riskAssessment}
                                                            onChange={this.riskParametersChange} />
                                                        <Button color="primary" style={{ marginLeft: '20px' }} className="btn-round" size="sm" type="submit" onClick={this.onRiskParametersNextClick}>
                                                            Next
                                                        </Button>
                                                    </>
                                                    : null
                                            }
                                            {
                                                createSitesStep ?
                                                    <PublishSites
                                                        sites={sites}
                                                        activeSite={activeSite}
                                                        data={siteFormData}
                                                        siteType={
															this.props.sites && this.props.sites.length > 0 ?
																this.state.siteType : 'create'
														}
                                                        onSiteTypeChanged={this.onSiteTypeChanged}
                                                        sitesCount={parseInt(studyDetails.noOfSites)}
                                                        onCreateSite={this.onCreateSiteClick} />
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = ({ user, sponsors, sites, staff }) => {
    return {
        token: user.token,
        sponsors: sponsors.data,
        sites: sites.data,
        managers: staff.pms
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSponsors: (token, start, limit) => SponsorActions.getSponsors(dispatch, token, start, limit),
        getSites: (token) => SiteActions.getAllSites(dispatch, token),
        getProjectManagers: (token) => StaffActions.getProjectManagers(dispatch, token)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyAdd);