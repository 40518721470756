import React from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, FormGroup, Label, Button } from 'reactstrap';
import { RenderImage } from 'components';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { createMonitorQueryComments } from "api/index.js";
import cogoToast from "cogo-toast";
const SSREDCQuery = (props) => {

    const data = props.query;
    const document = data.attachment;
    
    const onEDCCommentSubmit = async (values) => {
        var commentsObj = {
            'monitorQuery': props.match.params.id,
            'comment': values.comments,
            'ownerStaff': props.user.staffProfile._id,
        }
        console.log(commentsObj);
        try {
            // create query comments
            await createMonitorQueryComments(props.token, commentsObj);
            const { hide } = cogoToast.success('EDC Query response posted successfully.', {
                  onClick: () => {
                    hide();
                  },
                  position:"top-right",
                  hideAfter:3
            });
             props.onCancel();  
        } catch(error) {
            alert("Failed to Update EDC Query");
            console.log(error);
        }
    }
    
   
    
    return (
        <Row>
            <Col md="12">
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Query Details</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <AvForm onValidSubmit={(event, values) => {
                            onEDCCommentSubmit(values)
                        }}>
                            <Row>
                                <Col sm={12} lg={4}>
                                    <FormGroup>
                                        <Label>Trial Participant ID</Label>
                                        <p>{data.subject.uniqueCode}</p>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Query Description</Label>
                                        <p>{data.query}</p>
                                    </FormGroup>
                                    {
                                        (data.queryStatus === 'OPENED' || data.queryStatus === 'RE_OPENED') ?
                                            <AvField name="comments" autoComplete="off"
                                                required
                                                label="Response To Query"
                                                type="textarea"
                                                errorMessage="Please enter text in Response to Query."
                                                validate={{
                                                    maxLength: { value: 500, errorMessage: 'Response must not be more than 500 characters.' }
                                                }}/>
                                            : null
                                    }
                                </Col>
                                <Col sm={12} lg={8} className="text-center">
                                    <h6> Query Attachment </h6>
                                    <div className="query-image">
                                        <RenderImage alt="Query Attachment" src={document} />
                                    </div>
                                    {
                                        (data.queryStatus === 'OPENED' || data.queryStatus === 'RE_OPENED') ?
                                            <>
                                                <Button color="primary" className="btn-round btn-title" type="submit" size="sm">
                                                    Submit
                                                </Button>
                                                <Button color="primary" outline className="btn-round btn-title ml-4" size="sm" type="button" onClick={props.onCancel}>
                                                    Back To Query
                                                </Button>
                                            </>
                                            : null}
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>
            </Col>
        </Row >
    );
}

export default SSREDCQuery;