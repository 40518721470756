import Axios from 'axios';
import { SITE_SUBJECTS_URL, MONITOR_DOC_QUERIES_URL, MONITOR_DOC_URL } from 'api/list';

const getSSRMetrics = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${SITE_SUBJECTS_URL}/metrics/${siteId}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_SSR_DASHBOARD',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });

}

const getSiteStaffPendingReviewQueries = (dispatch, token, siteId, start, limit) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_QUERIES_URL}?studySite._id=${siteId}&queryStatus=OPENED&queryStatus=RE_OPENED&_start=${start}&_limit=${limit}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_WAITING_FOR_REVIEW_QUERIES',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getSiteStaffPendingReviewQueriesCount = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_QUERIES_URL}/count?studySite._id=${siteId}&queryStatus=OPENED&queryStatus=RE_OPENED`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_WAITING_FOR_REVIEW_QUERIES_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getPendingICFReviewDocs = (dispatch, token, siteId, start, limit) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_URL}?studySite._id=${siteId}&docType=ICF&isCCRAVerified=false&_start=${start}&_limit=${limit}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_PENDING_ICF_REVIEW_DOCS',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getWaitingForReviewQueries = (dispatch, token, siteId, start, limit) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_QUERIES_URL}?studySite._id=${siteId}&queryStatus=RESOLVED&_start=${start}&_limit=${limit}`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_WAITING_FOR_REVIEW_QUERIES',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getPendingICFReviewDocsCount = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_URL}/count?studySite._id=${siteId}&docType=ICF&isCCRAVerified=false`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_PENDING_ICF_REVIEW_DOCS_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

const getWaitingForReviewQueriesCount = (dispatch, token, siteId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    Axios.get(`${MONITOR_DOC_QUERIES_URL}/count?studySite._id=${siteId}&queryStatus=RESOLVED`, { headers })
        .then((response) => {
            dispatch({
                type: 'GET_WAITING_FOR_REVIEW_QUERIES_COUNT',
                response: response.data
            });
        })
        .catch((e) => {
            if (e && e.response && e.response.status === 401) {
                dispatch({
                    type: 'GET_TOKEN_ERROR',
                });
            }
            console.log('error', e);
        });
}

export default { getSSRMetrics, 
    getSiteStaffPendingReviewQueries, 
    getSiteStaffPendingReviewQueriesCount,
    getPendingICFReviewDocs, 
    getWaitingForReviewQueries,
    getPendingICFReviewDocsCount, 
    getWaitingForReviewQueriesCount    
}
