import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import Checkbox from '../Custom/Checkbox';


const MonitoringPlan = ((props) => {

    function domainDetails() {
        var { visits } = props;
        var tempDomains = []
        for (const i in visits) {
            tempDomains.push(<th key={i} style={{ paddingLeft: '5px', }}>{visits[i]}</th>)
        }
        return tempDomains
    }

    function visitDetails() {
        var { domains, visits, data } = props;
        var tempVisits = []

        for (var i in domains) {
            var tempDomains = []
            for (const j in visits) {
                tempDomains.push(
                    <td key={j}>
                        <Checkbox
                            id={`${visits[j]}:${domains[i]}`}
                            disable={props.disabled}
                            checked={data && data.indexOf(`${visits[j]}:${domains[i]}`) > -1 ? true : false}
                            {...props}
                            onCheckedChange={(e) => {
                                props.onDataChange(e.target.id, e.target.checked)
                            }} />
                    </td>
                )
            }
            tempVisits.push
                (
                    <tr key={domains[i]}>
                        <td>{domains[i]}</td>
                        {
                            tempDomains
                        }
                    </tr>
                )
        }

        return tempVisits
    }
    return (
        <>
            <Row>
                <Col>
                    <Table responsive className="table table-bordered" >
                        <thead className="table-active" style={{ paddingLeft: '-5px' ,borderRadius:'20px',border:'5px',margin:'20px'}}>
                            <tr >
                                <th>Domain ID</th>
                                {
                                    domainDetails()

                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                visitDetails()
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );

})

export default MonitoringPlan