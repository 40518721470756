import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Label,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
} from "reactstrap";

import * as moment from "moment";
import _ from "lodash";
import { DashboardActions, QueryActions, SiteActions } from "actions";

// core components
import { PanelHeader, RemoteTable } from "components";
import DashboardLayout from "layouts/Admin";
import { Routes, Roles, DATE_FORMAT } from 'constants/index.js';
import { PAGE_SIZE } from "api/list";
import QueriesFields from "./QueriesFields";

class Queries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            page: 1,
            sizePerPage: 10,
            item: '',
        }
    }

    async componentDidMount() {
        const token = this.props.token;
        const siteId = this.props.site ? this.props.site._id : undefined;
        var params = [`studySite._id=${siteId}`]
        switch (this.props.role) {
            case Roles.DM:
            case Roles.CSM:
                params.push(`owner._id=${this.props.user.staffProfile._id}`);
                break;
            default:
                break
        }
        this.props.getQueries(token, _.join(params, '&'));
        if (siteId) {
            this.props.getMyMetrics(token, siteId);
            this.props.getSiteSubjects(token, siteId);
            this.props.getSiteVisitDetails(token, siteId);
            this.props.getRaisedBy(token, siteId, this.props.site.clinicalStudy._id)
        }
    }

    static getDerivedStateFromProps(props, state) {

        var newstate = state;

        if (props.visits !== state.visits) {
            newstate['visits'] = props.visits.map(val => {
                return { value: val.name, label: val.name }
            })
        }

        if (props.subjects !== state.subjects) {
            newstate['subjects'] = props.subjects.map(val => {
                return { value: val._id, label: val.uniqueCode }
            })
        }

        if (props.queriesRaisedBy !== state.queriesRaisedBy) {
            newstate['queriesRaisedBy'] = props.queriesRaisedBy.map(val => {
                return { value: val.staffProfile._id, label: `${val.staffProfile.firstName} ${val.staffProfile.lastName}` }
            })
        }
      
        if (props.site && props.site.domainData !== state.domainData) {
            newstate['domainData'] = props.site.domainData.map(val => {
                return { value: val, label: val }
            })
        }

        if (props.totalSize !== state.totalSize) {
            newstate['totalSize'] = props.totalSize
        }
        return newstate;
    }

    handlePageChange = (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
    }

    RaiseSourceData = () => {
        this.props.history.push(`${Routes.QUERIES}/add`)
    }

    formatDate(date) {
        return moment(date).format(DATE_FORMAT);
    }

    onItemClick = (e, row, rowIndex) => {
        this.props.history.push(`${Routes.QUERIES}/${row._id}`)
    }

    handleTableChange = (type, { filters, page, sizePerPage }) => {

        const currentIndex = (page - 1) * sizePerPage;
        this.setState({ page })

        var params = [`studySite._id=${this.props.site._id}`];
        Object.keys(filters).map((key) => {
            const updatedKey = key === 'subject.uniqueCode' ? 'subject._id' : key
            return params.push(`${updatedKey}=${filters[key].filterVal}`);
        })

        switch (this.props.role) {
            case Roles.DM:
            case Roles.CSM:
                params.push(`owner._id=${this.props.user.staffProfile._id}`);
                break;
            default:
                break
        }

        this.props.getQueries(this.props.token, _.join(params, '&'), currentIndex);
    }

    render() {

    
        const { role, metrics, queries } = this.props;
        const { page, totalSize } = this.state;
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={Routes.DASHBOARD}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Queries</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">List Of Queries</CardTitle>
                                    <Row style={{ marginTop: '-12px' }}>
                                        <Col>
                                            <Label>Total Queries : </Label>
                                            <Label style={{ color: 'blue', marginLeft: '15px', marginRight: '40px' }}>
                                                {metrics ? metrics.queryMetrics.totalQueries : ''}
                                            </Label>
                                            <Label className="ml-2">Resolved Queries </Label>
                                            <Label style={{ color: 'green', marginLeft: '15px', marginRight: '40px' }}>
                                                {metrics ? metrics.queryMetrics.totalQueriesResolved : ''}</Label>
                                            <Label className="ml-3">Open Queries : </Label>
                                            <Label className="red" style={{ marginLeft: '15px', marginRight: '40px' }}>
                                                {metrics ? metrics.queryMetrics.totalQueriesOpen : ''}</Label>
                                            <Label className="ml-2">Closed Queries </Label>
                                            <Label className="text-primary" style={{ marginLeft: '15px', marginRight: '40px' }}>
                                                {metrics ? metrics.queryMetrics.totalQueriesClosed : ''}</Label>
                                            {(role === Roles.CCRA || role === Roles.DM || role === Roles.CSM) ?
                                                <Button color="primary" className="btn btn-round float-right" size="sm" onClick={this.RaiseSourceData}>Raise Source Data Request</Button>
                                                : null
                                            }
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={12}>
                                        {
                                            this.state.queriesRaisedBy && this.state.visits && 
                                            this.state.subjects &&
                                            <RemoteTable
                                                columns={QueriesFields(role, this.state)}
                                                onTableChange={this.handleTableChange}
                                                data={queries}
                                                onItemClick={this.onItemClick}
                                                page={page}
                                                sizePerPage={PAGE_SIZE}
                                                totalSize={totalSize}
                                            />
                                        }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        )
    }
}

const mapStateToProps = ({ user, sites, dashboard, queries }) => {
    return {
        user,
        token: user.token,
        role: user && user.role ? user.role.name : '',
        site: sites.current,
        visits: sites.visits,
        subjects: sites.subjects,
        metrics: dashboard.metrics,
        queries: queries.data,
        totalSize: queries.count,
        queriesRaisedBy: sites.queriesRaisedBy
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMyMetrics: (token, siteId) => DashboardActions.getSSRMetrics(dispatch, token, siteId),
        getQueries: (token, params, dataIndex) => QueryActions.getQueries(dispatch, token, params, dataIndex),
        getSiteSubjects: (token, siteId) => SiteActions.getSiteSubjects(dispatch, token, siteId),
        getSiteVisitDetails: (token, siteId) => SiteActions.getSiteVisitDetails(dispatch, token, siteId),
        getRaisedBy: (token, siteId, studyId) => SiteActions.getRaisedByDetails(dispatch, token, siteId, studyId)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Queries);
