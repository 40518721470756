import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
    Modal, 
    ModalHeader, 
    ModalBody
} from "reactstrap";
import Select from "react-select";
import moment from 'moment';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DashboardLayout from "layouts/Admin";
import { Routes } from 'constants/index.js';

import noImage from "assets/img/noImage.png"
import { DocumentActions } from "actions/index.js";
import { RenderImage } from "components";
import RaiseSdvQuery from "../Queries/SubmitQuery/RaiseSdvQuery.js";
import RaiseEdcQuery from "../Queries/SubmitQuery/RaiseEdcQuery.js";
import { getCrfSourceDocuments, getAllCrfSourceDocuments } from "api/index.js";

var selectOptions = [];
  
class ConductSDV extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            designation: null,
            queryType: "",
            linkedDocuments: [],
            selectedSourceIdx : 0,
            documentPopup : false,
            popupUrl : noImage,
            popupFileType : 'image'
        };
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.close = this.close.bind(this);
        this.cancelRaiseQuery = this.cancelRaiseQuery.bind(this);
    }

    async componentDidMount() {
        await this.props.getDocumentDetails(this.props.token, this.props.match.params.id);
    }
    
    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.sdvDocument !== this.props.sdvDocument) {
                
            // fetch linked source documents, if not fetch all source and ICF documents 
            // of the subject
            //
            let documents = await getCrfSourceDocuments(this.props.token, this.props.match.params.id);
            if (documents && documents.status === 200 && documents.data.length > 0) {
                await this.setState({ 
                    selectedSourceIdx : { value : 0, 
                        label : `${documents.data[0].domainData ? documents.data[0].domainData : ''}_${documents.data[0].id}`
                    },
                    sourceDocument: documents.data[0], 
                    linkedDocuments : documents.data });
            } else if (this.props.sdvDocument && this.props.sdvDocument.subject &&
                       this.props.sdvDocument.visit && this.props.sdvDocument.domainData) {
                let documents = await getAllCrfSourceDocuments(this.props.token, 
                                                    this.props.sdvDocument.subject.id,
                                                    this.props.sdvDocument.visit.id,
                                                    this.props.sdvDocument.domainData);
                if (documents && documents.status === 200 && documents.data.length > 0) {
                    await this.setState({ 
                        selectedSourceIdx : { value : 0, 
                            label : `${documents.data[0].domainData ? documents.data[0].domainData : ''}_${documents.data[0].id}`
                        },
                        sourceDocument: documents.data[0], 
                        linkedDocuments : documents.data });
                }
            }
        }
    }
    
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onCancel() {
        this.props.history.goBack()
    }

    cancelRaiseQuery() {
        this.setState({ queryType: "" })
    }

    close() {
        if (this.props.sdvDocument) {
            this.props.history.push(`${Routes.DOCUMENTS}/${this.props.sdvDocument.uploadGroup}`);
        }
    }

    getFileType(docUrl) {
        const splitObj = docUrl && docUrl.split('.')
        let fileType = "image";
        switch (splitObj && splitObj[splitObj.length - 1]) {
            case 'pdf':
                fileType = 'pdf'
                break;
            default:
                break;
        }
        return fileType;
    }
    
    render() {
        const sourceDocument = this.state.sourceDocument;
        const document = this.props.sdvDocument;
        
        var docUrl = document && document.versionedDocument && document.versionedDocument.length > 0 ? document.versionedDocument[document.versionedDocument.length - 1].url : undefined;
        if (sourceDocument && sourceDocument.versionedDocument) {
            var sourceDocUrl = sourceDocument && sourceDocument.versionedDocument && sourceDocument.versionedDocument.length > 0 ? sourceDocument.versionedDocument[sourceDocument.versionedDocument.length - 1].url : undefined;
            selectOptions = [
                { value: "edc_query", label: "Raise Query In EDC System" },
                { value: "completed", label: "Completed" },
                { value: "svd_query", label: "Raise Query In SDV System" },
            ];
        } else {
            selectOptions = [ { value: "svd_query", label: "Raise Query In SDV System" }];
        }
        var sourceDocFileType = this.getFileType(sourceDocUrl);
        var fileType = this.getFileType(docUrl);
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={`${Routes.ADMIN_DASHBOARD}`}>Home</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.DOCUMENTS}`} >Documents</BreadcrumbItem>
                            <BreadcrumbItem tag="a" href={`${Routes.DOCUMENTS}/${document ? document.uploadGroup : ''}`}>Document Details</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Conduct SDV</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    <Modal 
                        isOpen = {this.state.documentPopup} 
                        size="lg" 
                        centered 
                        toggle={ async () => await this.setState({documentPopup : false})} 
                        backdrop="static"
                        keyboard={false}
                    >
                        <ModalHeader toggle={ async () => await this.setState({documentPopup : false})}>
                            Document Preview
                        </ModalHeader>
                        <ModalBody>
                           <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                                <RenderImage alt="." src={this.state.popupUrl} />
                            </div>
                        </ModalBody>
                    </Modal>
                    <Row>
                        <Col md="12">
                            <Card style={{ minHeight: '755px' }}>
                                <CardHeader>
                                    <CardTitle tag="h5">Conduct SDV</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row className="text-center mx-auto my-4">
                                        <Col xs={2} className="text-center">
                                            <h6>Source Data Image</h6>
                                        </Col>
                                        <Col xs={12} sm={4} style={{ textAlign: 'center', color: '#42B5A9' }}>
                                            <Select
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                placeholder="Select Source Document"
                                                name="select"
                                                value={this.state.selectedSourceIdx}
                                                options={this.state.linkedDocuments.length ? 
                                                    this.state.linkedDocuments.map( (link, index) => {
                                                        return ({
                                                            value : index, 
                                                            label : `${link.domainData ? link.domainData : ''}_${link.id}`
                                                        })
                                                    }) : []
                                                }
                                                onChange={async (value) => {
                                                   await this.setState({ selectedSourceIdx : value, 
                                                        sourceDocument: this.state.linkedDocuments[value.value]})
                                                }}
                                            />
                                        </Col>
                                        <Col xs={6} className="text-center">
                                          <h6>CRF Document Image</h6>
                                        </Col>
                                     </Row>
                                     <Row>
                                        <Col xs={6} className="mx-auto">
                                            <div style={{ cursor: 'pointer'}} 
                                                onClick = { async () => await this.setState({
                                                       documentPopup : true,
                                                       popupUrl : sourceDocUrl ? sourceDocUrl : noImage,
                                                       popupFileType : sourceDocFileType})}
                                            >
                                                <RenderImage
                                                    alt="." width={600} height={400}
                                                    src={sourceDocUrl ? sourceDocUrl : noImage}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={6} className="mx-auto">
                                            <div style={{ cursor: 'pointer' }}
                                                 onClick = { async () => await this.setState({
                                                       documentPopup : true,
                                                       popupUrl : docUrl,
                                                       popupFileType : fileType})}
                                            >
                                                <RenderImage
                                                    alt="." width={600} height={400}
                                                    src={docUrl}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginLeft: '0px', marginTop: '45px' }}>
                                        <Col xs={6} md={12} lg={4}>
                                            <FormGroup>
                                                <Label>Select SDV Status</Label>
                                                <Select
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                    placeholder="Select"
                                                    name="select"
                                                    value={this.state.queryType}
                                                    options={selectOptions}
                                                    onChange={async (value) => {
                                                        if (value.value === 'completed') {
                                                            await this.props.setDocumentVerified(
                                                                this.props.token, 
                                                                document.id,
                                                                {'isCCRAVerified' : true, 'ccraVerifyTime' : moment.utc().valueOf});
                                                            this.props.history.goBack();
                                                        }
                                                        await this.setState({ queryType: value })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    {
                                        !this.state.queryType &&
                                        <Row style={{ marginLeft: '1%' }}>
                                            <Button color="primary" className="btn-round" size="sm" onClick={this.onCancel}>
                                                Cancel
                                            </Button>
                                        </Row>
                                    }
                                    <Row style={{ marginLeft: '0px', marginTop: '45px' }}>
                                        {this.state.queryType.value === "svd_query" && 
                                            <RaiseSdvQuery 
                                                crfDocId={this.props.match.params.id} 
                                                sourceDocUrl={sourceDocUrl ? sourceDocUrl : noImage } 
                                                docUrl={docUrl} sourceDocFileType={sourceDocFileType} 
                                                fileType={fileType} 
                                                close={this.close}
                                                cancel={this.cancelRaiseQuery} 
                                                sourceDocument={sourceDocument} 
                                                crfDocument={document} />}
                                        {this.state.queryType.value === "edc_query" && 
                                            <RaiseEdcQuery 
                                                cancel={this.cancelRaiseQuery} 
                                                crfDocId={this.props.match.params.id} 
                                                close={this.close} 
                                                sourceDocument={sourceDocument} 
                                                crfDocument={document} />}
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout>
        )
    }
}


const mapStateToProps = ({ user, documents, studies }) => {
    return {
        token: user.token,
        details: documents.details,
        sdvDocument : documents.sdvDocument,
        study: studies.myStudy
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDocumentDetails: (token, id) => DocumentActions.getDocumentDetailsById(dispatch, token, id),
        setDocumentVerified : (token, docId, data) => DocumentActions.setDocumentVerified(dispatch, token, docId, -1, data),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConductSDV);
