import Axios from 'axios';
import { FILEUPLOAD_URL, FILE_DOWNLOAD_URL, BASE_URL } from 'api/list';

export const uploadFileToStrapi = async (token, file) => {

    const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
    };

    var formdata = new FormData();
    formdata.append("files", file);

    return Axios.post(FILEUPLOAD_URL, formdata, { headers });
}


export const deleteFileFromStrapi = async (token, fileId) => {
    
    const headers = {
        Authorization: `Bearer ${token}`
    };

    return Axios.delete(`${FILEUPLOAD_URL}/files/${fileId}`, { headers });
}

// support for Azure blob storage
export const getFileFromUrl = async (url, token, blob=false) => {
    
    // check if url is on azure storage, fetch the file blob using API endpoint
    if (url.includes('blob.core.windows.net')) {
        try {
            const headers = {
                'Content-Type': 'blob',
                Authorization: `Bearer ${token}`
            };
            let response = await Axios.get(`${FILE_DOWNLOAD_URL}?url=${url}`, { headers : headers, responseType : 'arraybuffer'});
            let fileBlob = new Blob([response.data], {type: 'application/octet-stream'});
            
            // return blob if blob flag was set
            if (blob) return fileBlob;
            
            return await URL.createObjectURL(fileBlob);
        } catch (error) {
            console.log(error);
        }
    } else if (url.includes(BASE_URL)) {
        // return url if already has base url
        return url;
    } else if (url.includes('uploads')) {
        // return full url if the current url has relative path
        return `${BASE_URL}${url}`;
    } else {
        // if this is a local path, return as is
        return url;
    }
}