const initialState = {
    data: [],
    subjectsRecruited: 0,
    queriesCount: 0,
    subjects: []
};

const sites = (state = initialState, action) => {
     switch (action.type) {
        case 'GET_SITES':
             return { ...state, data: action.response }
         case 'GET_SITES_COUNT':
             return { ...state, count: action.response }
        case 'SITE_DETAILS':
            return {
                ...state,
                current: action.response
            }
        case 'SITE_SUBJECTS':
            return {
                ...state,
                subjects: action.response
            }
        case 'SITE_SUBJECTS_RECRUITED':
            return {
                ...state,
                subjectsRecruited: action.response
            }
        case 'SITE_QUERIES_COUNT':
            return {
                ...state,
                queriesCount: action.response
            }
         case 'SITE_QUERIES_RAISED_BY':
             return {
                 ...state,
                 queriesRaisedBy: action.response
             }
        case 'SITE_STAFF':
            return {
                ...state,
                staff: action.response
            }
        case 'SITE_VISIT':
            return {
                ...state,
                visits: action.response
            }
        case 'SITE_STUDIES':
            return {
                ...state,
                studies: action.response
            }
        case 'PAGES_UPLOADED_COUNT':
            return {
                ...state,
                pagesUploaded: action.response
            }
        case 'GET_TOKEN_ERROR':
             return { ...state, current: {} }
         
        default:
            return state;
    }
};

export default sites;