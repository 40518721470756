import React from 'react';

const Chip = ((props) => {
    return (
        <>
            <input type="checkbox" hidden disabled={props.disabled} checked={props.checked} id={props.id} className="chip" onChange={props.onCheckedChange}/>
            <label htmlFor={props.id}>{props.name}</label>
        </>
    );
});

export default Chip