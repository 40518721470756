import React from "react";
import { connect } from 'react-redux';
import { Roles } from "constants/index.js";
import { SiteScannerResolverDashboard, AdminDashboard, CcraDashboard, SiteScannerDashboard, PMDashboard, DMDashboard, DEDashboard, CSMDashboard } from "./Components";

class RootDashboard extends React.Component {

    render() {
        const role = this.props.role;

        return (
            <>
                {
                    (role === Roles.SYSTEM_ADMIN) ?
                        <AdminDashboard {...this.props} />
                        : null
                }
                {
                    (role === Roles.SITE_SCANNER_RESOLVER) ?
                        <SiteScannerResolverDashboard {...this.props} />
                        : null
                }
                {
                    (role === Roles.SITE_SCANNER) ?
                        <SiteScannerDashboard {...this.props} />
                        : null
                }
                {
                    (role === Roles.CCRA) ?
                        <CcraDashboard {...this.props} />
                        : null
                }
                {
                    (role === Roles.PM) ?
                        <PMDashboard {...this.props} />
                        : null
                }
                {
                    (role === Roles.DM) ?
                        <DMDashboard {...this.props} />
                        : null
                }
                {
                    (role === Roles.DE) ?
                        <DEDashboard {...this.props} />
                        : null
                }
                {
                    (role === Roles.CSM) ?
                        <CSMDashboard {...this.props} />
                        : null
                }
            </>
        );
    }
}

const mapStateToProps = ({ user, studies, sites }) => {
    return {
        role: user.role ? user.role.name : '',
        user: user,
        token: user.token,
        site: sites.current,
        study: studies.current
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RootDashboard);