import React from 'react';
// reactstrap validations
import { AvForm, AvField } from 'availity-reactstrap-validation';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup,
    Label,
    CustomInput
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";


import BasicImageRedactor from 'views/Documents/Components/BasicImageRedactor';

import uploadImagesLogo from 'assets/img/upload_images.svg';
import { captureWidth, captureHeight, readContentFromScreenshot, checkFileSize } from 'commons/Utils';
import { uploadBlobToStrapi, readFileAsDataURL, readContentAsImage } from "commons/Utils.js";
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import { QueryTypeString, QueryStatusString } from "constants/index.js";

import {
    createMonitorDocVersion,
    createMonitorQueryComments,
    getToken
} from "api/index.js";
import cogoToast from 'cogo-toast';

export default class ImageQuery extends React.Component {

    fileInput = React.createRef();
    videoElem = React.createRef();

    state = {
        alert: null,
        previewFile: null,
        stage: 'data'
    }

    onFileUploadFromComputer = async (e) => {
        const validFile = checkFileSize(e.target.files[0], 10)
        if (validFile.valid) {
            const result = await readContentAsImage(await readFileAsDataURL(e.target.files[0]));
            if (result && result.length > 0) {
                await this.setState({ previewFile: result[0] });
            }
        } else {
            cogoToast.warn(validFile.message)
        }
    }

    saveRedactedDocument = async (image, index) => {
        await this.setState({ previewFile: image, stage: 'data' });
    }

    onImageRedact = async () => {
        await this.setState({ stage: 'redact' });
    }

    startCapture = async () => {
        try {
            const image = await readContentFromScreenshot(this.videoElem);
            await this.setState({ previewFile: image });
        } catch (e) {
            alert('capture screenshot failed')
            console.log(e);
        }
    }

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    }

    onSubmitImageQuery = async (event, values) => {

        const identifier = this.props.user.email;
        getToken(identifier, values.password).then(async (response) => {

            try {
                this.props.saveToken(response.data);
                const fileResponse = await uploadBlobToStrapi(this.props.user.token, this.state.previewFile);

                if (fileResponse && fileResponse.data.length) {
                    // upload new version of the document
                    await createMonitorDocVersion(this.props.token, {
                        'url': fileResponse.data[0].url,
                        'uploadedBy': this.props.user.staffProfile.id,
                        'monitorDocument': this.props.query.monitorDocument._id
                    });

                    // create new query comment.
                    var commentsObj = {
                        'monitorQuery': this.props.match.params.id,
                        'comment': values.comments,
                        'ownerStaff': this.props.user.staffProfile._id,
                        'attachment': fileResponse.data[0].url
                    }

                    await createMonitorQueryComments(this.props.token, commentsObj)
                    this.props.onCancel();
                    cogoToast.success('Image Query added successfully.',{
                         position:"top-right"
                     })
                }

            } catch (error) {
                alert('API failed!')
                console.log(error);
            }

        }).catch((e) => {
            const m = e.response ? e.response.data.data[0].messages[0].message : 'Something went wrong';
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        title="Alert"
                        onConfirm={this.hideAlert}
                    >
                        {m}
                    </SweetAlert>
                )
            });
        });
    }

    render() {
        return (
            <Row>
                {this.state.alert}
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5">Upload New Document</CardTitle>
                        </CardHeader>
                        <CardBody>

                            <AvForm onValidSubmit={async (event, values) => {
                                if (this.state.stage === 'data')
                                    await this.setState({ stage: 'confirm' });
                                if (this.state.stage === 'confirm')
                                    await this.onSubmitImageQuery(event, values)
                            }}>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Site Name</Label>
                                            <p>{this.props.site.name}</p>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Study Name</Label>
                                            <p>{'N/A'}</p>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Trial Participant ID</Label>
                                            <p>{this.props.query && this.props.query.subject ? this.props.query.subject.uniqueCode : ''}</p>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Visit ID</Label>
                                            <p>{this.props.query && this.props.query.visit ? this.props.query.visit.name : ''}</p>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Data Domain</Label>
                                            <p>{this.props.query && this.props.query.dataDomain ? this.props.query.dataDomain : ''}</p>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Query Type</Label>
                                            <p>{this.props.query && QueryTypeString[this.props.query.queryType]}</p>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Query Status</Label>
                                            <p>{this.props.query && QueryStatusString[this.props.query.queryStatus]}</p>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Query Details</Label>
                                            <p>{this.props.query && this.props.query.query}</p>
                                        </FormGroup>
                                        <AvField name="comments" autoComplete="off"
                                            required
                                            label="Response To Query"
                                            type="textarea"
                                            errorMessage="Please enter text in Response to Query."
                                            validate={{
                                                maxLength: { value: 500, errorMessage: 'Response must not be more than 500 characters.' }
                                            }} />

                                        {
                                            this.state.stage === 'confirm' &&
                                            <>
                                                <h6 style={{ marginTop: '50px' }}>Signature Copy</h6>
                                                <AvInput tag={CustomInput} className="checkbox" type="checkbox" name="guidelines" label="Check to accept guidelines" required errorMessage="Please accept the guidelines." />
                                                <AvField name="username" autoComplete="off" value={this.props.user.staffProfile ? `${this.props.user.staffProfile.firstName} ${this.props.user.staffProfile.lastName}` : ''} label="Name" type="text" disabled />
                                                <AvField name="password" autoComplete="off" placeholder="Enter Password" label="Password" type="password" required errorMessage="Please enter your password." />
                                            </>
                                        }
                                        {
                                            this.state.stage === 'redact' &&
                                            <BasicImageRedactor
                                                editMode={this.state.stage === 'redact'}
                                                closeRedactWindow={async e => await this.setState({ stage: 'data' })}
                                                url={this.state.previewFile ? this.state.previewFile : null}
                                                saveRedactedDocument={async (image) => await this.saveRedactedDocument(image, 0)} />
                                        }
                                    </Col>
                                    <Col className="text-center">
                                        <Row className="mx-auto mb-4 query-image" style={{ borderStyle: 'dashed', borderWidth: '2px' }}>
                                            {
                                                this.state.previewFile ?
                                                    <Col>
                                                        <img alt="Monitor Document" src={this.state.previewFile} />
                                                    </Col> :
                                                    <Col>
                                                        <img alt="Upload" className="mx-auto" src={uploadImagesLogo} style={{
                                                            height: '120px', display: 'block', marginTop: '100px', marginBottom: '20px'
                                                        }} />
                                                        <span className="text-center">Upload Images</span>
                                                    </Col>
                                            }
                                        </Row>
                                        <Row>
                                            {
                                                this.state.previewFile ?
                                                    <Col>
                                                        {
                                                            this.state.stage === 'data' &&
                                                            <Button className="btn-round" outline color="primary" size="sm"
                                                                onClick={this.onImageRedact}>
                                                                <i className="fa fa-pen mr-2" aria-hidden="true" style={{}}></i>
                                                        Edit/Redact
                                                    </Button>
                                                        }
                                                        <Button color="primary" className="btn-round ml-4" size="sm" type="submit" >{this.state.stage === 'confirm' ? 'Submit' : 'Upload'}</Button>
                                                        <Button color="primary" className="btn-round ml-4" outline size="sm" type="button" onClick={this.props.onCancel} >Cancel</Button>
                                                    </Col>
                                                    :
                                                    <Col>
                                                        <input
                                                            hidden
                                                            type="file"
                                                            accept='image/*,.pdf'
                                                            onChange={this.onFileUploadFromComputer}
                                                            ref={this.fileInput}
                                                        />
                                                        <Button color="primary" className="btn-round btn-title" size="sm" onClick={() => this.fileInput.current.click()}>
                                                            Upload From Computer
                                                </Button>
                                                        <video
                                                            autoPlay
                                                            hidden
                                                            ref={this.videoElem}
                                                            width={captureWidth}
                                                            height={captureHeight}
                                                        />
                                                        <Button color="primary" className="btn-round btn-title ml-4" size="sm" onClick={this.startCapture}>
                                                            Screen Capture
                                                </Button>
                                                        <Button color="primary" className="btn-round btn-title ml-4" size="sm">
                                                            Upload From Scanner
                                                </Button>
                                                    </Col>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}
