import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Badge,
    BreadcrumbItem,
    Row,
    Col,
    Breadcrumb,
} from "reactstrap";
import _ from 'lodash';
import cogoToast from 'cogo-toast';
// core components
import { PanelHeader } from "components";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import defaultAvatar from "assets/img/default_profile.jpg";
import DashboardLayout from "layouts/Admin";
import { uploadFileToStrapi } from "commons";
import { Routes, Roles, Validations } from 'constants/index.js';
import { updateStaff } from "api";
import { RenderImage } from "components";
import { UserActions } from "actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { checkFileSize } from "commons/Utils";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            imagePreviewUrl: defaultAvatar,
            isUploading:false
        };
        this.fileInput = React.createRef();
    }

    componentDidMount() {
        this.setState({
            imagePreviewUrl: this.props.user && this.props.user.staffProfile.profilePicture ? this.props.user.staffProfile.profilePicture : defaultAvatar
        })
        this.props.getMySites(this.props.user.token, this.props.user.staffProfile._id);
    }
    
    passwordRules = (value, ctx) => {
        if (value) {
            const rules = Validations.PASSWORD
            const isValid = rules.values.test(value)
            return isValid ? isValid : rules.errorMessage;    
        }
        return true
    }

    handleValidSubmit = (event, values) => {
        this.setState({ isUploading: true });
        let profileObj = {
            firstName: values.firstName, 
            lastName: values.lastName, 
            mobileNo: values.mobileNo,
        }
        if (values.password && values.password.length) {
            profileObj.password = values.password;
        }            
        this.updateStaffData(profileObj);
    }

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    }

    handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        const validFile = checkFileSize(file);
        if (validFile.valid) {
            // upload file
            uploadFileToStrapi(this.props.user.token, file).then(res => {
                const data = res.data[0];
                // const fileData = { url: `${data.url}`, id: data._id }
                this.updateStaffData({ profilePicture: `${data.url}` })
                this.setState({ isUploading: false });
            }).catch(e => {
                this.setState({ isUploading: false });
                console.log(e)
                cogoToast.error('Failed to upload file.')
            })

            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result,
                });
            };
            if (file) {
                reader.readAsDataURL(file);
            }
        } else {
            cogoToast.warn(validFile.message)
        }
    };

    updateStaffData = (data) => {
        const userId = this.props.user.staffProfile._id;
        updateStaff(this.props.user.token, userId, data).then(res => {
            this.setState({
                // isUploading: false,
                alert: (
                    <SweetAlert
                        success
                        title="Alert"
                        onConfirm={this.hideAlert}
                    >
                        Profile Updated Successfully!
                    </SweetAlert>
                )
            });
            this.props.updateProfile(res.data);
            this.setState({ isUploading: false });
        }).catch(e => {
            this.setState({ isUploading: false });
            alert('update profile failed'); console.log(e);
        })
    }

    handleClick = () => {
        this.fileInput.current.click();
    };

    render() {
        const role = this.props.user ? this.props.user.role.name : ''
        const sites = this.props.user.mySites;
        const {isUploading } = this.state;
        return (
            <DashboardLayout>
                <PanelHeader size="sm" >
                    <Col>
                        <Breadcrumb tag="nav" listTag="div">
                            <BreadcrumbItem tag="a" href={Routes.DASHBOARD}>Home</BreadcrumbItem>
                            <BreadcrumbItem active tag="span">Profile</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </PanelHeader>
                <div className="content">
                    {this.state.alert}
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader style={{ marginLeft: '0px' }}>
                                    <CardTitle tag="h5">Edit Profile</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row style={{ marginLeft: '0px' }}>
                                        <Col sm="12">
                                            <AvForm onValidSubmit={this.handleValidSubmit} model={this.props.user}>
                                                <>
                                                    <Row>
                                                        <Col md="3">
                                                            <div className="fileinput text-center">
                                                                <input
                                                                    type="file"
                                                                    accept={this.props.acceptType}
                                                                    onChange={this.handleImageChange}
                                                                    ref={this.fileInput}
                                                                />
                                                                <div className={"thumbnail img-circle"} >
                                                                    <RenderImage src={this.state.imagePreviewUrl} alt="." onClick={() => this.handleClick()} style={{ width: '100px', height: '100px' }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="3">
                                                            <AvField name="firstName" defaultValue={this.props.user.staffProfile.firstName} autoComplete="off" placeholder="First Name" label="First Name" type="text" validate={{
                                                                required: { value: true, errorMessage: 'Please enter a first name.' },
                                                                pattern: Validations.ALPHABETS_WITH_SPACES,
                                                                minLength: { value: 4, errorMessage: 'First name must be at least 4 characters' },
                                                                maxLength: { value: 50, errorMessage: 'First name should not be more than 50 characters' },
                                                            }} />
                                                            <AvField name="email" autoComplete="off" placeholder="someone@mail.com" label="Email ID" type="email" disabled />
                                                            <AvField name="role.name" autoComplete="off" label="Role" type="text" disabled />
                                                        </Col>
                                                        <Col md="3">
                                                            <AvField name="lastName" defaultValue={this.props.user.staffProfile.lastName} autoComplete="off" placeholder="Last Name" label="Last Name" type="text" validate={{
                                                                required: { value: true, errorMessage: 'Please enter a last name.' },
                                                                pattern: Validations.ALPHABETS_WITH_SPACES,
                                                                minLength: { value: 4, errorMessage: 'Last name must be at least 4 characters' },
                                                                maxLength: { value: 50, errorMessage: 'Last name must not be more than 50 characters' },
                                                            }} />
                                                            <AvField name="mobileNo" defaultValue={this.props.user.staffProfile.mobileNo} autoComplete="off" placeholder="+447911123456" label="Contact Number" type="text" validate={{
                                                                required: { value: true, errorMessage: 'Please enter a contact number.' },
                                                                pattern: Validations.TELEPHONE
                                                            }} />
                                                            <AvField name="password" autoComplete="off" placeholder="*****" label="Password" type="password" 
                                                            validate={{
                                                                myRules: this.passwordRules
                                                            }} />
                                                        </Col>
                                                    </Row>
                                                    <Button color="primary" className="btn-round" size="sm"  disabled={isUploading} type="submit">
                                                       {isUploading ? 'Please wait..' : 'Update Profile'}
                                                </Button>
                                                </>
                                            </AvForm>
                                        </Col>
                                    </Row>
                                    {
                                        ([Roles.CCRA, Roles.SITE_SCANNER, Roles.SITE_SCANNER_RESOLVER].indexOf(role) > -1) ?
                                            <Row>
                                                <Col md="12">
                                                    <h4>My Studies</h4>
                                                    <Table responsive hover>
                                                        <thead className="info-title">
                                                            <tr>
                                                                <th>Site Name</th>
                                                                <th>Study Name</th>
                                                                <th>Access</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                sites ? sites.map((val, index) => {
                                                                    return (
                                                                        <tr key={val.id}>
                                                                            <td>{val.studySite.name}</td>
                                                                            <td>{val.clinicalStudy.name}</td>
                                                                            <td><Badge className={val.isAccess ? "submitted-badge" : "verified-badge"}>{val.isAccess ? 'Granted' : 'Revoked'}</Badge></td>
                                                                            <td className="more text-center" />
                                                                        </tr>
                                                                    )
                                                                })
                                                                    :
                                                                    <h4>No Records Found</h4>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                            : null
                                    }
                                    {
                                        ([Roles.PM, Roles.DM, Roles.DE, Roles.CSM].indexOf(role) > -1) ?
                                            <Row>
                                                <Col md="12">
                                                    <h4>My Studies</h4>
                                                    <Table responsive hover>
                                                        <thead className="info-title">
                                                            <tr>
                                                                <th>Study Name</th>
                                                                {
                                                                    role === Roles.DE &&
                                                                    <th>Role</th>
                                                                }
                                                                <th>Access</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                sites ? _.uniqBy(sites, 'clinicalStudy._id').map((val, index) => {
                                                                    if (val.clinicalStudy)
                                                                        return (
                                                                            <tr key={val.id}>
                                                                                <td>{val.clinicalStudy.name}</td>
                                                                                {
                                                                                    role === Roles.DE &&
                                                                                    <td>{val.dataEntryRole}</td>
                                                                                }
                                                                                <td><Badge className={val.isAccess ? "submitted-badge" : "verified-badge"}>{val.isAccess ? 'Granted' : 'Revoked'}</Badge></td>
                                                                                <td className="more text-center" />
                                                                            </tr>
                                                                        )
                                                                    // fix warnings    
                                                                    else return null;
                                                                })
                                                                    :
                                                                    <h4>No Records Found</h4>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                            : null
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </DashboardLayout >
        );
    }
}

const mapStateToProps = ({ user }) => {
    return {
        user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (data) => dispatch({ type: 'UPDATE_PROFILE', response: data }),
        getMySites: (token, profileId) => UserActions.getMySites(dispatch, token, profileId)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);