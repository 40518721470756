import React, { Fragment } from 'react';
import { Navbar, Card, CardBody, Container, Button } from 'reactstrap';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import { forgetPassword } from 'api';

class ForgetPassword extends React.Component {

    constructor(props) {
        super();
        this.state = {
            alert: null,
        };
        this.form = React.createRef();
    }

    hideAlert = (success) => {
        this.setState({
            alert: null,
        });
        if (success)
            this.props.history.push('/')
    }

    onValidSubmit = async (event, values) => {
        try {
            await forgetPassword(values.email);
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        title="Alert"
                        onConfirm={() => this.hideAlert(true)}
                    >
                        An email is sent to your email address with a link reset password. Please follow the instructions in the email.
                    </SweetAlert>
                )
            });

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message && error.response.data.message.length > 0 && error.response.data.message[0].messages && error.response.data.message[0].messages.length > 0)
                this.setState({
                    alert: (
                        <SweetAlert
                            warning
                            title="Alert"
                            onConfirm={() => this.hideAlert(false)}
                        >
                            {error.response.data.message[0].messages[0].message}
                        </SweetAlert>
                    )
                });
            else {
                console.log(error.response);
                alert('Failed to send email to reset password!')
            }
            this.form.reset();
        }

    }

    render() {
        return (
            <Fragment style={{ backgroundColor: '#edf0fa' }}>
                <Navbar style={{ backgroundColor: '#42B5A9', marginBottom: '0px' }} >
                    <Container fluid>
                        <a href="/" className="mx-auto" >
                            <img alt="Logo" src={require('../../assets/img/logo.png')} height={40} />
                        </a>
                    </Container>
                </Navbar>
                <div className="wrapper wrapper-full-page" style={{ backgroundColor: '#edf0fa', minHeight: 'calc(100vh - 80px)' }}>
                    {this.state.alert}
                    <Card className="mx-auto" style={{ width: '535px', height: '570px', paddingTop: '100px' }}>
                        <CardBody className="text-center">
                            <img src={require('../../assets/img/reset_password.svg')} alt='Reset Password' />
                            <p className="reset-title">Reset your password?</p>
                            <p className="reset-sub-title">Enter username that you use to login into your account. An email will be sent that will allow you to reset your password.</p>
                            <AvForm ref={c => (this.form = c)} onValidSubmit={this.onValidSubmit} style={{ marginTop: '40px', marginLeft: '80px', marginRight: '80px' }}>
                                <AvField name="email" autoComplete="off" placeholder="Enter Email or User Name" type="email" required validate={{
                                    required: { value: true, errorMessage: 'Please enter an email.' },
                                    email: { value: true, errorMessage: 'Please enter a valid email address.' },
                                    maxLength: { value: 50, errorMessage: 'Admin Email should not be more than 50 characters. ' },
                                }} />
                                <Button color="primary" className="btn-round" size="sm" type="submit">
                                    Send Email
                                        </Button>
                            </AvForm>
                        </CardBody>
                    </Card>
                </div>
            </Fragment>
        );
    }
}

export default ForgetPassword;