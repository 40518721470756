import React from 'react';
import { Row, Col, FormGroup, Label, Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';
import { RenderImage } from 'components';

const MetaDataQuery = (props) => {

    const [docUrl, setDocUrl] = React.useState(null);
    
    const latestResponse = props.responseHistory ? _.first(props.responseHistory) : undefined;
    
    const oldMeta = latestResponse ? latestResponse.oldMeta : undefined;
    const newMeta = latestResponse ? latestResponse.newMeta : undefined;

    return (
        <Row>
            <Modal
                isOpen={docUrl !== null}
                size="lg"
                centered
                toggle={() => setDocUrl(null)}
                backdrop="static"
                keyboard={false}
            >
                <ModalHeader toggle={() => setDocUrl(null)}>
                    Document Preview
                </ModalHeader>
                <ModalBody>
                {   
                    docUrl &&
                    <div style={{ borderColor: 'gray', borderWidth: '1px' }}>
                       <RenderImage alt="CRF Document" className="mx-auto"  src={docUrl} />
                    </div>
                }
                </ModalBody>
            </Modal>
            
            <Col sm={4}>
                <h6>Old Meta Data</h6>
                <FormGroup>
                    <Label>Trial Participant ID</Label>
                    <p>{oldMeta ? oldMeta.subject : ''}</p>
                </FormGroup>
                <FormGroup>
                    <Label>Data Domain</Label>
                    <p>{oldMeta ? oldMeta.domainData : ''}</p>
                </FormGroup>
                <FormGroup>
                    <Label>Visit No.</Label>
                    <p>{oldMeta ? oldMeta.visit : ''}</p>
                </FormGroup>
                <FormGroup>
                    <Label>Doc Type</Label>
                    <p>{oldMeta ? oldMeta.docType : ''}</p>
                </FormGroup>
            </Col>
             <Col sm={4}>
                <h6>New Meta Data</h6>
                <FormGroup>
                    <Label>Trial Participant ID</Label>
                    <p>{newMeta ? newMeta.subject : ''}</p>
                </FormGroup>
                <FormGroup>
                    <Label>Data Domain</Label>
                    <p>{newMeta ? newMeta.domainData : ''}</p>
                </FormGroup>
                <FormGroup>
                    <Label>Visit No.</Label>
                    <p>{newMeta ? newMeta.visit : ''}</p>
                </FormGroup>
                <FormGroup>
                    <Label>Doc Type</Label>
                    <p>{newMeta ? newMeta.docType : ''}</p>
                </FormGroup>
            </Col>

        </Row>
    );
}

export default MetaDataQuery;