import React from 'react';

import './styles.css';

export default class Popup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alert: <img
                alt="..." style={{ maxHeight: '2230px' }}
                src={require("assets/img/bg14.jpg")}
            />
        };
    }

    render() {
        return (
            <div >
                {this.state.alert}
            </div>
        );
    }
}